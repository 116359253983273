import React from "react";
import { Modal } from "antd";
import { Currency } from "react-intl-number-format/dist/index.js";
import moment from "moment";
import { array } from "prop-types";

const ContizacionInvalidModal = (props) => {
  const validaciones = props.cotizacion_invalid.validaciones || [];
  const comisiones_totales = props.cotizacion_invalid.comisiones_totales || [];
  const procutos_total_mas_IVA =
    props.cotizacion_invalid.procutos_total_mas_IVA || [];
  console.log(validaciones);
  if (props.invalid) {
    return (
      <div className="ps-modal__container">
        <h3 className="modal-body">
          <div className="alert alert-info" role="alert">
            <ul>
              <h2 className="mt-3"></h2>
              <span style={{ color: "#000" }}>
                <i className="icon icon-bubble-alert" /> &nbsp;Nota:
              </span>{" "}
              La cotizacion no puede ser enviada, debido a que el monto no
              cumple con el requisito minimo de ganancia.
            </ul>
          </div>
        </h3>
        <table className="ps-table--modal center">
          <thead>
            <tr>
              <th>Descripcion</th>
              <th>Cant</th>
              <th>Envase</th>
              <th>Precio Unitario (CLP)+ IVA</th>
              <th>VALOR TOTAL (CLP)</th>
            </tr>
          </thead>
          <tbody>
            {props.cotizacion.map((c, index) => (
              <tr key={c.id}>
                {c.descripcion.map((producto) => (
                  <React.Fragment>
                    <td>{producto.nombre_comercial}</td>
                    <td>{c.cantidad}</td>
                    <td>-</td>
                    <td>
                      <Currency
                        groupSeparator=","
                        decimalSeparator="."
                        allowDecimals={true}
                        decimalsLimit={2}
                      >
                        {c.precio}
                      </Currency>
                    </td>
                  </React.Fragment>
                ))}
                <td style={{ color: validaciones[index] ? "" : "red" }}>
                  <Currency
                    groupSeparator=","
                    decimalSeparator="."
                    allowDecimals={true}
                    decimalsLimit={2}
                  >
                    {c.total}
                  </Currency>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <hr />
        <p style={{ textAlign: "center", fontSize: "1.4em" }}>
          Valor total - Comisiones + IVA = Ganancia
        </p>
        {validaciones.map((validacion, index) => {
          console.log(validacion, comisiones_totales[index]);
          if (!validacion) {
            return (
              <p style={{ textAlign: "center", fontSize: "1.4em" }}>
                CLP {procutos_total_mas_IVA[index]} - CLP{" "}
                {comisiones_totales[index]} = CLP{" "}
                <span style={{ color: "red" }}>
                  {procutos_total_mas_IVA[index] - comisiones_totales[index]}
                </span>
              </p>
            );
          }
        })}

      </div>
    );
  } else {
    return (
      <div className="ps-modal__container">
        <h3 className="modal-body">¿Deseas enviar la siguiente cotización?</h3>
        <p style={{ textAlign: "center" }}>
          Este resumen incluye los datos generales de la cotización junto al
          total de la misma
        </p>
        <hr />
        <div
          className="container m-10 p-10"
          style={{ display: "flex", justifyContent: "space-around" }}
        >
          <div>
            <b>Cliente</b>
            <p className="mt-1">
              {props.encabezado.cliente__first_name}{" "}
              {props.encabezado.cliente__last_name}
            </p>
          </div>
          <div>
            <b>Fecha props.cotizacion</b>
            <p className="mt-1">
              {moment(props.encabezado.fecha_creacion).format(
                "DD/MM/YYYY, h:mm:ssa"
              )}
            </p>
          </div>
          <div>
            <b>Fecha Límite</b>
            <p className="mt-1">
              {moment(props.encabezado.fecha_limite).format(
                "DD/MM/YYYY, h:mm:ssa"
              )}
            </p>
          </div>
          <div>
            <b>Cotización No.</b>
            <p className="mt-1">No. {props.encabezado.id}</p>
          </div>
          <div>
            <b></b>
            <p className="mt-1"></p>
          </div>
        </div>
        <hr />
        <div className="modal-body">
          <h4>Resumen</h4>
          <table className="ps-table--modal center">
            <thead>
              <tr>
                <th>Descripcion</th>
                <th>Cant</th>
                <th>Envase</th>
                <th>Precio Unitario (CLP)+ IVA</th>
                <th>VALOR TOTAL (CLP)</th>
              </tr>
            </thead>
            <tbody>
              {props.cotizacion.map((c, index) => (
                <tr key={c.id}>
                  {c.descripcion.map((producto) => (
                    <React.Fragment>
                      <td>{producto.nombre_comercial}</td>
                      <td>{c.cantidad}</td>
                      <td>-</td>
                      <td>
                        <Currency
                          groupSeparator=","
                          decimalSeparator="."
                          allowDecimals={true}
                          decimalsLimit={2}
                        >
                          {c.precio}
                        </Currency>
                      </td>
                    </React.Fragment>
                  ))}
                  <td>
                    <Currency
                      groupSeparator=","
                      decimalSeparator="."
                      allowDecimals={true}
                      decimalsLimit={2}
                    >
                      {c.total}
                    </Currency>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div
          className="ps-section__cart-actions"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <h3 style={{ textAlign: "center" }}>TOTAL:</h3>
          <h3 style={{ textAlign: "center", color: "#FCB800" }}>
            <Currency
              groupSeparator=","
              decimalSeparator="."
              allowDecimals={true}
              decimalsLimit={2}
            >
              {props._total}
            </Currency>
          </h3>
        </div>

        <div
          className="ps-section mt-15 pt-15"
          style={{ display: "flex", justifyContent: "space-around" }}
        >
          <input
            type="button"
            className="ps-btn ps-btn--gray"
            style={{ backgroundColor: "#2e3d50", color: "white" }}
            onClick={props.closeModal}
            value="Regresar"
          ></input>

          <button onClick={props.handleUpdateCotizacion} className="ps-btn">
            Enviar cotización
          </button>
        </div>
      </div>
    );
  }
};

export default ContizacionInvalidModal;
