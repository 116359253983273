import React, {useState, useLayoutEffect, Component } from 'react'
import cotizacion, {actions} from '../../../redux/modules/cotizaciones/cotizacion';
import {onSubmitCotizacion} from '../../../redux/modules/cotizaciones/cotizacion';
import { connect } from 'react-redux';
import {api} from '../../../utility/api'
import DefaultHeader from '../layout/Header/DefaultHeader'
import Listas from './Listas';
import Entregar from './Entregar';
import Entregado from './Entregado';


class DetalleVenta extends Component {

  constructor(props) {
    super(props);

    this.state = {
        id : this.props.match.params.id,
        cotizacion: [],
        iva: 0.19,
        monto_pagado: "",
        encabezado: [],
        status: false,
        editable: [],
        _total: 0,
        isModalVisible: false,
        handleOk:false,
        handleCancel:false,
        scrollPosition: ''
    };
} 

    openModal = () => {
        window.scrollTo(0, 0)
         this.setState({isModalVisible:true});

    };

    closeModal = () => {
        this.setState({isModalVisible:false});
    };
 
    handleClick = (e) => {
        history.push("/");
    }

    handlePickUp = (data) =>{
        this.props.pickupCotizacion(data)
    }

    handleUpdateCotizacion = () => {
        const { cotizacion } = this.state;
        this.props.onSubmitCotizacion(cotizacion);
        this.closeModal()
    }

    calculateSubtotal = (producto, value, index) => {
        let total_c= 0
        const { cotizacion } = this.state;
        let precio
        {value ? precio = value :precio = 0 }
        total_c = producto.cantidad * precio;
        total_c = total_c+(total_c*this.state.iva)
        const newCounters = Object.assign(cotizacion, {
            ...cotizacion,
            [index]: { ...cotizacion[index], total: total_c , precio: value, unidad: value}
          });
        this.setState({ cotizacion: newCounters });
        this.calculateTotal();
        }

        calculateTotal = () => {
            let total = 0
            this.state.cotizacion.map(monto =>(
                total += parseInt(monto.total)
            ))
            this.setState({_total: total})
        }

        setTotal = (cotizacion) => {
            let total = 0
            cotizacion.map(monto =>(
                total += parseInt(monto.total)
                
            ))
            this.setState({_total: total})
        }

      
        componentDidMount(){
            var id = this.state.id;
            this.getCotizacion(id);
            this.calculateTotal();
            this.setTotal(this.state.cotizacion)
        }
    

      getCotizacion = async() => {
        const SPProducts = await api.get(`cotizaciones/paid/?identifier=${this.state.id}`).then((response) =>{
            //setProductItems(response.results); 
            if (response) {
                this.setState({cotizacion:response})
                this.setState({editable:response})
                this.setState({encabezado:response[0].encabezado[0]})
                this.setState({monto_pagado:response[0].monto_pagado})
            } else {
                //
            }
            setTimeout(function () {
            }, 500);
            return SPProducts;
        })
    }

    

render() {
    const {cotizacion , encabezado, _total} = this.state
    return (
        <div>
            <DefaultHeader title="Ventas" search_bar={false}/>
            {encabezado.estado === 9? 
            <React.Fragment>
            <h2>Tus ventas</h2>
            <Listas id={this.state.id}/>
            </React.Fragment>
            :null}
            {encabezado.estado === 11?
            <Entregar id={this.state.id}/>
            :null}
            {encabezado.estado === 12?
            <Entregado id={this.state.id}/>
            :null}
        </div>
    );
}


};

const ms2p = (state) => {
    return state.cotizacion;
  };
  
  const md2p = { ...actions };
  
export default connect(ms2p, md2p)(DetalleVenta);

