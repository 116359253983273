import React, { useEffect, useState } from 'react'
import DefaultHeader from '../../layout/Header/DefaultHeader'
import LoadMask from '../../Utils/LoadMask/LoadMask'
import { connect } from 'react-redux'
import UserForm from './UserForm'
import { actions } from '../../../../redux/modules/cuenta/login'

const User = (props) => {
  const [avatar, setAvatar] = useState(null)
  const [loader, setLoader] = useState(true)

  const { me } = props.login

//   me['hola'] = 'hola'
  console.log(me)

  const handleSetAvatar = (avatar_img) => {
    setAvatar(avatar_img)
  }

  const handleupdate = (data) => {
    const imagen = { name: 'avatar', file: avatar }
    data.avatar = imagen
    data['cliente_telefono'] = "+(56)".concat(data["cliente_telefono"].replace('+(56)', ''))
    props.updateProfile(data)
  }

  const handleChangePassword = (data) => {
    data.token = 'password_change'
    props.submitChangePassword(data)
  }

  const handleLoader = () => {
    setTimeout(() => setLoader(false), 1000)
  }

  useEffect(() => {
    handleLoader()
  }, [])

  return (
    <div className='container'>
      <DefaultHeader title='Credenciales de acceso' visible />
      {loader
        ? <LoadMask loading={loader} dark />
        : <div>
          <div className='header__center'>
            <div className='row'>
              <hr />
            </div>
          </div>
          <div className='container'>
            <UserForm
              initialValues={me}
              setAvatar={handleSetAvatar}
              me={me}
              onSubmit={handleupdate}
              changePassword={handleChangePassword}
            />
          </div>
        </div>}
    </div>
  )
}

const ms2p = state => {
  return state
}

const md2p = { ...actions }

export default connect(ms2p, md2p)(User)
