import React, { useEffect } from "react";
import { Field, reduxForm, reset, formValueSelector  } from "redux-form";
import { connect } from 'react-redux';
import validate from "./validate";
import {
  renderField,
  SelectField,
  renderupdateField,
  renderFilePicker,
} from "../../components/Utils/renderField/renderField";
import { Link } from "react-router-dom";

let ProductForm = (props) => {
  const {
    handleSubmit,
    setFile,
    pristine,
    submitting,
    imagen,
    change,
    valid,
    categorias,
    familias,
    selected_familia,
  } = props;

  const unidades_medidas = [
    { value: "500", label: "cc" },
    { value: "200", label: "grs" },
    { value: "100", label: "kg" },
    { value: "300", label: "lt" },
    { value: "800", label: "mg" },
    { value: "600", label: "mk" },
    { value: "400", label: "ml" },
    { value: "700", label: "und" },
  ];

  let opt_categorias = [];
  const opt_familia = [];

//   let categorias_filter = [];
//   {
//     if (categorias.length > 0) {
//       categorias_filter = categorias.filter((cat) => {
//         if (cat.familia) {
//           return cat.familia.id === selected_familia;
//         }
//       })
//     }
//     categorias_filter.map((categoria) =>
//       opt_categorias.push({
//         label: categoria.nombre,
//         value: categoria.id,
//       })
//     )
//   }

  {
    categorias.length > 0
      ? categorias.filter(cat => cat.familia === selected_familia).map((categoria) =>
          opt_categorias.push({
            label: categoria.nombre,
            value: categoria.id,
          })
        )
      : null;
  }
  {
    familias.length > 0
      ? familias.map((familia) =>
          opt_familia.push({
            label: familia.nombre,
            value: familia.id,
          })
        )
      : null;
  }

  return (
    <form className="form" onSubmit={handleSubmit}>
      <hr />
      <div className="ps-form__content">
        <figure className="ps-block--form-box">
          <figcaption>Agregar producto</figcaption>
          <div className="ps-block__content">
            <p style={{ marginLeft: "3px" }}>
              Campos requeridos: <small style={{ color: "red" }}>*</small>
            </p>
            <div className="form-group">
              <Field
                name="imagen"
                imagen={imagen}
                type="file"
                setFile={setFile}
                component={renderFilePicker}
              />
            </div>
            <div className="form-group">
              <label>
                Familia: <small style={{ color: "red" }}>*</small>
              </label>
              <Field
                className="form-control"
                name="familia"
                options={opt_familia}
                component={SelectField}
                placeholder="Familia"
                required
              />
            </div>

            <div className="form-group">
              <label>
                Categorías: <small style={{ color: "red" }}>*</small>
              </label>
              <Field
                className="form-control"
                name="categoria"
                options={opt_categorias}
                component={SelectField}
                placeholder="Categoría"
                required
              />
            </div>

            <div className="form-group">
              <label>
                Marca: <small style={{ color: "red" }}>*</small>
              </label>
              <Field
                name="marca"
                component={renderField}
                type="text"
                placeholder="Marca"
                required
              />
            </div>

            <div className="form-group">
              <label>
                Nombre del producto: <small style={{ color: "red" }}>*</small>
              </label>
              <Field
                name="nombre_comercial"
                component={renderField}
                type="text"
                placeholder="Nombre del producto"
              />
            </div>

            <div className="form-group">
              <label>
                Tipo de formulación:
              </label>
              <Field
                name="tipo_formulacion"
                component={renderField}
                type="text"
                placeholder="Fomulacion"
              />
            </div>
            <div className="form-group">
              <label>
                Ingrediente Activo:
              </label>
              <Field
                name="sustancia_activa"
                component={renderField}
                type="text"
                placeholder="Ingrediente Activo"
              />
            </div>

            <div className="form-group">
              <label>
                Concentración:
              </label>
              <Field
                name="concentracion"
                component={renderField}
                type="text"
                placeholder="Concrentración"
              />
            </div>

            <div className="form-group">
              <label>
                Envase : <small style={{ color: "red" }}>*</small>
              </label>
              <Field
                name="envase"
                component={renderupdateField}
                type="number"
                max={9999.99}
                placeholder="Envase"
              />
            </div>

            <div className="form-group">
              <label>
                Unidad de medida: <small style={{ color: "red" }}>*</small>
              </label>
              <Field
                className="form-control"
                name="unidad_medida"
                options={unidades_medidas}
                component={SelectField}
                placeholder="Unidad de medida"
                required
              />
            </div>



            <div className="form-group">
              <label>
                SKU:
              </label>
              <Field
                name="sku"
                component={renderField}
                type="text"
                placeholder="SKU"
              />
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <Link to="catalogo" className="ps-btn ps-btn--gray">
                  Cancelar
                </Link>
              </div>
              <button
                className={
                  pristine || submitting || !valid
                    ? 'ps-btn'
                    : 'ps-btn'
                }
                style={{
                    backgroundColor: pristine || submitting || !valid ? '#ccc' : '',
                    cursor: pristine || submitting || !valid ? 'not-allowed' : '',
                    color: pristine || submitting || !valid ? '#000' : '' }}
                type='submit'
                disabled={pristine || submitting || !valid}
                >
                Crear
              </button>
            </div>
          </div>
        </figure>
      </div>
    </form>
  );
};

ProductForm = reduxForm({
  form: "new_product_form",
  validate,
  onSubmitSuccess: (result, dispatch) => dispatch(reset("new_product_form")),
})(ProductForm);

const selector = formValueSelector('new_product_form')
ProductForm = connect(state => {
  const selected_familia = selector(state, 'familia')
  return {
    selected_familia,
  }
})(ProductForm)

export default ProductForm
