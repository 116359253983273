import React, { Component, useEffect, useState } from 'react'
import { Table, Modal } from 'antd'
import Multiselect from 'react-widgets/Multiselect'
import moment from 'moment'
import { connect } from 'react-redux'
import { actions } from '../../../redux/modules/productos/productos'

const TableProductos = (props) => {
  const { categorias_ap, familias_ap } = props

  const [value, setValue] = useState([])
  const [cat_selected, setCatSelect] = useState([])
  const [fam_selected, setFamSelect] = useState([])
  const [desde, setDate1] = useState(moment().clone().startOf('month').format('YYYY-MM-DD'))
  const [hasta, setDate2] = useState(moment().format())

  const [dataSource, setDataSource] = useState([])
  const [productos_seleccionados, setProducts] = useState([])
  const [checkedAll, setCheckedAll] = useState(false)
  const [isModalVisible, setModal] = useState(false)
  const [producto, setInfo] = useState([])
  const [Ok, setOk] = useState(false)
  const [Cancel, setCancel] = useState(false)

  const handleSend = () => {
    const data = productos_seleccionados
    props.importProducts(data)
  }

  const showModal = () => {
    setModal(true)
  }

  const handleOk = () => {
    setOk(false)
    setModal(false)
    setInfo([])
  }

  const handleCancel = () => {
    setCancel(false)
    setModal(false)
    setInfo([])
  }

  const ESTADOS = [
    '-',
    'SOLICITADO',
    'FINALIZADA'
  ]

  const filter_status = []

  const opt_categorias = []
  const opt_familias = []

  { categorias_ap.length > 0
    ? categorias_ap.map((option, index) => (
        opt_categorias.push({ value: option.categoria, text: option.categoria })
      ))
    : null
  }

  { familias_ap.length > 0
    ? familias_ap.map((option, index) => (
        opt_familias.push({ value: option.nombre, text: option.nombre })
      ))
    : null
  }

  ESTADOS.map((status, index) => (
    filter_status.push(
      {
        text: status,
        value: (index * 10)
      })
  ))

  const handleCatSelected = (option) => {
    setCatSelect(option)
    // handleCatFilter()
  }

  const handleFamSelected = (option) => {
    setFamSelect(option)
    // handleFamiliaFilter()
  }

  const handleCatFilter = () => {
    let filtered = []
    cat_selected.map(categoria => {
      const filteredData = dataSource.filter(entry => {
        String(entry.categoria).includes(String(categoria.text))
      }
      )
      filtered = filtered.concat(filteredData)
    })
    setDataSource(filtered)
  }

  const handleFamiliaFilter = () => {
    let filtered = []
    fam_selected.map(familia => {
      const filteredData = props.info.filter(entry =>
        String(entry.familia).includes(String(familia.text))
      )
      filtered = filtered.concat(filteredData)
    })
    setDataSource(filtered)
  }

  const handleFilterTable = () => {
    let filtered = []
    fam_selected.map(familia => {
      const filteredData = props.info.filter(entry =>
        String(entry.familia_texto).includes(String(familia.text))
      )
      filtered = filtered.concat(filteredData)
    })
    cat_selected.map(categoria => {
      const filteredData = props.info.filter(entry =>
        String(entry.categoria_texto).includes(String(categoria.text))
      )

      filtered = filtered.concat(filteredData)
    })
    setDataSource(filtered)
  }

  useEffect(() => {
    handleFilterTable()
  }, [fam_selected])

  useEffect(() => {
    handleFilterTable()
  }, [cat_selected])

  const columns = [
    {
      title: () => (<b>Familia</b>),
      filters: opt_familias,
      onFilter: (value, record) => record.familia === value,
      render: (record) => (
        <p>{record.familia_texto}</p>
      )
    },
    {
      title: () => (<b>Categoría</b>),
      filters: opt_categorias,
      onFilter: (value, record) => record.categoria === value,
      render: (record) => (
        <p>{record.categoria_texto}</p>
      )
    },
    {
      title: 'Nombre del producto',
      key: 'id',
      render: (record) => (
        <p>{record.nombre_comercial}</p>
      )

    },
    {
      title: 'Concentración',
      render: (record) => (
        <p>{record.concentracion}</p>
      )
    },
    {
      title: 'Envase / Unidad medida',
      render: (record) => (
        <p>{record.envase} - {record.unidad_medida}</p>
      )
    },
    {
      title: 'Tipo de formulación',
      render: (record) => (
        <p>{record.tipo_formulacion}</p>
      )
    },
    {
      title: () => {
        return (
          <input
            type='checkbox'
            onClick={e => { handleSelectAll(e) }}
          />
        )
      },
      render: (record) => (
        <React.Fragment>
          <input
            type='checkbox'
            onClick={e => { handleSelectProduct(e, record) }}
            checked={checkedAll ? 'checked' : null}
          />
        </React.Fragment>
      )
    }
  ]

  const handleFilterAll = () => {
    setDataSource(props.info)
  }

  const handleCleanAll = () => {
    setDataSource([])
    setCatSelect([])
    setProducts([])
  }

  const handleSelectProduct = (e, record) => {
    let value = []
    const action = e.target.checked
    if (action === false) {
      let value = productos_seleccionados
      const index = value.findIndex(
        (item) => item.id === record.id
      )
      value.splice(index, 1)
      if (value.length === 0) {
        value = []
      }
      setProducts(value)
    }
    if (action === true) {
      value = productos_seleccionados
      value.push(record)
      setProducts(value)
    }
  }

  const handleSelectAll = (e) => {
    const action = e.target.checked
    if (action === true) {
      setProducts(dataSource)
      setCheckedAll(true)
    } if (action === false) {
      setProducts([])
      setCheckedAll(false)
    }
  }

  return (
    <div>
      <div className='form-group'>

        <div className='d-flex justify-content-around'>

          <div className='ps-form__content col-6'>
            <figure className='ps-block--form-box'>
              <figcaption>Familias</figcaption>
              <div className='ps-block__content'>
                <Multiselect
                  placeholder='Familias'
                  dataKey='value'
                  textField='text'
                  data={opt_familias}
                  onChange={select => {
                    handleFamSelected(select)
                  }}
                />
                <hr />
                <div className='d-flex justify-content-around'>
                  {/*
                        <button className="ps-btn">Limpiar</button>
                        <button className="ps-btn">Todo</button>
                    */}
                </div>
              </div>
            </figure>
          </div>

          <div className='ps-form__content col-6'>
            <figure className='ps-block--form-box'>
              <figcaption>Categorías</figcaption>
              <div className='ps-block__content'>
                <Multiselect
                  placeholder='Categorías'
                  dataKey='value'
                  textField='text'
                  data={opt_categorias}
                  onChange={select => {
                    handleCatSelected(select)
                  }}
                />
                <hr />
                <div className='d-flex justify-content-around'>
                  {/*
                        <button className="ps-btn" onClick={handleCleanAll}>Limpiar</button>
                        <button className="ps-btn" onClick={handleFilterAll}>Todos</button>
                    */}
                </div>

              </div>
            </figure>
          </div>

        </div>

        {props.styled
          ? <React.Fragment>
            <section className='ps-dashboard' style={{ alignItems: 'center' }}>
              <div className='d-flex justify-content-between'>
                {/*
                <label htmlFor="estados">Filtrar:</label>
                <div className="col-8 pl-0">
                    <div className="ps-form--search-simple">
                    <input
                        placeholder="Buscar..."
                        className="form-control"
                        value={value}
                        onChange={e => {
                                const currValue = e.target.value;
                                setValue(currValue);
                                const filteredData = props.info.filter(entry =>
                                entry.nombre_comercial.toLowerCase().includes(currValue.toLowerCase())
                                );
                                setDataSource(filteredData);
                        }}
                        >
                    </input>
                    <button><i className="icon icon-magnifier"></i></button>
                    </div>
                    <hr/>
                </div>
                */}

                <div>
                  <button className='ps-btn' onClick={showModal} disabled={dataSource.length > 0 ? null : true}>
                    Importar seleccionados
                  </button>
                  <hr />
                </div>

              </div>
              <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                <div>
                  {/* button here */}
                </div>
                <div>
                  {/* button here */}
                </div>
              </div>
            </section>

            <Table dataSource={dataSource} columns={columns} className='table ps-table--market' />

            </React.Fragment>
          : <Table dataSource={dataSource} columns={columns} />}
      </div>
      <Modal
        title=''
        width={800}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[

        ]}
      >
        <div>
          <h3 style={{ textAlign: 'center' }} className='pb-50'>Confirmación de importación</h3>
          <p style={{ textAlign: 'center' }} className='pb-50'>¿Estás por agregar <b>{`${productos_seleccionados.length}`}</b> productos al catalogo de agromall?</p>
          <div className='d-flex justify-content-around '>
            <button className='ps-btn ps-btn--gray' onClick={handleCancel}>Cancelar</button>
            <button className='ps-btn' onClick={handleSend}>Guardar</button>
          </div>
        </div>
      </Modal>

    </div>

  )
}

const md2p = { ...actions }

export default connect(null, md2p)(TableProductos)
