import React, { useState, useEffect } from "react";
import db_comunas from "../../../database/comunas";
import db_regiones from "../../../database/regiones";
import db_provincias from "../../../database/provincias";
import { Form } from "antd";

import { NotificationManager } from 'react-notifications'

import Geocode from 'react-geocode';

Geocode.setApiKey('AIzaSyCc7uFE7m8XJ17HcYQrXPF2SiTXysfDmss')
Geocode.setLanguage('en')
Geocode.setRegion('es')
Geocode.setLocationType('ROOFTOP')

const UbicacionUpdateForm = (props) => {
  const { handleUpdate, title, ubicacion } = props;

  const [regiones, setRegiones] = useState(db_regiones);
  const [provincias, setProvincias] = useState(db_provincias);
  const [comunas, setComunas] = useState(db_comunas);
  const [filtered_provincias, setFilteredProvincias] = useState([]);
  const [filtered_comunas, setFilteredComunas] = useState([]);

  const [nombre, setNombre] = useState(ubicacion.nombre);
  const [contacto, setContacto] = useState(ubicacion.contacto);
  const [direccion, setDireccion] = useState(ubicacion.direccion);
  const [telefono, setTelefono] = useState(ubicacion.telefono);
  const [provincia, setProvincia] = useState(ubicacion.provincia);
  const [region, setRegion] = useState(ubicacion.region);
  const [comuna, setComuna] = useState(ubicacion.comuna);
  const [provincia_texto, setProvinciaTexto] = useState(ubicacion.provincia_nombre);
  const [region_texto, setRegionTexto] = useState(ubicacion.region_nombre);
  const [comuna_texto, setComunaTexto] = useState(ubicacion.comuna_nombre);


  const handleTelefono = (e) => {
    if (e.target) {
        setTelefono(e.target.value.replace('+(56)', ''));
    }else{
        setTelefono(e.replace('+(56)', ''));
    }
    }
  useEffect(() => {
    // setNombre(ubicacion.nombre);
    // setContacto(ubicacion.contacto);
    // setDireccion(ubicacion.direccion);
    // setTelefono(ubicacion.telefono);
    // setProvincia(ubicacion.provincia);
    // setRegion(ubicacion.region);
    // setComuna(ubicacion.comuna);
    // setProvinciaTexto(ubicacion.provincia_nombre);
    // setRegionTexto(ubicacion.region_nombre);
    // setComunaTexto(ubicacion.comuna_nombre);
    // handleTelefono(ubicacion.telefono);
    handleFormValidation ();
    }
    , [valida_form , nombre, contacto, direccion, telefono, provincia, region, comuna, ubicacion]);


  const [invalid, setInvalid] = useState(false);

  const  [valida_form, setValidaForm] = useState(false);

  const handleChange = (e) => {
    switch (e.target.name) {
        case "nombre":
            setNombre(e.target.value);
            break;
        case "contacto":
            setContacto(e.target.value);
            break;
        case "direccion":
            setDireccion(e.target.value);
            break;
        case "telefono":
            handleTelefono(e.target.value);
            break;
        case "provincia":
            setProvincia(e.target.value);
            break;
        case "region":
            setRegion(e.target.value);
            break;
        case "comuna":
            setComuna(e.target.value);
            break;
        default:
            break;
    }}
  const handleFormValidation = () => {
    if (nombre.length > 0 && contacto.length > 0 && direccion.length > 0 && telefono.toString().length > 0 && provincia > 0 && region > 0 && comuna > 0) {
        setValidaForm(true);
        //telefono
        if (telefono) {
            if(telefono.includes('+')) {
                !/^\+56\d{9}$/.test(telefono.replace(/\((\w+)\)/g, "$1").replace('-', '')) ? setValidaForm(false) : null
                !/^\+56\d{9}$/.test(telefono.replace(/\((\w+)\)/g, "$1").replace('-', '')) ? setInvalid(true) : setInvalid(false)
            }else {
                !/^\d{9}$/.test(telefono.replace(/\((\w+)\)/g, "$1").replace('-', '')) ? setValidaForm(false) : null
                !/^\d{9}$/.test(telefono.replace(/\((\w+)\)/g, "$1").replace('-', '')) ? setInvalid(true) : setInvalid(false)
            }
        }
        // if (!/^([0-9])*$/.test(telefono)) {
        //     setValidaForm(false);
        // }
        // if (telefono.length < 8 || telefono.length > 12) {
        //     setValidaForm(false);
        // }

    }
    else {
        setValidaForm(false);
    }
    }

  const opt_regiones = [];
  const opt_provincias = [];
  let opt_comunas = [];

  {
    regiones.length > 0
      ? regiones.map((option) =>
          opt_regiones.push({ value: option.id, label: option.nombre })
        )
      : null;
  }

  {
    provincias.length > 0
      ? provincias.map((option) =>
          opt_provincias.push({ value: option.value, label: option.label })
        )
      : null;
  }

  {
    comunas.length > 0
      ? comunas.map((option) =>
          opt_comunas.push({ value: option.value, label: option.label })
        )
      : null;
  }

  const handleChangeRegion = (e) => {
    const target = e.target.value;
    const filteredData = provincias.filter((entry) =>
      entry.region.includes(target)
    );
    const region_texto = regiones.find((entry) => entry.id === target);
    setFilteredProvincias(filteredData);
    setRegionTexto(region_texto.nombre);
    setRegion(region_texto.id);
    handleChangeProvincia({ target: { value: filteredData[0].value } });
  };

  const handleChangeProvincia = (e) => {
    const target = e.target.value;
    opt_comunas = [];
    const filteredData = comunas.filter((entry) =>
      entry.provincia.includes(target)
    );
    const provincia_texto = provincias.find((entry) => entry.value === target);
    setFilteredComunas(filteredData);
    setProvinciaTexto(provincia_texto.label);
    setProvincia(provincia_texto.value);
    setComuna(filteredData[0].value);
    setComunaTexto(filteredData[0].label);
  };

  const handleChangeComuna = (e) => {
    const target = e.target.value;
    const comuna_texto = comunas.find((entry) => entry.value === target);
    setComunaTexto(comuna_texto.label);
    setComuna(comuna_texto.value);
  };

  const preSubmit = (data) => {
    Geocode.fromAddress(data.direccion).then((response) => {
      const { lat, lng } = response.results[0].geometry.location;
      data.longitud = lng;
      data.latitud = lat;
      data.provincia = provincia;
      data.comuna = comuna;
      data.comuna_texto = comuna_texto;
      data.provincia_texto = provincia_texto;
      data.region_texto = region_texto;
      data.id = ubicacion.id;
      data.telefono = "+(56)".concat(data["telefono"].replace('+(56)', ''))
      handleUpdate(data);
    },
    (error) => {
      NotificationManager.error("Dirección no válida, por favor verifiquela.", 'ERROR')
    }
    );
  }

  return (
    <Form
      name="basic"
      layout="vertical"
      onFinish={preSubmit}
      initialValues={ubicacion}
      autoComplete="off"
      preserve={false}
      className="form container"
    >
      <div className="ps-form__content">
        <figure className="ps-block--form-box">
          <figcaption style={{ textAlign: "center" }}>{title}</figcaption>
          <div className="ps-block__content">
            <div className="form-group">
              <div className="form-group">
                <Form.Item
                  label="Nombre de sucursal:"
                  name="nombre"
                  rules={[
                    {
                      required: true,
                      message: "Este campo es requerido.",
                    },
                  ]}
                >
                  <input
                    type="text"
                    className="form-control"
                    name="nombre"
                    id="nombre"
                    value={nombre}
                    onChange={handleChange}
                    aria-describedby="helpId"
                    placeholder="Nombre sucursal"
                  />
                </Form.Item>
              </div>
              <div className="form-group">
                <Form.Item
                  label="Persona de contacto:"
                  name="contacto"
                  rules={[
                    {
                      required: true,
                      message: "Este campo es requerido.",
                    },
                  ]}
                >
                  <input
                    type="text"
                    className="form-control"
                    name="contacto"
                    id="contacto"
                    value={contacto}
                    onChange={handleChange}
                    aria-describedby="helpId"
                    placeholder="Nombre contacto"
                  />
                </Form.Item>
              </div>
              <Form.Item
                label="Dirección:"
                name="direccion"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido.",
                  },
                ]}
              >
                <input
                  type="text"
                  className="form-control"
                  name="direccion"
                  id="direccion"
                  value={direccion}
                  onChange={handleChange}
                  aria-describedby="helpId"
                  placeholder="Dirección"
                />
              </Form.Item>
            </div>
            <div className="form-group">
              <Form.Item
                label="Telefono:"
                name="telefono"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido.",
                  },
                ]}
              >
                <div className="input-group">
                    <span
                    className="input-group-text" id="basic-addon1"
                    style={{fontSize: '1.3rem'}}
                    >+(56)</span>
                    <input
                        placeholder="Telefono"
                        type='text'
                        name="telefono"
                        id="telefono"
                        minLength='9'
                        maxLength='9'
                        className="form-control"
                        value={telefono.replace('+(56)', '')}
                        required title="Minimo 9 digitos numericos"
                        onChange={handleTelefono}
                        aria-describedby="basic-addon1"
                        pattern="[0-9]+"
                    />
                </div>
                {/* <input
                  type="text"
                  className="form-control"
                  name="telefono"
                  id="telefono"
                  value={telefono}
                  onChange={handleChange}
                  aria-describedby="helpId"
                  placeholder="Telefono"
                /> */}
              </Form.Item>
                {invalid && <span style={{color:'#ff4d4f'}}>El telefono debe tener 9 digitos numericos</span>}
            </div>
            <div className="form-group">
              <Form.Item
                label="Región:"
                name="region"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido.",
                  },
                ]}
              >
                <select
                  className="form-control"
                  aria-label="Default select example"
                  name="region"
                  value={region}
                  onChange={(e) => handleChangeRegion(e)}
                >
                  {opt_regiones.map((e, index) => (
                    <option key={index} value={e.value}>
                      {e.label}
                    </option>
                  ))}
                </select>
              </Form.Item>
            </div>
            <div className="form-group">
              <Form.Item
                label="Provincia:"
                name="provincia"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido.",
                  },
                ]}
              >
                <select
                  className="form-control"
                  aria-label="Default select example"
                  name="provincia"
                  value={provincia}
                  onChange={handleChangeProvincia}
                >
                  {filtered_provincias.length > 0
                    ? filtered_provincias.map((e, index) => (
                        <option key={index} value={e.value}>
                          {e.label}
                        </option>
                      ))
                    : opt_provincias.map((e, index) => (
                        <option key={index} value={e.value}>
                          {e.label}
                        </option>
                      ))}
                </select>
              </Form.Item>
            </div>
            <div className="form-group">
              <Form.Item
                label="Comuna:"
                name="comuna"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido.",
                  },
                ]}
              >
                <select
                  className="form-control"
                  aria-label="Default select example"
                  name="comuna"
                  value={comuna}
                  onChange={handleChangeComuna}
                >
                  {filtered_comunas.length > 0
                    ? filtered_comunas.map((e, index) => (
                        <option key={index} value={e.value}>
                          {e.label}
                        </option>
                      ))
                    : opt_comunas.map((e, index) => (
                        <option key={index} value={e.value}>
                          {e.label}
                        </option>
                      ))}
                </select>
              </Form.Item>
            </div>
            <div
              className="container"
              style={{ display: "flex", justifyContent: "space-around" }}
            >
              <button
                className={
                  !valida_form
                    ? 'ps-btn'
                    : 'ps-btn'
                }
                style={{
                    backgroundColor: !valida_form ? '#ccc' : '',
                    cursor:!valida_form ? 'not-allowed' : '',
                    color: !valida_form ? '#000' : '' }}
                type='submit'
                disabled={!valida_form}
                >
                Guardar
              </button>

            </div>
          </div>
        </figure>
      </div>
    </Form>
  );
};

export default UbicacionUpdateForm;
