import React from 'react'
import { connect } from 'react-redux'
import { Currency } from 'react-intl-number-format/dist/index.js'
import { Link } from 'react-router-dom'
import { actions } from '../../../../redux/modules/cuenta/login'

const WidgetEarningSidebar = (props) => {
  const { me } = props.login

  const handleLogout = (e) => {
    props.logOut()
  }

  return (
    <div>
      {me.tipo_usuario === 20
        ? <div className='ps-block--earning-count'>
          <hr />
          <strong>Actividad del mes</strong>
          <h3 className='fw-b'>
            <Currency locale='es-CL' currency='CLP'>{props.cotizacion.pagado.pagado}</Currency>
          </h3>

        </div>
        : null}
    </div>

  )
}

const ms2p = (state) => {
  return state
}

const md2p = { ...actions }

export default connect(ms2p, md2p)(WidgetEarningSidebar)
