import React, { Component } from "react";
import { Link } from "react-router-dom";
import DefaultHeader from "../layout/Header/DefaultHeader";
import LoadMask from "../Utils/LoadMask/LoadMask";
import CreateImage from "../../../../assets/img/create_product.png";
import SyncImage from "../../../../assets/img/sync.png";
import SimpleBorderedCard from "../Utils/Cards/Card";
class MenuProductos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
    };
  }

  componentDidMount() {
    this.handleLoader();
  }

  handleLoader = () => {
    setTimeout(() => this.setState({ loader: false }), 500);
  };

  render() {
    return (
      <div className="container">
        <DefaultHeader title="Agregar un producto" visible={true} />
        {this.state.loader ? (
          <LoadMask loading={this.state.loader} dark></LoadMask>
        ) : (
          <div className="row">
            <div
              className="container"
              style={{ display: "flex", justifyContent: "space-evenly" }}
            >
              <SimpleBorderedCard
                url="/importar-productos"
                title="Importar sugeridos"
                description="Productos sugeridos por el marketplace."
                img={SyncImage}
              />
              <SimpleBorderedCard
                url="/nuevo-producto"
                title="Crear producto"
                description="Crear productos manualmente"
                img={CreateImage}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default MenuProductos;
