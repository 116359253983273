import React, { Component } from 'react'
import { Currency } from "react-intl-number-format/dist/index.js";
import {actions} from '../../../redux/modules/cotizaciones/cotizacion';
import { connect } from 'react-redux';
import { Link} from "react-router-dom";
import { Modal } from 'antd';
import {api} from '../../../utility/api'
import moment from 'moment';


class DetalleLista extends Component {

  constructor(props) {
    super(props);

    this.state = {
        id : this.props.id,
        cotizacion: [],
        iva: 0.19,
        monto_pagado: "",
        encabezado: [],
        prueba: [],
        status: false,
        editable: [],
        _total: 0,
        isModalVisible: false,
        handleOk:false,
        handleCancel:false,
        scrollPosition: ''
    };
} 

    openModal = (e) => {
        e.preventDefault()
        window.scrollTo(0, 0)
         this.setState({isModalVisible:true});

    };

    closeModal = () => {
        this.setState({isModalVisible:false});
    };
 
    handleClick = (e) => {
        history.push("/");
    }

    handleSubmit = (data) =>{
        this.props.updateStateCotizacion(data)
    }

    calculateSubtotal = (producto, value, index) => {
        let total_c= 0
        const { cotizacion } = this.state;
        let precio
        {value ? precio = value :precio = 0 }
        total_c = producto.cantidad * precio;
        total_c = total_c+(total_c*this.state.iva)
        const newCounters = Object.assign(cotizacion, {
            ...cotizacion,
            [index]: { ...cotizacion[index], total: total_c , precio: value, unidad: value}
          });
        this.setState({ cotizacion: newCounters });
        this.calculateTotal();
        }

        calculateTotal = () => {
            let total = 0
            this.state.cotizacion.map(monto =>(
                total += parseInt(monto.total)
            ))
            this.setState({_total: total})
        }

        setTotal = (cotizacion) => {
            let total = 0
            cotizacion.map(monto =>(
                total += parseInt(monto.total)
                
            ))
            this.setState({_total: total})
        }

      
        componentDidMount(){
            var id = this.state.id;
            this.getCotizacion(id);
            this.calculateTotal();
            this.setTotal(this.state.cotizacion)
        }
    

      getCotizacion = async() => {
        const SPProducts = await api.get(`cotizaciones/resumen/?identifier=${this.state.id}`).then((response) =>{
            //setProductItems(response.results); 
            if (response) {
                this.setState({cotizacion:response["productos"]})
                this.setState({encabezado:response["cotizacion"]})
                this.setState({prueba:response["entrega"]})
                this.setState({monto_pagado:response["monto_pagado"]})
            } else {
                //
            }
            setTimeout(function () {
            }, 500);
            return SPProducts;
        })
    }

    

render() {
    const {cotizacion , encabezado, _total, prueba} = this.state
    return (
    <div> 
        <div className="d-flex flex-row justify-content-between">
            <h2>Venta #{encabezado.id}</h2>
            <div>
                <h4>Cliente: {encabezado.cliente}</h4>
                <h4>RUT: {encabezado.rut} </h4>
                <h4>Fecha de cotización: {moment(encabezado.fecha_creacion).format("L")}</h4>
            </div>
        </div>   
        <div className="ps-section__cart-actions" style={{'display':'flex' }}>
            <Link 
                to="/en-proceso"
                className="ps-btn ps-btn mr-5" style={{backgroundColor: "#2e3d50", color: "white"}}
            >
                Regresar
            </Link>
        </div>
        <hr/>
        <form className="ps-form--account">                    

        <table className="table ps-table--market">
            <thead>
                <tr>
                    <th>Producto</th>
                    <th>Envase</th>
                    <th>Cantidad</th>
                    <th>Precio Unitario (CLP+IVA)</th>
                    <th>VALOR TOTAL (CLP)</th>
                </tr>
            </thead>
            <tbody>
            
            {cotizacion.map((c , index) => (
                <tr key={c.id}>
                    {c.descripcion.map(producto => (
                        <React.Fragment>
                            <td>
                            {producto.nombre_comercial}  {producto.envase}
                            </td>
                            <td>
                            {producto.envase}
                            </td>
                            <td>
                            {c.cantidad}
                            </td>
                            <td>
                            <Currency locale="es-CL" currency="CLP">{c.precio}</Currency>
                            </td>
                        </React.Fragment>
                    ))}

                    <td>
                        {c.total > 0
                        ?
                        <Currency locale="es-CL" currency="CLP">{c.cantidad*c.precio + ((c.cantidad*c.precio)*0.19)}</Currency>
                        :
                        <Currency locale="es-CL" currency="CLP">0.00</Currency>
                        }
                            
                    </td>
                </tr>
            ))}
            </tbody>
        </table>               
        <hr/>
        <div style={{'display':'flex' , 'justifyContent': 'space-between' , 'alignItems':"flex-end"}}>
        <div className="ps-section__cart-actions" style={{'display':'flex' }}>
            <div>           
               {/* <button className="ps-btn " onClick={this.openModal}>Comprobante</button>*/}
               
                   <a className="ps-btn" href={prueba.foto_entrega} target="_blank">Comprobante</a>
               
            </div>
        </div>
        <div className="ps-container" style={{'display':'flex' , 'justifyContent': 'space-between' }}>
            <div>
                <h3>
                    Precio total: 
                </h3>
                <h3 className="pl-2 pr-10 mr-30" style={{'color':'#FCB800'}}>
                <Currency locale="es-CL" currency="CLP">{this.state.monto_pagado}</Currency>
                </h3>
            </div>

        <Modal 
            width={800}
            title="" 
            visible={this.state.isModalVisible} 
            onOk={this.closeModal}
            onCancel={this.closeModal}
            footer={[
                <button type="reset" className="ps-btn ps-btn" onClick={this.closeModal}>Cerrar</button>
            ]}
            >
                {prueba.foto_entrega === null? 
                <p>No hay ningun comprobante guardado.</p>
                :
                    <div className="container">
                        <img
                            className="img-entrega"
                            src={prueba.foto_entrega}
                            alt={prueba.foto_entrega}
                        />
                    </div>
                }
        </Modal>
        </div>
    </div>
    </form>

    </div>

);
}


};

const ms2p = (state) => {
    return state.cotizacion;
  };
  
  const md2p = { ...actions };
  
export default connect(ms2p, md2p)(DetalleLista);