import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Table, Modal } from "antd";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { updateProducto } from "../../../redux/modules/solicitudes/solicitudes";
import {
  getProductos,
  updateProduct,
  deleteProduct,
} from "../../../redux/modules/productos/productos";
import UpdateForm from "./UpdateForm";
import Swal from "sweetalert2";
import "../../../../style/scss/custom/TableProductos.module.scss";

const TableProductos = (props) => {
  const history = useHistory();
  const { me, familias, categorias } = props;
  const { mis_productos } = props.productos;
  const [dataSource, setDataSource] = useState(mis_productos);
  const [categorias_filter, setFilter] = useState(categorias);
  const [familias_filter, setFamiliaFilter] = useState(familias);
  const [value, setValue] = useState("");
  const [modalEditar, setModalEditar] = useState(false);
  const [Cancel, setCancel] = useState(false);
  const [producto, setInfo] = useState([]);
  const [producto_imagen, setImagen] = useState(null);
  const [value_marca, setMarcaFilter] = useState("");
  const [prod_activo, setProduct] = useState();

  const handleSetImagen = (imagen) => {
    setImagen(imagen);
  };

  const handleSend = (data) => {
    props.updateProduct(data);
    closeModal();
  };

  const closeModal = () => {
    handleOk();
    setCancel(false);
    setModalEditar(false);
    setInfo([]);
    setImagen(null);
  };

  const opt_categoria = [];
  const opt_familia = [];

  {
    categorias_filter.length > 0
      ? categorias_filter.map((categoria) =>
          opt_categoria.push({
            text: categoria.nombre,
            value: categoria.nombre,
          })
        )
      : null;
  }

  {
    familias_filter.length > 0
      ? familias_filter.map((familia) =>
          opt_familia.push({
            text: familia.nombre,
            value: familia.nombre,
          })
        )
      : null;
  }

  const handleChange = (e, record) => {
    const data = { id: record.id, action: e.target.checked };
    props.updateProducto(data);
    setDataSource(mis_productos);
  };

  const handleSetProduct = (record) => {
    const producto_seleccionado = record;
    producto_seleccionado["categoria"] = record.categoria_id;
    producto_seleccionado["familia"] = record.familia_id;
    producto_seleccionado["tipo_formulacion"] =
      record.descripcion.tipo_formulacion;
    producto_seleccionado["sustancia_activa"] =
      record.descripcion.sustancia_activa;
    producto_seleccionado["concentracion"] = record.descripcion.concentracion;
    producto_seleccionado["unidad_medida"] = record.descripcion.unidad_medida;
    setInfo(producto_seleccionado);
  };

  const handleOk = () => {
    setInfo([]);
    setCancel(false);
    setModalEditar(false);
  };

  const handleDetail = (record) => {
    history.push(`/catalogo/producto/${record.id}`);
  };

  const showModalEditar = () => {
    setModalEditar(true);
  };

  const sweet = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-lg ps-btn ps-btn--opt button--min--size",
      cancelButton: "btn btn-lg btn-danger",
    },
    buttonsStyling: false,
  });

  const handleDeleteProduct = (e) => {
    sweet
      .fire({
        title: "¿Estás seguro de eleminar el producto?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        denyButtonText: `Cancelar`,
      })
      .then((result) => {
        if (result.value) {
          props.deleteProduct(e);
          setTimeout(() => {
            props.getProductos({ type: "SET_PRODUCTOS" });
            setDataSource(mis_productos);
          }, 1000);
        }
      });
  };

  useEffect(() => {
    setDataSource(mis_productos);
  }, [mis_productos]);

  const columns = [
    {
      title: "Producto",
      dataIndex: "nombre_comercial",
      key: "id",
      showSorterTooltip: false,
    },
    {
      title: "Envase / Unidad medida",
      render: (record) => `${record.envase} ${record.unidad_medida_texto}`,
    },
    {
      title: "Categoría",
      filters: opt_categoria,
      onFilter: (value, record) => record.descripcion.categoria === value,
      render: (record) => record.descripcion.categoria,
    },
    {
      title: "Familia",
      filters: opt_familia,
      onFilter: (value, record) => record.descripcion.familia === value,
      render: (record) => record.descripcion.familia,
    },
    {
      title: "Activo",
      dataIndex: "activo",
      sorter: (a, b) => a.activo - b.activo,
      showSorterTooltip: false,
      render: (activo, record) => {
        if (record.aprobado === true) {
          return (
            <FormControlLabel
              control={
                <Switch
                  checked={activo}
                  onClick={(e) => {
                    handleChange(e, record);
                    props.getProductos({ type: "SET_PRODUCTOS" });
                  }}
                  name="checkedB"
                  color="primary"
                />
              }
            />
          );
        } else {
          return <p>En revision...</p>;
        }
      },
    },
    {
      title: "-",
      key: "id",
      render: (record) => (
        <React.Fragment>
          <div className="wrap justify-content-evenly">
            <div>
              <button
                className="ps-btn ps-btn--opt"
                onClick={() => {
                  handleDetail(record);
                }}
              >
                <i className="icon-eye" />
              </button>
            </div>
            <div>
              <button
                className="ps-btn ps-btn--opt"
                onClick={(e) => {
                  handleSetProduct(record);
                  showModalEditar(true);
                }}
              >
                <i className="icon-pencil"></i>
              </button>
            </div>
            <div>
              <button
                className="ps-btn ps-btn--opt"
                onClick={(e) => {
                  handleDeleteProduct(record);
                }}
              >
                <i className="icon-trash"></i>
              </button>
            </div>
          </div>
        </React.Fragment>
      ),
    },
  ];

  return (
    <div>
      <div className="form-group">
        <section className="pl-0 pr-0">
          <div
            className="navbar justify-content-beetwen"
            style={{
              padding:"0"
            }}
          >
            <div className="d-flex justify-content-start">
              {me.tienda.cliente_ap ? (
                <Link to="/agregar-producto" className="ps-btn">
                  Agregar un producto
                </Link>
              ) : (
                <Link to="/nuevo-producto" className="ps-btn">
                  Agregar un producto
                </Link>
              )}
            </div>


              <div className="ps-form--search-simple">
                <input
                  placeholder="Buscar..."
                  className="form-control"
                  value={value}
                  onChange={(e) => {
                    const currValue = e.target.value;
                    setValue(currValue);
                    const filteredData = mis_productos.filter((entry) =>
                      entry.nombre_comercial
                        .toLowerCase()
                        .includes(currValue.toLowerCase())
                    );
                    setDataSource(filteredData);
                  }}
                />
                <button>
                  <i className="icon icon-magnifier" />
                </button>
              </div>

          </div>
        </section>
        <hr />
        <Table
          dataSource={dataSource}
          rowKey="id"
          columns={columns}
          className="table ps-table--market"
        />
      </div>
      <Modal
        title=""
        width={800}
        visible={modalEditar}
        onOk={handleOk}
        onCancel={closeModal}
        footer={[
          <button
            type="reset"
            className="ps-btn ps-btn--gray"
            onClick={closeModal}
          >
            Cerrar
          </button>,
        ]}
      >
        <div className="container">
          <UpdateForm
            onSubmit={handleSend}
            setImagen={handleSetImagen}
            producto={producto}
            familias={familias}
            categorias={categorias}
            initialValues={producto}
            imagen={producto_imagen}
            title="Actualizar producto"
          />
        </div>
      </Modal>
    </div>
  );
};

const ms2p = (state) => {
  return state;
};

const md2p = { updateProducto, getProductos, updateProduct, deleteProduct };

export default connect(ms2p, md2p)(TableProductos);
