import { handleActions } from 'redux-actions'
import { push } from 'react-router-redux'
import { initialize as initializeForm } from 'redux-form'
import { NotificationManager } from 'react-notifications'
import { useHistory } from 'react-router-dom'
import { api } from 'api'
import { notification } from 'antd'
import { showSweet } from '../notificaciones/notificaciones'

const actionTypes = {
  VIEW_COTIZACION: 'VIEW_COTIZACION',
  UPDATE_COTIZACION: 'UPDATE_COTIZACION',
  LOADER: 'LOGIN_LOADER',
  GET_PAGADO: 'GET_APGADO',
  SET_PAGADO: 'SET_PAGADO',
  COTIZACION_SIN_ENVIAR: 'COTIZACION_SIN_ENVIAR',
  COTIZACION_ENVIADAS: 'COTIZACION_ENVIADAS',
  COTIZACION_TOTAL: 'COTIZACION_TOTAL'

}

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
  type: actionTypes.LOADER,
  loader
})

const modalSuccessAny = (type, message, description) => {
  notification[type]({
    message: message,
    description: description,
    duration: 2
  })
}

export function updateCotSuccess (payload) {
  return {
    type: actionTypes.UPDATE_COTIZACION,
    payload
  }
}

export function setPagados (pagado) {
  return {
    type: actionTypes.SET_PAGADO,
    pagado
  }
};
export const getPagados = () => (dispatch) => {
  api.get('#/cotizaciones/p_paid').then(pagado => {
    dispatch(setPagados(pagado))
  })
    .catch(() => {
    }).finally(() => {})
}

// ------------------------------------
// Actions
// ------------------------------------

export const detailCotiz = (id) => (dispatch, getStore) => {
  dispatch(setLoader(true))
  api.get(`api/cotizaciones/${id}`).then((response) => {
    dispatch(push('#/cotizacion'))
  }).catch(() => {
    NotificationManager.error('Hubo un error al realizar la accion, intenta mas tarde', 'ERROR', 0)
  }).finally(() => {
    dispatch(setLoader(false))
  })
}

export const onSubmitCotizacion = (cotizacion) => (dispatch) => {
  dispatch(setLoader(true))

  function round(num) {
  var m = Number((Math.abs(num) * 100).toPrecision(15));
  return Math.round(m) / 100 * Math.sign(num);
  }

  for (let i = 0; i < cotizacion.length; i++) {
  console.log(cotizacion[i])
  cotizacion[i].total = round(cotizacion[i].total)
  }

  api.post('cotizaciones/productos/cotizacion/update_cotizacion', cotizacion).then((response) => {
    // dispatch(showSweet('success'));
    NotificationManager.success('Se ha enviado los datos correctamente', 'SUCCESS', 3000)
    dispatch(push('#/cotizaciones'))
    window.location.reload()
  }).catch(() => {
    NotificationManager.error('Hubo en error en el formulario', 'ERROR', 1500)
  }).finally(() => {
    dispatch(setLoader(false))
  })

}

export const updateStateCotizacion = (data) => (dispatch) => {
  api.post('cotizaciones/actualizar', data).then((response) => {
    const message = 'Se ha actualizado correctamente'
    modalSuccessAny('success', message, '')
    dispatch(push('/en-proceso'))
  }).catch(() => {
    const message = 'Hubo un error al enviar el formulario'
    dispatch(modalSuccessAny('error', message, ''))
  }).finally(() => {

  })
}

export const entregaProductos = (data = {}, attachments = []) => (dispatch) => {
  api.putAttachments('cotizaciones/entrega', data, attachments).then((response) => {
    const message = 'Se ha entregado esta venta'
    modalSuccessAny('success', message, '')
    dispatch(push('/en-proceso'))
  }).catch((error) => {
    const message = error.detail
    dispatch(modalSuccessAny('error', message, ''))
  }).finally(() => {

  })
}

export const addCotizacionItem = (producto) => (dispatch) => {
  try {
    const localCot = JSON.parse(localStorage.getItem('cotizacion'))
      .cart
    const currentCot = JSON.parse(localCot)
    const existItem = currentCot.cotItems.find(
      (item) => item.id === product.id
    )
    if (existItem) {
      existItem.precio === producto.precio
    } else {
      currentCot.cotItems.push(producto)
    }
    dispatch(updateCotSuccess(currentCot), modalSuccess('success'))
  } catch (err) {
    modalSuccess('error')
  }
}

export const getTotales = (data) => (dispatch) => {
  api.get(`/cotizaciones/get_vendor_cot/?estado=${data}`).then(todas => {
    dispatch({ type: actionTypes.COTIZACION_TOTAL, todas })
  })
    .catch(() => {
    }).finally(() => {})
}

export const getSinEnviar = (data) => (dispatch) => {
  api.get(`/cotizaciones/get_vendor_cot/?estado=${data}`).then(sin_enviar => {
    dispatch({ type: actionTypes.COTIZACION_SIN_ENVIAR, sin_enviar })
  })
    .catch(() => {
    }).finally(() => {})
}

export const getEnviadas = (data) => (dispatch) => {
  api.get(`/cotizaciones/get_vendor_cot/?estado=${data}`).then(enviadas => {
    dispatch({ type: actionTypes.COTIZACION_ENVIADAS, enviadas })
  })
    .catch(() => {
    }).finally(() => {})
}

export const actions = {
  detailCotiz,
  addCotizacionItem,
  onSubmitCotizacion,
  updateStateCotizacion,
  entregaProductos,
  getPagados,
  getTotales,
  getSinEnviar,
  getEnviadas
}

export const reducers = {
  [actionTypes.LOADER]: (state, { loader }) => {
    return {
      ...state,
      loader
    }
  },
  [actionTypes.SET_PAGADO]: (state, { pagado }) => {
    return {
      ...state,
      pagado
    }
  },
  [actionTypes.COTIZACION_TOTAL]: (state, { todas }) => {
    return {
      ...state,
      todas
    }
  },
  [actionTypes.COTIZACION_SIN_ENVIAR]: (state, { sin_enviar }) => {
    return {
      ...state,
      sin_enviar
    }
  },
  [actionTypes.COTIZACION_ENVIADAS]: (state, { enviadas }) => {
    return {
      ...state,
      enviadas
    }
  }
}

export const initialState = {
  loader: false,
  cotItems: [],
  cotizacion: {
  },
  todas: {},
  sin_enviar: {},
  enviadas: {},
  pagado: {}
}

export default handleActions(reducers, initialState)
