import { handleActions } from 'redux-actions';
import { api } from 'api';

const actionTypes = {
    SET_CALIFICACIONES: 'SET_CALIFICACIONES'    
}

export function setMisProductos (calificaciones) {
    return {
      type: actionTypes.SET_CALIFICACIONES,
      calificaciones
    }
};

export const getCalifications = (params) => (dispatch) => {
    api.get('tiendas-rating' + params).then(mis_productos => {
      dispatch(setMisProductos(mis_productos))
    }).catch(() => {
      
    }).finally(() => {});
}  

export const actions = {
    getCalifications
}

export const reducers = {
    [actionTypes.SET_CALIFICACIONES]: (state, { calificaciones }) => {
      return {
        ...state,
        calificaciones
      }
    }
}

export const initialState = {
    calificaciones: []
}

export default handleActions(reducers, initialState)