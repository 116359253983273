import { handleActions } from "redux-actions";
import { api } from "api";
import { NotificationManager } from "react-notifications";

const actionTypes = {
  SET_DEVOLUCIONES: "SET_DEVOLUCIONES",
};

export function setDevoluciones(devoluciones) {
  return {
    type: actionTypes.SET_DEVOLUCIONES,
    devoluciones,
  };
}

export const getDevoluciones = (params) => (dispatch) => {
  api.get("devoluciones" + params).then((devoluciones) => {
    dispatch(setDevoluciones(devoluciones));
  });
};

export const updateDevoluciones = (data) => (dispatch) => {
  api
    .put("devoluciones/" + data.id, data)
    .then(() => {
      NotificationManager.success(
        "Satisfactorio",
        "Devolución actualizada satisfactoriamente."
      );
    })
    .catch((err) => {
      NotificationManager.error("¡Atención!", "Error al actualizar.");
    });
};

export const actions = {
  getDevoluciones,
  updateDevoluciones
};

export const reducers = {
  [actionTypes.SET_DEVOLUCIONES]: (state, { devoluciones }) => {
    return {
      ...state,
      devoluciones,
    };
  },
};

export const initialState = {
  devoluciones: [],
};

export default handleActions(reducers, initialState);
