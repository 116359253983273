import React, { Component, useLayoutEffect, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Field, reduxForm } from 'redux-form'
import { Currency } from 'react-intl-number-format/dist/index.js'
import { onlyReadRenderField } from '../Utils/renderField/index'
import { SelectField } from '../Utils/renderField/renderField'
import  Info_retiro from './Info_retiro'
import { api } from '../../../utility/api'
import { validate, validators } from "validate-redux-form";
import LoadMask from "../Utils/LoadMask/LoadMask";


const RetiroForm = (props) => {
  const { iva, handleSubmit, ventas, cuentas, change, comisionDataProps, loadingProps} = props

  const [error, SetError] = useState(false)
  const [seleccionados, setSeleccionado] = useState([])
  const [total_ventas, setTotal] = useState(0)
  const [isModalVisible, setVisible] = useState()
  const [loading, setLoading] = useState(true)
  const [comisionData, setComisionData] = useState(comisionDataProps || [])

//   let dataComision = []
  const calculateComisiones = () => {
    let data = {id_cotizacion: []}
    ventas.map((venta) =>{
        data.id_cotizacion.push(venta.cotizacion)
    })
    setLoading(true)
    api.post(`/categorias-feeds-commissions/calcule_comisions`, data)
        .then((res) => {
            return setComisionData(res)
        })
        .catch((err) => {
            console.log(err);
        })
        .finally(() => {
            setLoading(false)
        });
    // setComisionData(dataComision)
    // return dataComision
  };

  const opt_bancos = []

  { cuentas.length > 0
    ? cuentas.map((option) => {
        if (option.activo === true) {
          return (
            opt_bancos.push({ value: option.id, label: `${option.banco} - [${option.tipo}] (${option.no_cuenta})` })
          )
        }
      }
      )
    : null
  }

  const handleSelectProduct = (e, venta) => {
    let value = {}
    const action = e.target.checked
    if (action === false) {
      let value = seleccionados
      const index = value.findIndex(
        (item) => item.cotizacion === venta.cotizacion
      )
      value.splice(index, 1)
      setSeleccionado(value)
      if (value.length === 0) {
        value = []
      }
    }
    if (action === true) {
      value =
            {
              id: parseInt(venta.cotizacion),
              monto_solicitado: parseInt(venta.monto_venta)
            }
      seleccionados.push(value)
      setSeleccionado(seleccionados)
    }
    calculateTotal()
  }

  const calculateTotal = () => {
    let total = 0
    seleccionados.map(venta => (
      total += parseInt(venta.monto_solicitado)
    ))
    setTotal(total),
    change('ventas', seleccionados)
  }

  useLayoutEffect(() => {
    change('ventas', seleccionados)
    calculateComisiones()
  }, [])

  const handleCancel = () => {
    setVisible(false)
  }
//   useEffect(() => {
//     calculateComisiones()
//   }, [])

  return (
    <LoadMask loading={loading} blur={true}>
    <div>
      <div className='container'>
        <h4 style={{ textAlign: 'center' }}>Selecciona las ventas que deseas retirar</h4>
        <Info_retiro />
        <form onSubmit={handleSubmit}>
          <table className='table table-striped'>
            <thead>
              <tr>
                <th>Cotizacion</th>
                <th>Cotizado</th>
                <th>Monto Comprado Total</th>
                <th>Comisiones + IVA</th>
                <th>Disponible retiro</th>
                <th>-</th>
              </tr>
            </thead>
            <tbody>
              {ventas.length > 0
                ? ventas.map((venta, index ) => {
                    // console.log(comisionData)
                    return (
                    <tr>
                      <td>{venta.cotizacion}</td>
                      <td>
                        <Currency locale='es-CL' currency='CLP'>{venta.total_cotizado}</Currency>
                      </td>
                      <td>
                        <Currency locale='es-CL' currency='CLP'>{venta.monto_venta}</Currency>
                      </td>
                      <td>
                        <Currency locale='es-CL' currency='CLP'>{comisionData.length > 0 ? comisionData[index].comision_total : 0}</Currency>
                      </td>
                      <td>
                        <Currency locale='es-CL' currency='CLP'>{venta.monto_venta - (comisionData.length > 0 ? comisionData[index].comision_total : 0) }</Currency>
                      </td>
                      <td>
                        <input type='checkbox' onClick={(event) => handleSelectProduct(event, venta)} />
                      </td>
                    </tr>
                    )
                })
                : null}
            </tbody>
          </table>
          {opt_bancos.length > 0
            ? <div>
              <div className='' style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div className='form-group col-6 pt-10'>
                  <label htmlFor=''>Cuenta</label>
                  <Field
                    className='form-control'
                    name='banco'
                    options={opt_bancos}
                    component={SelectField}
                    placeholder='Cuenta bancaria'
                    required
                  />
                </div>
                <h3 style={{ fontWeight: 'bold' }}>Monto a retirar:<Currency locale='es-CL' currency='CLP'>{total_ventas}</Currency></h3>
              </div>
              <hr />

              <div className='container' style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <div className='container mt-20 pt-20' style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                  <div>
                    <Field
                      name='ventas'
                      component={onlyReadRenderField}
                      placeholder='Cantidad (CLP)'
                      defaultValue={total_ventas}
                    />
                    <button
                      type='submit'
                      className={total_ventas > 0 ? 'ps-btn' : 'ps-btn ps-btn--gray'}
                      disabled={total_ventas > 0 ? '' : 'disabled'}
                    >
                      Enviar solicitud
                    </button>
                  </div>
                </div>
              </div>
            </div>
            : <div>
              <p style={{ color: 'red', textAlign: 'center' }}>
                No puedes solictar el retiro de tus fondos
                porque no tiene creada una cuenta corriente
                de destino o  no está activada.
                puedes revisar las cuentas bancarias
                en: <Link to='/cuentas-bancarias'> <a>Perfil → Cuentas bancarias</a></Link>
              </p>
            </div>}

        </form>
      </div>
    </div>
    </LoadMask>
  )
}

export default reduxForm({
  form: 'solicitud_retiro', // a unique identifier for this form
  destroyOnUnmount: false, // <------ preserve form data
  validate: (data) => {
    return validate(data, {
        banco: validators.exists()("Este campo es requerido"),
    });
},
})(RetiroForm)
