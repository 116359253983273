import React, { Component } from "react";
import { Link } from "react-router-dom";
import { api } from "../../../utility/api";
import DefaultHeader from "../layout/Header/DefaultHeader";
import { Table, Tag } from "antd";
import moment from "moment";
import "../../../../style/scss/custom/Cotizacion.module.scss";

class Cotizaciones extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    cotizaciones: [],
    seleccionado: "/cotizaciones",
    estado: "1",
    value: "",
    dataSource: [],
  };

  componentDidMount() {
    this.getCotizaciones(this.state.estado);
  }

  getCotizaciones = async (estado) => {
    const reponse = api
      .get(`/cotizaciones/cotizaciones_tagged/?estado=${estado}`)
      .then((reponse) => {
        this.setState({ cotizaciones: reponse });
        this.setState({ dataSource: reponse });
      })
      .catch((error) => {
        {
          error: JSON.stringify(error);
        }
        this.setState({ cotizaciones: [] });
      });
    return reponse;
  };

  handleSetActive = (ruta) => {
    this.setState({ cotizaciones: [] });
    this.setState({ dataSource: [] });
    this.setState({ seleccionado: `${ruta.url}` });
    let estado = ruta.id;
    this.getCotizaciones(estado);
  };

  render() {
    let coti = this.state.cotizaciones;
    const get_ruta = location.href.split("/");
    let route = "/" + get_ruta[get_ruta.length - 1];
    let keyword = route.split("#");
    const menuItems = [
      {
        text: "Recibidas",
        url: "/cotizaciones",
        icon: "icon-home",
        id: "1",
      },
      {
        text: "Enviadas",
        url: "/cotizaciones#por-revisar",
        icon: "icon-database",
        id: "2",
      },
      {
        text: "Aprobadas",
        url: "/cotizaciones#total",
        icon: "icon-bag2",
        id: "6",
      },
      {
        text: "Aprobada Parcial",
        url: "/cotizaciones#parcial",
        icon: "icon-ticket",
        id: "5",
      },
    ];

    const columns = [
      {
        title: "ACCIONES",
        key: "id",
        dataIndex: "id",
        render: (id) => (
          <React.Fragment>
            <Link to={`cotizacion/${id}`} className="ps-btn">
              Ver
            </Link>
          </React.Fragment>
        ),
      },
      {
        title: "No Cotizacion",
        dataIndex: "id",
        key: "id",
        width: "20%",
        render: (id) => `No. ${id}`,
        sorter: (a, b) => a.id - b.id,
        showSorterTooltip: false,
      },
      {
        title: "Cliente",
        dataIndex: "cliente",
        key: "",
      },

      {
        title: "Fecha Limite",
        dataIndex: "fecha_limite",
        key: "fecha_limite",
        sorter: (a, b) =>
          moment(a.fecha_limite).unix() - moment(b.fecha_limite).unix(),
        showSorterTooltip: false,
      },
      {
        title: "Estado",
        dataIndex: "estado",
        key: "id",
        render: (estado) => (
          <span>
            <Tag color={estados[estado].color}>
              {estados[estado].nombre.toUpperCase()}
            </Tag>
          </span>
        ),
      },
    ];

    const estados = [
      { id: 0, nombre: "-", color: "default" },
      { id: 1, nombre: "RECIBIDA", color: "orange" },
      { id: 2, nombre: "ENVIADA", color: "processing" },
      { id: 3, nombre: "EXPIRADA", color: "error" },
      { id: 4, nombre: "FINALIZADA", color: "success" },
      { id: 5, nombre: "APROBADA-PARCIAL", color: "yellow" },
      { id: 6, nombre: "APROBADA-TOTAL", color: "processing" },
      { id: 7, nombre: "PAGO PENDIENTE", color: "orange" },
      { id: 8, nombre: "FINALIZADA-NO PAGO", color: "error" },
      { id: 9, nombre: "PAGADA", color: "blue" },
    ];

    return (
      <div>
        <DefaultHeader title="Cotizacion" search_bar={false} />
        <div className="container-fluid">
          <div className="row ">
            <div className="col-12">
              <h2>Mis Cotizaciones</h2>
            </div>
          </div>
          <hr></hr>
          <div className="row justify-content-end">
            <div className="col-12 col-sm-4">
              <div className="ps-form--search-simple " style={{marginRight:'30px', padding:'0'}}>
                <input
                  placeholder="Buscar..."
                  className="form-control"
                  value={this.state.value}
                  onChange={(e) => {
                    const currValue = e.target.value;
                    this.setState({ value: currValue });
                    const filteredData = coti.filter((entry) =>
                      entry.cliente
                        .toLowerCase()
                        .includes(currValue.toLowerCase())
                    );
                    this.setState({ dataSource: filteredData });
                  }}
                ></input>
                <button>
                  <i className="icon icon-magnifier"></i>
                </button>
              </div>
            </div>
          </div>
          <div className="row table-responsive">
            <div className="col-12">
              <ul className="menu-horizontal">
                {menuItems.map((item, index) => (
                  <li
                    key={index}
                    className={
                      this.state.seleccionado === item.url ? "active" : ""
                    }
                  >
                    <Link
                      to={item.url}
                      onClick={this.handleSetActive.bind(this, item)}
                    >
                      {item.text}
                      {this.state.seleccionado === item.url
                        ? `(${this.state.cotizaciones.length})`
                        : null}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            {/*
            <div className="col-12">
              <ul className="ul--tipo">
              {menuItems.map((item, index) => (
                  <li
                    key={index}
                    className={
                      this.state.seleccionado === item.url ? "active" : ""
                    }
                  >
                    <Link
                      to={item.url}
                      onClick={this.handleSetActive.bind(this, item)}
                    >
                      {item.text}
                      {this.state.seleccionado === item.url
                        ? `(${this.state.cotizaciones.length})`
                        : null}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            */}
            <div className="col-12">
              <Table
                className="ps-table--market"
                columns={columns}
                dataSource={this.state.dataSource}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Cotizaciones;
