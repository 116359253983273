import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Table, Modal } from 'antd'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import { actions } from '../../../../redux/modules/ubicaciones/ubicaciones'
import UbicacionForm from './UbicacionForm'
import UbicacionUpdateForm from './UbicacionUpdateForm'
import Swal from 'sweetalert2'
import { api } from "api";
import { NotificationManager } from "react-notifications";

import DetalleUbicacion from './DetalleUbicacion'

import Geocode from 'react-geocode'
import { convertLegacyProps } from 'antd/lib/button/button'

const TableUbicaciones = (props) => {
  const [dataSource, setDataSource] = useState(props.ubicaciones.ubicaciones)
  const [value, setValue] = useState('')
  const [isModalVisible, setModal] = useState(false)
  const [modalDetalle, setModalDetalle] = useState(false)
  const [modalEditar, setModalEditar] = useState(false)
  const [verUbicacion, setVerUbicacion] = useState(false)
  const [Ok, setOk] = useState(false)
  const [ubicacion, setInfo] = useState([])
  const [Cancel, setCancel] = useState(false)
  const [geoUbicacion, setUbicacion] = useState()

  Geocode.setApiKey('AIzaSyCc7uFE7m8XJ17HcYQrXPF2SiTXysfDmss')
  Geocode.setLanguage('en')
  Geocode.setRegion('es')
  Geocode.setLocationType('ROOFTOP')


  const showModal = () => {
    setModal(true)
  }

  const showModalDetalle = () => {
    setModalDetalle(true)
  }

  const showDetalle = () => {
    setVerUbicacion(true)
  }

  const showModalEditar = () => {
    setModalEditar(true)
  }

  const hiddenDetalle = () => {
    setVerUbicacion(false)
  }

  const handleOk = () => {
    setInfo([])
    setOk(false)
    setModal(false)
    setModalDetalle(false)
    setModalEditar(false)
    setVerUbicacion(false)
  }

  const handleCancel = () => {
    setInfo(null)
    setCancel(false)
    setModal(false)
    setModalDetalle(false)
    setModalEditar(false)
    setVerUbicacion(false)
  }

  const handleChange = (e, record) => {
    const data = { id: record.id, action: e.target.checked }
    props.estadoUbicacion(data)
    props.getDirecciones({ type: 'SEND_UBICACION' })
    setDataSource(props.ubicaciones.ubicaciones)
  }

  const handleSend = (data) => {
    Geocode.fromAddress(`${data.direccion}, ${data.comuna_texto}`).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location
        data.latitud = lat
        data.longitud = lng
        props.agregarUbicacion(data)
      },
      (error) => {
        console.error(error)
        data.latitud = []
        data.longitud = []
      }
    )
    setDataSource(props.ubicaciones.ubicaciones)
    handleOk()
  }

const deleteCategoria = (id) => {
    Swal.fire({
        title: '¿Eliminar?',
        text: '¡No podrá revertir esta acción!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: '¡Sí, eliminar!',
        cancelButtonText: 'No, cancelar',
        reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        api.post(`ubicaciones/${id}/eliminar_ubicaciones`,id)
        .then(() => {
            const changedTodos = dataSource.filter( todo => todo.id !== id );
            setDataSource(changedTodos)
            NotificationManager.info(
                "Satisfactorio",
                "Ubicacion eliminada satisfactoriamente."
            );
        })
        .catch((err) => {
            let message = "Hubo un error al eliminar la ubicacion";
            let description = "Intenta mas tarde";
            NotificationManager.error(message, description);
        });
    }
    });
};

  const handleUpdate = (data) => {
    Geocode.fromAddress(`${data.direccion}, ${data.comuna_texto}`).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location
        data.latitud = lat
        data.longitud = lng
        props.actualizar(data)
      },
      (error) => {
        console.error(error)
        data.latitud = []
        data.longitud = []
      }
    )
    setDataSource(props.ubicaciones.ubicaciones)
    handleOk()
  }

  useEffect(() => {
    setDataSource(props.ubicaciones.ubicaciones)
    is_valid = true
  }, [props.ubicaciones.ubicaciones])

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      key: 'id',
      sorter: (a, b) => a.nombre.localeCompare(b.nombre),
      showSorterTooltip: false
    },
    {
      title: 'Contacto',
      dataIndex: 'contacto',
      key: 'contacto',
      sorter: (a, b) => a.contacto.localeCompare(b.contacto),
      showSorterTooltip: false
    },
    {
      title: 'Telefono',
      dataIndex: 'telefono',
      key: 'id'
    },
    {
      title: 'Direccion',
      key: 'direccion',
      sorter: (a, b) => a.direccion.localeCompare(b.direccion),
      showSorterTooltip: false,
      render: (record) => (
        <React.Fragment>
          {record.direccion} , {record.comuna_nombre}
        </React.Fragment>
      )
    },

    {
      title: 'Estado',
      dataIndex: 'activo',
      key: 'id',
      render: (activo, record) => (
        <FormControlLabel
          control={
            <Switch
              checked={activo}
              onClick={e => {
                handleChange(e, record)
                props.getUbicaciones({ type: 'SET_BANCOS' })
              }}
              name='checkedB'
              color='primary'
            />
            }
        />
      )
    },
    {
      title: '-',
      key: 'id',
      render: (record) => (
          <div className="wrap justify-content-evenly">
            <div>
              <button
                className='ps-btn ps-btn--opt' onClick={e => {
                  setInfo(record),
                  showDetalle(true)
                }}
                >
                <i className='icon-eye' />
              </button>
            </div>
            <div>
              <button
                className='ps-btn ps-btn--opt' onClick={e => {
                  setInfo(record),
                  showModalEditar(true)
                }}
              >
                <i className='icon-pencil'>...</i>
              </button>
            </div>
            <div>
              <button
                className='ps-btn ps-btn--opt' onClick={e => {
                  deleteCategoria(record.id)
                }}
              >
                <i className='icon-trash2'></i>
              </button>
            </div>
          </div>
      )
    }
  ]

  var is_valid = false
  { props.ubicaciones.ubicaciones.length > 0
    ? is_valid = true
    : false
  }

  return (
    <div>
      {verUbicacion
        ? <DetalleUbicacion ubicacion={ubicacion} setUbicacion={setUbicacion} regresar={hiddenDetalle} editar={showModalEditar} />
        : <div className='form-group'>
          <section className='pl-0 pr-0'>
            <div className='container' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div>
                <button className='ps-btn' onClick={showModal}>Agregar +</button>
              </div>
              {is_valid
                ?
                  <div className='ps-form--search-simple'>
                    <input
                      placeholder='Buscar...'
                      className='form-control'
                      value={value}
                      onChange={e => {
                        const currValue = e.target.value
                        setValue(currValue)
                        const filteredData = props.ubicaciones.ubicaciones.filter(entry =>
                          entry.direccion.toLowerCase().includes(currValue.toLowerCase())
                        )
                        setDataSource(filteredData)
                      }}
                    />
                    <button><i className='icon icon-magnifier' /></button>
                  </div>
                : null}


            </div>
          </section>
          <hr />
          {is_valid === true
            ? <Table rowKey="id" dataSource={dataSource} columns={columns} className='table ps-table--market' />
            : <div>
              <hr />
              <p>Aun no tienes direcciones agregadas...</p>
            </div>}
        </div>}

      <Modal
        title=''
        width={800}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        footer={[
          <button type='reset' className='ps-btn ps-btn--gray' onClick={handleCancel}>Cerrar</button>
        ]}
      >
        <div className='container'>
          <UbicacionForm handleSend={handleSend} title='Agregar dirección' />
        </div>
      </Modal>

      {/*
    <Modal
        title=""
        width={800}
        visible={modalDetalle}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
            <button type="reset" className="ps-btn ps-btn--gray" onClick={handleCancel}>Cerrar</button>
            ]}
        >
        <div className="container">
        <DetalleUbicacion ubicacion={ubicacion} setUbicacion={setUbicacion}/>
        </div>
    </Modal>

        */}

      <Modal
        title=''
        width={800}
        visible={modalEditar}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        footer={[
          <button type='reset' className='ps-btn ps-btn--gray' onClick={handleCancel}>Cerrar</button>
        ]}
      >
        <div className='container'>
          <UbicacionUpdateForm
            handleUpdate={handleUpdate}
            ubicacion={ubicacion}
            title='Actualizar dirección'
          />
        </div>
      </Modal>

    </div>
  )
}

const ms2p = (state) => {
  return state
}

const md2p = { ...actions }

export default connect(ms2p, md2p)(TableUbicaciones)
