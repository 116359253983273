import React from "react";
import { Route, Switch } from "react-router-dom";
import { NotificationContainer } from "react-notifications";
import { Login, Profile } from "./common/components/LoginRegister";
import Home from "./common/components/Home/Home";
import ProtectedRoute from "./ProtectedRoute";
import Examples from "./common/components/Examples/Basic";
import NotFound from "./common/components/layout/NotFound/NotFound";
import Migracion from "./common/components/Migracion/Migracion";
import Productos from "./common/components/Productos/Productos";
import Cotizaciones from "./common/components/Cotizacion/Cotizacion";
import CotizacionDetalle from "./common/components/Cotizacion/CotizacionDetalle";
import Billetera from "./common/components/Billetera/Billetera";
import Ventas from "./common/components/Ventas/Ventas";
import Notificaciones from "./common/components/Notificaciones/Notificaciones";
import DetalleVenta from "./common/components/Ventas/DetalleVenta";
import Perfil from "./common/components/Perfil/Perfil";
import "../assets/fonts/fonts.css";
import "../assets/public/css/bootstrap.min.css";
import "../assets/public/css/slick.min.css";
import "../js/common/components/Utils/ImageField/style.css";
import ExampleTabs from "./common/components/Examples/Tabs/Tabs";
import "../style/scss/style.scss";
import "../assets/public/fonts/Linearicons/Font/demo-files/demo.css";
import "../assets/public/fonts/font-awesome/css/font-awesome.css";
import "./Layout.scss";
import CuentaBanco from "./common/components/Perfil/CuentaBanco";
import Tienda from "./common/components/Perfil/Tienda/Tienda";
import Facturacion from "./common/components/Perfil/Facturacion/Facturacion";
import Ubicacion from "./common/components/Perfil/Ubicaciones/Ubicacion";
import DetalleUbicacion from "./common/components/Perfil/Ubicaciones/DetalleUbicacion";
import User from "./common/components/Perfil/User/User";
import Asociado from "./common/components/Perfil/Asociados/Asociado";
import CrearAsociado from "./common/components/Perfil/Asociados/CrearAsociado";
import Activacion from "./common/components/Perfil/User/Activacion";
import Sucess from "./common/components/Perfil/User/ResultadoActivacion/Sucess";
import Failed from "./common/components/Perfil/User/ResultadoActivacion/Failed";
import MenuProductos from "./common/components/Productos/MenuProductos";
import ProductoAP from "./common/components/Importacion/ProductoAP";
import Recovery from "./common/components/LoginRegister/PasswordRecovery/Recuperacion";
import PasswordRecoveryConfirm from "./common/components/LoginRegister/PasswordRecovery/PasswordRecoveryConfirm";
import FormChange from "./common/components/LoginRegister/PasswordRecovery/FormChangePassword/FormChange";
import ProductoLocal from "./common/components/Productos/NuevoProducto/ProductoLocal";
import ListRating from "./common/components/Rating/ListRating";
import ListDevoluciones from "./common/components/Devolucion/ListDevoluciones";
import DetalleProducto from "./common/components/Productos/DetalleProducto";

module.exports = (
  <div>
    <div className="container__content">
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route
          exact
          path="/activacion-de-invitacion/asociados/:id"
          component={Activacion}
        />
        <Route exact path="/cuenta-activacion/completado" component={Sucess} />
        <Route exact path="/cuenta-activacion/error" component={Failed} />
        <Route
          exact
          path="/recuperacion/confirmacion"
          component={PasswordRecoveryConfirm}
        />
        <Route exact path="/recovery/r/:id" component={FormChange} />
        <Route exact path="/recuperacion" component={Recovery} />
        <ProtectedRoute exact path="/" component={Home} />
        <ProtectedRoute exact path="/billetera" component={Billetera} />
        <ProtectedRoute
          exact
          path="/notificaciones"
          component={Notificaciones}
        />
        <ProtectedRoute exact path="/page2" component={Examples} />
        <ProtectedRoute exact path="/user-profile" component={Profile} />
        <ProtectedRoute
          exact
          path="/cotizacion/:id"
          component={CotizacionDetalle}
        />
        <ProtectedRoute exact path="/perfil" component={Perfil} />
        <ProtectedRoute
          exact
          path="/cuentas-bancarias"
          component={CuentaBanco}
        />
        <ProtectedRoute
          exact
          path="/datos-facturacion"
          component={Facturacion}
        />
        <ProtectedRoute exact path="/tienda" component={Tienda} />
        <ProtectedRoute exact path="/catalogo" component={Productos} />
        <ProtectedRoute
          exact
          path="/catalogo/producto/:id"
          component={DetalleProducto}
        />
        <ProtectedRoute exact path="/ubicaciones" component={Ubicacion} />
        <ProtectedRoute exact path="/datos-acceso" component={User} />
        <ProtectedRoute exact path="/asociados" component={Asociado} />
        <ProtectedRoute
          exact
          path="/invitar-asociado"
          component={CrearAsociado}
        />
        <ProtectedRoute
          exact
          path="/ver-ubicacion"
          component={DetalleUbicacion}
        />
        <ProtectedRoute exact path="/en-proceso" component={Ventas} />
        <ProtectedRoute exact path="/venta/:id" component={DetalleVenta} />
        <ProtectedRoute exact path="/cotizaciones" component={Cotizaciones} />
        <ProtectedRoute exact path="/migrar-productos" component={Migracion} />
        <ProtectedRoute exact path="/tabs" component={ExampleTabs} />
        <ProtectedRoute
          exact
          path="/nuevo-producto"
          component={ProductoLocal}
        />
        <ProtectedRoute
          exact
          path="/importar-productos"
          component={ProductoAP}
        />
        <ProtectedRoute
          exact
          path="/agregar-producto"
          component={MenuProductos}
        />
        <ProtectedRoute exact path="/rating/list" component={ListRating} />
        <ProtectedRoute
          exact
          paht="/devoluciones"
          component={ListDevoluciones}
        />
        <Route component={NotFound} />
      </Switch>
    </div>
    <NotificationContainer />
  </div>
);
