import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import DefaultHeader from '../layout/Header/DefaultHeader'
import LoadMask from "../Utils/LoadMask/LoadMask";
import TableCuentas from './Tables/TableCuentas'
import {getBancos} from '../../../redux/modules/bancos/bancos'
import { connect } from "react-redux";

class CuentasBancarias extends Component{
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
          };
    }

    componentDidMount() {
        this.handleLoader()
        this.props.getBancos()
    }


    handleLoader = () => {
        setTimeout(()=> this.setState({loader:false}),1000);
    }

render(){
    return (
        <div className="container">
            <DefaultHeader title="Cuenta bancarias" visible={true}/>
            {this.state.loader?
                <LoadMask loading={this.state.loader} dark />
                :
                <div>
                    <div className="container pl-5">
                        <Link to={`/perfil`} style={{backgroundColor: "#2e3d50", color: "white"}} className="ps-btn">
                            Regresar
                        </Link>
                    </div>
                    <div className="header__center">
                        <div className="row">
                            <hr></hr>
                        </div>
                    </div>
                    <div className="container">
                        <TableCuentas/>
                    </div>
                </div>
            }
        </div>
    );
}
}

const ms2p = (state) => {
    return state;
  };

const md2p = {getBancos}


export default connect(ms2p ,md2p)(CuentasBancarias);
