import React, { useEffect, useState } from "react";
import { Currency } from "react-intl-number-format/dist/index.js";
import { Table } from "antd";
import moment from "moment";
import { CSVLink } from "react-csv";
import "../../../../style/scss/custom/TableActividad.module.scss";
import LoadMask from "../Utils/LoadMask/LoadMask";
import { api } from '../../../utility/api'

const TableActividad = (info, styled) => {
  const [value, setValue] = useState("");
  const [desde, setDate1] = useState(
    moment().clone().startOf("month").format("YYYY-MM-DD")
  );
  const [hasta, setDate2] = useState(moment().format());
  const [dataSource, getDataSource] = useState(info.info);

  const ESTADOS = ["-", "ENVIADA", "ACEPTADA", "DEPOSITADA", "FINALIZADA"];

  const filter_status = [];

  ESTADOS.map((status, index) =>
    filter_status.push({
      text: status,
      value: index * 10,
    })
  );

  const filter_estados = [
    { id: 0, nombre: "-", color: "default" },
    { id: 1, nombre: "ENVIADA", color: "orange" },
    { id: 4, nombre: "FINALIZADA", color: "success" },
  ];

  const enbacezado_csv = [
    { label: "No transacción", key: "id" },
    { label: "Fecha", key: "fecha_creacion" },
    { label: "Estado", key: "status" },
    { label: "Monto solicitado", key: "monto_solicitado" },
    { label: "Monto a Depositar", key: "monto_transferido" },
  ];

  const [loading, setLoading] = useState(true)
  const setData = (comisionData) => {
    let resetData = [];
    dataSource.map((movimiento, index) => {
        resetData.push({
            ...movimiento,
            comision_total: comisionData[index].comision_total,
        });
        }
    )
    console.log(resetData)
    getDataSource(resetData);
  }

  function calculateComisiones(callback) {
    let data = {id_cotizacion: []}
    dataSource.map((movimiento) =>{
        data.id_cotizacion.push(movimiento.venta)
    })
    setLoading(true)
    api.post(`/categorias-feeds-commissions/calcule_comisions`, data)
        .then((res) => {
            callback(res)
        })
        .catch((err) => {
            console.log(err);
        })
        .finally(() => {
            setLoading(false)
        });
  };

  const handleSetFilter = () => {
    const inicio = new Date(desde).getTime();
    const final = new Date(hasta).getTime();

    const resultData = info.info.filter((obj) => {
      const time = new Date(obj.fecha_creacion).getTime();
      return inicio < time && time < final;
    });
    getDataSource(resultData);
  };

  const columns = [
    {
      title: "No.",
      key: "id",
      dataIndex: "id",
      sorter: (a, b) => a.id - b.id,
      showSorterTooltip: false,
    },
    {
      title: "Fecha Solicitud",
      dataIndex: "fecha_creacion",
      key: "fecha_creacion",
      sorter: (a, b) =>
        moment(a.fecha_creacion).unix() - moment(b.fecha_creacion).unix(),
      showSorterTooltip: false,
      render: (fecha_creacion) => (
        <p>
          {moment(fecha_creacion).format("L")} - (
          {moment(fecha_creacion).fromNow()})
        </p>
      ),
    },
    {
      title: "Monto solicitado",
      dataIndex: "monto_solicitado",
      key: "monto_solicitado",
      render: (monto_solicitado) => (
        <p>
          <Currency locale="es-CL" currency="CLP">
            {monto_solicitado}
          </Currency>
        </p>
      ),
    },
    {
      title: "Comisiones",
      key: "id",
      render: (record) => (
        console.log(record),
        <p>
          <Currency locale="es-CL" currency="CLP">
            {record.comision_total}
          </Currency>
        </p>
      ),
    },
    {
      title: "A depositar",
      dataIndex: "monto_transferido",
      key: "monto_transferido",
      render: (monto_transferido, record) => (
        <p>
          {monto_transferido > 0 ? (
            <Currency locale="es-CL" currency="CLP">
              {monto_transferido}
            </Currency>
          ) : (
            <Currency locale="es-CL" currency="CLP">
              {record.monto_solicitado - record.comision_total}
            </Currency>
          )}
        </p>
      ),
    },
    {
      title: "Estado",
      dataIndex: "status",
      key: "status",
      filters: filter_status,
      onFilter: (value, record) => record.status === value,
      render: (status) => <p>{ESTADOS[status / 10]}</p>,
    },
  ];

  useEffect(() => {
    calculateComisiones(setData);
  }, []);
  return (
    <LoadMask loading={loading} blur={true}>
    <div>
      <div className="form-group">
        {styled ? (
          <React.Fragment>
            <section className="ps-dashboard">
              <div className="row">
                <div className="col-12 col-md-3">
                  <label
                    htmlFor="estados"
                    className="col-12 col-md-4 col-form-label"
                  >
                    Filtrar:
                  </label>
                  <select
                    className="form-control"
                    value={value}
                    onChange={(e) => {
                      const currValue = e.target.value;
                      setValue(currValue);
                      const filteredData = info.info.filter((entry) =>
                        String(entry.status).includes(String(currValue))
                      );
                      getDataSource(filteredData);
                    }}
                  >
                    {filter_estados.map((estado, index) => (
                      <option key={index} value={estado.id}>
                        {estado.nombre}
                      </option>
                    ))}
                  </select>
                  <hr />
                </div>
                <div className="col-12 col-md-3">
                  <label htmlFor="estados">Desde:</label>
                  <input
                    type="date"
                    className="form-control"
                    name="fecha_i"
                    value={desde}
                    onChange={(e) => {
                      const currValue = e.target.value;
                      setDate1(currValue);
                    }}
                  />
                  <hr />
                </div>
                <div className="col-12 col-md-3">
                  <label htmlFor="estados">Hasta:</label>
                  <input
                    type="date"
                    className="form-control"
                    name="fecha_f"
                    value={moment(hasta).format("YYYY-MM-DD")}
                    onChange={(e) => {
                      const currValue = e.target.value;
                      setDate2(currValue);
                    }}
                  />
                  <hr />
                </div>
                <div className="col-12 col-md-3 button--display">
                  <button
                    style={{ marginRight: "5px" }}
                    className="ps-btn xs"
                    onClick={handleSetFilter}
                  >
                    Filtrar
                  </button>
                  <CSVLink
                    data={dataSource}
                    headers={enbacezado_csv}
                    filename={`historial_solicitudes_${moment().format(
                      "L"
                    )}.csv`}
                    className="ps-btn xs"
                    style={{ backgroundColor: "#28a745" }}
                  >
                    Exportar
                  </CSVLink>
                </div>
              </div>
            </section>

            <br />
            <div className="table-responsive">
              <Table
                dataSource={dataSource}
                columns={columns}
                rowKey="id"
                className="table ps-table--market"
              />
            </div>
          </React.Fragment>
        ) : (
          <div className="table-responsive">
            <Table dataSource={dataSource} columns={columns} rowKey="id" />
          </div>
        )}
      </div>
      <div className="form-group" />
    </div>
    </LoadMask>
  );
};

export default TableActividad;
