import { handleActions } from 'redux-actions'
import Swal from 'sweetalert2'
import { push } from 'react-router-redux'
import { initialize as initializeForm } from 'redux-form'
import { NotificationManager } from 'react-notifications'
import { useHistory } from 'react-router-dom'
import { api } from 'api'
import { notification } from 'antd'

const actionTypes = {
  SEND_BANCO: 'SEND_BANCO',
  SET_BANCOS: ' SET_BANCOS'
}

// ------------------------------------
// Pure Actions
// ------------------------------------

const modalSuccessAny = (type, message, description) => {
  notification[type]({
    message: message,
    description: description,
    duration: 1
  })
}

const SwalAlert = (title, text, type) => {
  Swal.fire({
    width: '400px',
    heightAuto: true,
    title: title,
    text: text,
    type: type
  }).finally(() => {
    window.location.reload();
  })
}

export function sendSolicitud (payload) {
  return {
    type: actionTypes.SEND_BANCO,
    payload
  }
}

export function setBancos (cuentas) {
  return {
    type: actionTypes.SET_BANCOS,
    cuentas
  }
};

export const getBancos = () => (dispatch) => {
  api.get('bancos/mis_cuentas').then(cuentas => {
    dispatch(setBancos(cuentas))
  })
    .catch(() => {
    }).finally(() => {})
}

// ------------------------------------
// Actions
// ------------------------------------

export const vincularCuenta = (data) => (dispatch) => {
  api.post('bancos', data).then((response) => {
    dispatch(sendSolicitud())
    dispatch(push('/cuentas-bancarias'))
    dispatch(getBancos())
    const title = 'Completado'
    const text = 'Se agrego la cuenta bancaria para que se realizen tus pagos'
    const type = 'success'
    SwalAlert(title, text, type)
  }).catch((error) => {
    const title = 'Error'
    const text = 'Parece que hubo un error al enviar la solicitud, intenta más tarde'
    const type = 'error'
    SwalAlert(title, error, type)
  }).finally(() => {
  })
}

export const actualizarEstado = (data) => (dispatch) => {
  api.post('bancos/actualizar_estado', data).then((response) => {
    dispatch(sendSolicitud())
    dispatch(getBancos())
    modalSuccessAny('success', '', 'Completado')
  }).catch((error) => {
    modalSuccessAny('error', 'intente más tarde...', 'Hubo un error')
  }).finally(() => {
  })
}

export const actualizarEstadoCampos = (data) => (dispatch) => {
    api.post('bancos/actualizar_estado_campos', data).then((response) => {
      dispatch(sendSolicitud())
      dispatch(getBancos())
      modalSuccessAny('success', '', 'Completado')
    }).catch((error) => {
      console.log(error)
      modalSuccessAny('error', 'intente más tarde...', 'Hubo un error')
    }).finally(() => {
    })
  }

export const deleteCuenta = (data) => (dispatch) => {
    api.post('bancos/elimininar_cuenta', data).then((response) => {
      dispatch(sendSolicitud())
      dispatch(getBancos())
      modalSuccessAny('success', '', 'Cuenta eliminada')
    }).catch((error) => {
      console.log(error)
      modalSuccessAny('error', 'intente más tarde...', 'Hubo un error')
    }).finally(() => {
    })
  }

export const actions = {
  getBancos,
  vincularCuenta,
  actualizarEstado,
  actualizarEstadoCampos,
  deleteCuenta
}

export const reducers = {
  [actionTypes.SET_BANCOS]: (state, { cuentas }) => {
    return {
      ...state,
      cuentas
    }
  },
  [actionTypes.SEND_BANCO]: (state) => {
    return {
      ...state
    }
  }

}

export const initialState = {
  loader: false,
  cuentas: {}

}

export default handleActions(reducers, initialState)
