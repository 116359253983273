import React, { Component } from 'react';
import Modal from 'react-modal';
import '../../../../style/modal.css';
import loader_migrate from '../../../../../src/assets/img/loader_migrate_yellow.png';
import loader_success from '../../../../../src/assets/img/loader_add_yellow.png';
import { Link } from 'react-router-dom';

class Migracion extends Component {

    state = {
        migrateModal: false,
        modalConfirmation: false,
    };

    openMigrateModal = () => {
        this.setState({migrateModal: true});
        this.setState({modalConfirmation: false})

    };

    closeMigrateModal = () => {
        this.setState({migrateModal: false});
    };

    openConfirmationModal = () => {
        this.setState({modalConfirmation: true}),
        this.setState({migrateModal: false});

    }
    closeConfirmationModal = () => {
        this.setState({modalConfirmation: false})
    }



    render() {
        const {loader} = this.props;

        const products = [
            {
                'name': " Producto 1",
                'cat': "Categoria 1",
                'fam': "Familia 1",
                'prov': "Proveedor 1",
            },
            {
                'name': " Producto 1",
                'cat': "Categoria 1",
                'fam': "Familia 1",
                'prov': "Proveedor 1",
            },
            {
                'name': " Producto 1",
                'cat': "Categoria 1",
                'fam': "Familia 1",
                'prov': "Proveedor 1",
            },
            {
                'name': " Producto 1",
                'cat': "Categoria 1",
                'fam': "Familia 1",
                'prov': "Proveedor 1",
            },
            {
                'name': " Producto 1",
                'cat': "Categoria 1",
                'fam': "Familia 1",
                'prov': "Proveedor 1",
            },
            {
                'name': " Producto 1",
                'cat': "Categoria 1",
                'fam': "Familia 1",
                'prov': "Proveedor 1",
            },

        ]
          
        return (
            <React.Fragment>

                <div className="form-group submit">
                    <h3>
                    Filtros
                    <button className="ps-btn ps-btn">Seleccionar Todos</button>
                    </h3>
                </div>
                    <div className="col-sm">
                        <div className="card">
                            <div className="card-body">
                            <h5 className="card-title">Migracion de Productos</h5>
                            <h6 className="card-subtitle mb-2 text-muted">Card subtitle</h6>
                            <p className="card-text">Por favor seleccione los filtros para migrar los productos.</p>
                            </div>
                        </div>
                    </div>
                <hr></hr>
                <div className="container">
                <div className="row">
                    <div className="col-sm">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Categorías</h5>
                                {products.map((product) => (
                                <div className='form-check'> 
                                    <input type='checkbox' className='form-check-input' id="category-check"/>
                                    <label for="category-check" className='col-check-label'> {product.cat}</label>
                                </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="col-sm">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Familias</h5>
                                {products.map((product) => (
                                <div className='form-check'> 
                                <input type='checkbox' className='form-check-input' id="category-check"/>
                                <label for="category-check" className='col-check-label'> {product.fam}</label>
                            </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="col-sm">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Proveedor</h5>
                                {products.map((product) => (
                                <div className='form-check'> 
                                <input type='checkbox' className='form-check-input' id="category-check"/>
                                <label for="category-check" className='col-check-label'> {product.prov}</label>
                            </div>
                            ))}
                            </div>
                        </div>
                    </div>
                </div>
                </div>

            <br></br>
            <div className="table-responsive">
                <table className="table ps-table stripped ">
                    <thead className="thead-dark">
                        <tr>
                            <th>SELECCION</th>
                            <th>PRODUCTO</th>
                            <th>CATEGORIA</th>
                            <th>FAMILIA</th>
                            <th>PROVEEDOR</th>
                        </tr>
                    </thead>
                    <tbody>
                    {products.map((product) => (
                    <tr>
                        <td><input type='checkbox' defaultValue='false'/></td>
                        <td>{product.name}</td>
                        <td>{product.cat}</td>
                        <td>{product.fam}</td>
                        <td>{product.prov}</td>
                    </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="form-group submit">
                <button className="ps-btn ps-btn" onClick={this.openMigrateModal}>Realizar migracion</button>
            </div>
            
            <Modal isOpen={this.state.migrateModal} onRequestClose={this.setState.closeMigrateModal}
            className="modal-wrapper">
            <div className="ps-modal__container">
            <h3 className="modal-body">Migracion de archivos</h3>
            <p className="modal-body">Has seleccionado  cantidad de productos. Estas seguro que deseas migrar estos productos a tu BD</p>
            <div className="modal-body">
            <img src={loader_migrate}></img>
            </div>
            <button className="ps-btn center modal-footer" onClick={this.openConfirmationModal}><b>Confirmar migracion de  productos</b></button>
            </div>
            </Modal>
            

            <Modal isOpen={this.state.modalConfirmation} onRequestClose={this.setState.closeConfirmationModal}
            className="modal-wrapper">
            <div className="ps-modal__container">
            <h3 className="modal-body">Productos cargados exitosamente!</h3>
            <p className="modal-body">Se han cargado todos los productos con estado activo en Agropartner</p>
            <div className="modal-body">
            <img src={loader_success}></img>
            </div>
            <Link to ='/products' className="ps-btn ps-btn--fullwidth">Ver listado de productos</Link>
            </div>
            </Modal>
            
            </React.Fragment>
        );
    }
}

export default Migracion;