const comunas = [
  { value: '15101', label: 'Arica', provincia: '151' },
  { value: '15102', label: 'Camarones', provincia: '151' },
  { value: '15201', label: 'Putre', provincia: '152' },
  { value: '15202', label: 'General Lagos', provincia: '152' },
  { value: '01101', label: 'Iquique', provincia: '011' },
  { value: '01107', label: 'Alto Hospicio', provincia: '011' },
  { value: '01401', label: 'Pozo Almonte', provincia: '014' },
  { value: '01402', label: 'Camiña', provincia: '014' },
  { value: '01403', label: 'Colchane', provincia: '014' },
  { value: '01404', label: 'Huara', provincia: '014' },
  { value: '01405', label: 'Pica', provincia: '014' },
  { value: '02101', label: 'Antofagasta', provincia: '021' },
  { value: '02102', label: 'Mejillones', provincia: '021' },
  { value: '02103', label: 'Sierra Gorda', provincia: '021' },
  { value: '02104', label: 'Taltal', provincia: '021' },
  { value: '02201', label: 'Calama', provincia: '022' },
  { value: '02202', label: 'Ollagüe', provincia: '022' },
  { value: '02203', label: 'San Pedro de Atacama', provincia: '022' },
  { value: '02301', label: 'Tocopilla', provincia: '023' },
  { value: '02302', label: 'María Elena', provincia: '023' },
  { value: '03101', label: 'Copiapó', provincia: '031' },
  { value: '03102', label: 'Caldera', provincia: '031' },
  { value: '03103', label: 'Tierra Amarilla', provincia: '031' },
  { value: '03201', label: 'Chañaral', provincia: '032' },
  { value: '03202', label: 'Diego de Almagro', provincia: '032' },
  { value: '03301', label: 'Vallenar', provincia: '033' },
  { value: '03302', label: 'Alto del Carmen', provincia: '033' },
  { value: '03303', label: 'Freirina', provincia: '033' },
  { value: '03304', label: 'Huasco', provincia: '033' },
  { value: '04101', label: 'La Serena', provincia: '041' },
  { value: '04102', label: 'Coquimbo', provincia: '041' },
  { value: '04103', label: 'Andacollo', provincia: '041' },
  { value: '04104', label: 'La Higuera', provincia: '041' },
  { value: '04105', label: 'Paiguano', provincia: '041' },
  { value: '04106', label: 'Vicuña', provincia: '041' },
  { value: '04201', label: 'Illapel', provincia: '042' },
  { value: '04202', label: 'Canela', provincia: '042' },
  { value: '04203', label: 'Los Vilos', provincia: '042' },
  { value: '04204', label: 'Salamanca', provincia: '042' },
  { value: '04301', label: 'Ovalle', provincia: '043' },
  { value: '04302', label: 'Combarbalá', provincia: '043' },
  { value: '04303', label: 'Monte Patria', provincia: '043' },
  { value: '04304', label: 'Punitaqui', provincia: '043' },
  { value: '04305', label: 'Río Hurtado', provincia: '043' },
  { value: '05101', label: 'Valparaíso', provincia: '051' },
  { value: '05102', label: 'Casablanca', provincia: '051' },
  { value: '05103', label: 'Concón', provincia: '051' },
  { value: '05104', label: 'Juan Fernández', provincia: '051' },
  { value: '05105', label: 'Puchuncaví', provincia: '051' },
  { value: '05801', label: 'Quilpué', provincia: '058' },
  { value: '05107', label: 'Quintero', provincia: '051' },
  { value: '05804', label: 'Villa Alemana', provincia: '058' },
  { value: '05109', label: 'Viña del Mar', provincia: '051' },
  { value: '05201', label: 'Isla de Pascua', provincia: '052' },
  { value: '05301', label: 'Los Andes', provincia: '053' },
  { value: '05302', label: 'Calle Larga', provincia: '053' },
  { value: '05303', label: 'Rinconada', provincia: '053' },
  { value: '05304', label: 'San Esteban', provincia: '053' },
  { value: '05401', label: 'La Ligua', provincia: '054' },
  { value: '05402', label: 'Cabildo', provincia: '054' },
  { value: '05403', label: 'Papudo', provincia: '054' },
  { value: '05404', label: 'Petorca', provincia: '054' },
  { value: '05405', label: 'Zapallar', provincia: '054' },
  { value: '05501', label: 'Quillota', provincia: '055' },
  { value: '05502', label: 'Calera', provincia: '055' },
  { value: '05503', label: 'Hijuelas', provincia: '055' },
  { value: '05504', label: 'La Cruz', provincia: '055' },
  { value: '05802', label: 'Limache', provincia: '058' },
  { value: '05506', label: 'Nogales', provincia: '055' },
  { value: '05803', label: 'Olmué', provincia: '058' },
  { value: '05601', label: 'San Antonio', provincia: '056' },
  { value: '05602', label: 'Algarrobo', provincia: '056' },
  { value: '05603', label: 'Cartagena', provincia: '056' },
  { value: '05604', label: 'El Quisco', provincia: '056' },
  { value: '05605', label: 'El Tabo', provincia: '056' },
  { value: '05606', label: 'Santo Domingo', provincia: '056' },
  { value: '05701', label: 'San Felipe', provincia: '057' },
  { value: '05702', label: 'Catemu', provincia: '057' },
  { value: '05703', label: 'Llaillay', provincia: '057' },
  { value: '05704', label: 'Panquehue', provincia: '057' },
  { value: '05705', label: 'Putaendo', provincia: '057' },
  { value: '05706', label: 'Santa María', provincia: '057' },
  { value: '06101', label: 'Rancagua', provincia: '061' },
  { value: '06102', label: 'Codegua', provincia: '061' },
  { value: '06103', label: 'Coinco', provincia: '061' },
  { value: '06104', label: 'Coltauco', provincia: '061' },
  { value: '06105', label: 'Doñihue', provincia: '061' },
  { value: '06106', label: 'Graneros', provincia: '061' },
  { value: '06107', label: 'Las Cabras', provincia: '061' },
  { value: '06108', label: 'Machalí', provincia: '061' },
  { value: '06109', label: 'Malloa', provincia: '061' },
  { value: '06110', label: 'Mostazal', provincia: '061' },
  { value: '06111', label: 'Olivar', provincia: '061' },
  { value: '06112', label: 'Peumo', provincia: '061' },
  { value: '06113', label: 'Pichvalueegua', provincia: '061' },
  { value: '06114', label: 'Quinta de Tilcoco', provincia: '061' },
  { value: '06115', label: 'Rengo', provincia: '061' },
  { value: '06116', label: 'Requínoa', provincia: '061' },
  { value: '06117', label: 'San Vicente', provincia: '061' },
  { value: '06201', label: 'Pichilemu', provincia: '062' },
  { value: '06202', label: 'La Estrella', provincia: '062' },
  { value: '06203', label: 'Litueche', provincia: '062' },
  { value: '06204', label: 'Marchihue', provincia: '062' },
  { value: '06205', label: 'Navvaluead', provincia: '062' },
  { value: '06206', label: 'Paredones', provincia: '062' },
  { value: '06301', label: 'San Fernando', provincia: '063' },
  { value: '06302', label: 'Chépica', provincia: '063' },
  { value: '06303', label: 'Chimbarongo', provincia: '063' },
  { value: '06304', label: 'Lolol', provincia: '063' },
  { value: '06305', label: 'Nancagua', provincia: '063' },
  { value: '06306', label: 'Palmilla', provincia: '063' },
  { value: '06307', label: 'Peralillo', provincia: '063' },
  { value: '06308', label: 'Placilla', provincia: '063' },
  { value: '06309', label: 'Pumanque', provincia: '063' },
  { value: '06310', label: 'Santa Cruz', provincia: '063' },
  { value: '07101', label: 'Talca', provincia: '071' },
  { value: '07102', label: 'Constitución', provincia: '071' },
  { value: '07103', label: 'Curepto', provincia: '071' },
  { value: '07104', label: 'Empedrado', provincia: '071' },
  { value: '07105', label: 'Maule', provincia: '071' },
  { value: '07106', label: 'Pelarco', provincia: '071' },
  { value: '07107', label: 'Pencahue', provincia: '071' },
  { value: '07108', label: 'Río Claro', provincia: '071' },
  { value: '07109', label: 'San Clemente', provincia: '071' },
  { value: '07110', label: 'San Rafael', provincia: '071' },
  { value: '07201', label: 'Cauquenes', provincia: '072' },
  { value: '07202', label: 'Chanco', provincia: '072' },
  { value: '07203', label: 'Pelluhue', provincia: '072' },
  { value: '07301', label: 'Curicó', provincia: '073' },
  { value: '07302', label: 'Hualañé', provincia: '073' },
  { value: '07303', label: 'Licantén', provincia: '073' },
  { value: '07304', label: 'Molina', provincia: '073' },
  { value: '07305', label: 'Rauco', provincia: '073' },
  { value: '07306', label: 'Romeral', provincia: '073' },
  { value: '07307', label: 'Sagrada Familia', provincia: '073' },
  { value: '07308', label: 'Teno', provincia: '073' },
  { value: '07309', label: 'Vichuquén', provincia: '073' },
  { value: '07401', label: 'Linares', provincia: '074' },
  { value: '07402', label: 'Colbún', provincia: '074' },
  { value: '07403', label: 'Longaví', provincia: '074' },
  { value: '07404', label: 'Parral', provincia: '074' },
  { value: '07405', label: 'Retiro', provincia: '074' },
  { value: '07406', label: 'San Javier', provincia: '074' },
  { value: '07407', label: 'Villa Alegre', provincia: '074' },
  { value: '07408', label: 'Yerbas Buenas', provincia: '074' },
  { value: '08101', label: 'Concepción', provincia: '081' },
  { value: '08102', label: 'Coronel', provincia: '081' },
  { value: '08103', label: 'Chiguayante', provincia: '081' },
  { value: '08104', label: 'Florvaluea', provincia: '081' },
  { value: '08105', label: 'Hualqui', provincia: '081' },
  { value: '08106', label: 'Lota', provincia: '081' },
  { value: '08107', label: 'Penco', provincia: '081' },
  { value: '08108', label: 'San Pedro de la Paz', provincia: '081' },
  { value: '08109', label: 'Santa Juana', provincia: '081' },
  { value: '08110', label: 'Talcahuano', provincia: '081' },
  { value: '08111', label: 'Tomé', provincia: '081' },
  { value: '08112', label: 'Hualpén', provincia: '081' },
  { value: '08201', label: 'Lebu', provincia: '082' },
  { value: '08202', label: 'Arauco', provincia: '082' },
  { value: '08203', label: 'Cañete', provincia: '082' },
  { value: '08204', label: 'Contulmo', provincia: '082' },
  { value: '08205', label: 'Curanilahue', provincia: '082' },
  { value: '08206', label: 'Los Álamos', provincia: '082' },
  { value: '08207', label: 'Tirúa', provincia: '082' },
  { value: '08301', label: 'Los Ángeles', provincia: '083' },
  { value: '08302', label: 'Antuco', provincia: '083' },
  { value: '08303', label: 'Cabrero', provincia: '083' },
  { value: '08304', label: 'Laja', provincia: '083' },
  { value: '08305', label: 'Mulchén', provincia: '083' },
  { value: '08306', label: 'Nacimiento', provincia: '083' },
  { value: '08307', label: 'Negrete', provincia: '083' },
  { value: '08308', label: 'Quilaco', provincia: '083' },
  { value: '08309', label: 'Quilleco', provincia: '083' },
  { value: '08310', label: 'San Rosendo', provincia: '083' },
  { value: '08311', label: 'Santa Bárbara', provincia: '083' },
  { value: '08312', label: 'Tucapel', provincia: '083' },
  { value: '08313', label: 'Yumbel', provincia: '083' },
  { value: '08314', label: 'Alto Biobío', provincia: '083' },
  { value: '08401', label: 'Chillán', provincia: '084' },
  { value: '08402', label: 'Bulnes', provincia: '084' },
  { value: '08403', label: 'Cobquecura', provincia: '084' },
  { value: '08404', label: 'Coelemu', provincia: '084' },
  { value: '08405', label: 'Coihueco', provincia: '084' },
  { value: '08406', label: 'Chillán Viejo', provincia: '084' },
  { value: '08407', label: 'El Carmen', provincia: '084' },
  { value: '08408', label: 'Ninhue', provincia: '084' },
  { value: '08409', label: 'Ñiquén', provincia: '084' },
  { value: '08410', label: 'Pemuco', provincia: '084' },
  { value: '08411', label: 'Pinto', provincia: '084' },
  { value: '08412', label: 'Portezuelo', provincia: '084' },
  { value: '08413', label: 'Quillón', provincia: '084' },
  { value: '08414', label: 'Quirihue', provincia: '084' },
  { value: '08415', label: 'Ránquil', provincia: '084' },
  { value: '08416', label: 'San Carlos', provincia: '084' },
  { value: '08417', label: 'San Fabián', provincia: '084' },
  { value: '08418', label: 'San Ignacio', provincia: '084' },
  { value: '08419', label: 'San Nicolás', provincia: '084' },
  { value: '08420', label: 'Treguaco', provincia: '084' },
  { value: '08421', label: 'Yungay', provincia: '084' },
  { value: '09101', label: 'Temuco', provincia: '091' },
  { value: '09102', label: 'Carahue', provincia: '091' },
  { value: '09103', label: 'Cunco', provincia: '091' },
  { value: '09104', label: 'Curarrehue', provincia: '091' },
  { value: '09105', label: 'Freire', provincia: '091' },
  { value: '09106', label: 'Galvarino', provincia: '091' },
  { value: '09107', label: 'Gorbea', provincia: '091' },
  { value: '09108', label: 'Lautaro', provincia: '091' },
  { value: '09109', label: 'Loncoche', provincia: '091' },
  { value: '09110', label: 'Melipeuco', provincia: '091' },
  { value: '09111', label: 'Nueva Imperial', provincia: '091' },
  { value: '09112', label: 'Padre Las Casas', provincia: '091' },
  { value: '09113', label: 'Perquenco', provincia: '091' },
  { value: '09114', label: 'Pitrufquén', provincia: '091' },
  { value: '09115', label: 'Pucón', provincia: '091' },
  { value: '09116', label: 'Saavedra', provincia: '091' },
  { value: '09117', label: 'Teodoro Schmvaluet', provincia: '091' },
  { value: '09118', label: 'Toltén', provincia: '091' },
  { value: '09119', label: 'Vilcún', provincia: '091' },
  { value: '09120', label: 'Villarrica', provincia: '091' },
  { value: '09121', label: 'Cholchol', provincia: '091' },
  { value: '09201', label: 'Angol', provincia: '092' },
  { value: '09202', label: 'Collipulli', provincia: '092' },
  { value: '09203', label: 'Curacautín', provincia: '092' },
  { value: '09204', label: 'Ercilla', provincia: '092' },
  { value: '09205', label: 'Lonquimay', provincia: '092' },
  { value: '09206', label: 'Los Sauces', provincia: '092' },
  { value: '09207', label: 'Lumaco', provincia: '092' },
  { value: '09208', label: 'Purén', provincia: '092' },
  { value: '09209', label: 'Renaico', provincia: '092' },
  { value: '09210', label: 'Traiguén', provincia: '092' },
  { value: '09211', label: 'Victoria', provincia: '092' },
  { value: '14101', label: 'Valdivia', provincia: '141' },
  { value: '14102', label: 'Corral', provincia: '141' },
  { value: '14103', label: 'Lanco', provincia: '141' },
  { value: '14104', label: 'Los Lagos', provincia: '141' },
  { value: '14105', label: 'Máfil', provincia: '141' },
  { value: '14106', label: 'Mariquina', provincia: '141' },
  { value: '14107', label: 'Paillaco', provincia: '141' },
  { value: '14108', label: 'Panguipulli', provincia: '141' },
  { value: '14201', label: 'La Unión', provincia: '142' },
  { value: '14202', label: 'Futrono', provincia: '142' },
  { value: '14203', label: 'Lago Ranco', provincia: '142' },
  { value: '14204', label: 'Río Bueno', provincia: '142' },
  { value: '10101', label: 'Puerto Montt', provincia: '101' },
  { value: '10102', label: 'Calbuco', provincia: '101' },
  { value: '10103', label: 'Cochamó', provincia: '101' },
  { value: '10104', label: 'Fresia', provincia: '101' },
  { value: '10105', label: 'Frutillar', provincia: '101' },
  { value: '10106', label: 'Los Muermos', provincia: '101' },
  { value: '10107', label: 'Llanquihue', provincia: '101' },
  { value: '10108', label: 'Maullín', provincia: '101' },
  { value: '10109', label: 'Puerto Varas', provincia: '101' },
  { value: '10201', label: 'Castro', provincia: '102' },
  { value: '10202', label: 'Ancud', provincia: '102' },
  { value: '10203', label: 'Chonchi', provincia: '102' },
  { value: '10204', label: 'Curaco de Vélez', provincia: '102' },
  { value: '10205', label: 'Dalcahue', provincia: '102' },
  { value: '10206', label: 'Puqueldón', provincia: '102' },
  { value: '10207', label: 'Queilén', provincia: '102' },
  { value: '10208', label: 'Quellón', provincia: '102' },
  { value: '10209', label: 'Quemchi', provincia: '102' },
  { value: '10210', label: 'Quinchao', provincia: '102' },
  { value: '10301', label: 'Osorno', provincia: '103' },
  { value: '10302', label: 'Puerto Octay', provincia: '103' },
  { value: '10303', label: 'Purranque', provincia: '103' },
  { value: '10304', label: 'Puyehue', provincia: '103' },
  { value: '10305', label: 'Río Negro', provincia: '103' },
  { value: '10306', label: 'San Juan de la Costa', provincia: '103' },
  { value: '10307', label: 'San Pablo', provincia: '103' },
  { value: '10401', label: 'Chaitén', provincia: '104' },
  { value: '10402', label: 'Futaleufú', provincia: '104' },
  { value: '10403', label: 'Hualaihué', provincia: '104' },
  { value: '10404', label: 'Palena', provincia: '104' },
  { value: '11101', label: 'Coyhaique', provincia: '111' },
  { value: '11102', label: 'Lago Verde', provincia: '111' },
  { value: '11201', label: 'Aysén', provincia: '112' },
  { value: '11202', label: 'Cisnes', provincia: '112' },
  { value: '11203', label: 'Guaitecas', provincia: '112' },
  { value: '11301', label: 'Cochrane', provincia: '113' },
  { value: '11302', label: 'O’Higgins', provincia: '113' },
  { value: '11303', label: 'Tortel', provincia: '113' },
  { value: '11401', label: 'Chile Chico', provincia: '114' },
  { value: '11402', label: 'Río Ibáñez', provincia: '114' },
  { value: '12101', label: 'Punta Arenas', provincia: '121' },
  { value: '12102', label: 'Laguna Blanca', provincia: '121' },
  { value: '12103', label: 'Río Verde', provincia: '121' },
  { value: '12104', label: 'San Gregorio', provincia: '121' },
  { value: '12201', label: 'Cabo de Hornos (Ex - Navarino)', provincia: '122' },
  { value: '12202', label: 'Antártica', provincia: '122' },
  { value: '12301', label: 'Porvenir', provincia: '123' },
  { value: '12302', label: 'Primavera', provincia: '123' },
  { value: '12303', label: 'Timaukel', provincia: '123' },
  { value: '12401', label: 'Natales', provincia: '124' },
  { value: '12402', label: 'Torres del Paine', provincia: '124' },
  { value: '13101', label: 'Santiago', provincia: '131' },
  { value: '13102', label: 'Cerrillos', provincia: '131' },
  { value: '13103', label: 'Cerro Navia', provincia: '131' },
  { value: '13104', label: 'Conchalí', provincia: '131' },
  { value: '13105', label: 'El Bosque', provincia: '131' },
  { value: '13106', label: 'Estación Central', provincia: '131' },
  { value: '13107', label: 'Huechuraba', provincia: '131' },
  { value: '13108', label: 'Independencia', provincia: '131' },
  { value: '13109', label: 'La Cisterna', provincia: '131' },
  { value: '13110', label: 'La Florvaluea', provincia: '131' },
  { value: '13111', label: 'La Granja', provincia: '131' },
  { value: '13112', label: 'La Pintana', provincia: '131' },
  { value: '13113', label: 'La Reina', provincia: '131' },
  { value: '13114', label: 'Las Condes', provincia: '131' },
  { value: '13115', label: 'Lo Barnechea', provincia: '131' },
  { value: '13116', label: 'Lo Espejo', provincia: '131' },
  { value: '13117', label: 'Lo Prado', provincia: '131' },
  { value: '13118', label: 'Macul', provincia: '131' },
  { value: '13119', label: 'Maipú', provincia: '131' },
  { value: '13120', label: 'Ñuñoa', provincia: '131' },
  { value: '13121', label: 'Pedro Aguirre Cerda', provincia: '131' },
  { value: '13122', label: 'Peñalolén', provincia: '131' },
  { value: '13123', label: 'Provvalueencia', provincia: '131' },
  { value: '13124', label: 'Pudahuel', provincia: '131' },
  { value: '13125', label: 'Quilicura', provincia: '131' },
  { value: '13126', label: 'Quinta Normal', provincia: '131' },
  { value: '13127', label: 'Recoleta', provincia: '131' },
  { value: '13128', label: 'Renca', provincia: '131' },
  { value: '13129', label: 'San Joaquín', provincia: '131' },
  { value: '13130', label: 'San Miguel', provincia: '131' },
  { value: '13131', label: 'San Ramón', provincia: '131' },
  { value: '13132', label: 'Vitacura', provincia: '131' },
  { value: '13201', label: 'Puente Alto', provincia: '132' },
  { value: '13202', label: 'Pirque', provincia: '132' },
  { value: '13203', label: 'San José de Maipo', provincia: '132' },
  { value: '13301', label: 'Colina', provincia: '133' },
  { value: '13302', label: 'Lampa ', provincia: '133' },
  { value: '13303', label: 'Tiltil', provincia: '133' },
  { value: '13401', label: 'San Bernardo', provincia: '134' },
  { value: '13402', label: 'Buin', provincia: '134' },
  { value: '13403', label: 'Calera de Tango', provincia: '134' },
  { value: '13404', label: 'Paine', provincia: '134' },
  { value: '13501', label: 'Melipilla', provincia: '135' },
  { value: '13502', label: 'Alhué', provincia: '135' },
  { value: '13503', label: 'Curacaví', provincia: '135' },
  { value: '13504', label: 'María Pinto', provincia: '135' },
  { value: '13505', label: 'San Pedro', provincia: '135' },
  { value: '13601', label: 'Talagante', provincia: '136' },
  { value: '13602', label: 'El Monte', provincia: '136' },
  { value: '13603', label: 'Isla de Maipo', provincia: '136' },
  { value: '13604', label: 'Padre Hurtado', provincia: '136' },
  { value: '13605', label: 'Peñaflor', provincia: '136' }
]

export default comunas
