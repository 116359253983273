import React, { Component, useEffect, useState } from 'react'
import { Field, reduxForm } from 'redux-form'
import { renderFilePicker } from '../Utils/renderField/renderField'

const EntregaForm = (props) => {
  const { handleSubmit, venta, setComprobante, change, title, estado } = props

  const [error, SetError] = useState(false)
  const [seleccionados, setSeleccionado] = useState([])
  const [total_ventas, setTotal] = useState(0)
  const [isModalVisible, setVisible] = useState()

  /*
        useLayoutEffect(() => {
            change("ventas", seleccionados)
          }, []);
*/

  const handleCancel = () => {
    setVisible(false)
  }

  useEffect(() => {
    change('cotizacion', venta[0].cotizacion)
    change('estado', estado)
  })

  return (
    <div>
      <div className='container'>
        <h4 style={{ textAlign: 'center' }}> {title}</h4>
        <form onSubmit={handleSubmit}>
          <div className='form-group'>
            <label>Debes agregar el comprobante de entrega firmado</label>
            <Field
              name='comprobante'
              setFile={setComprobante}
              component={renderFilePicker}
            />
          </div>
          <div>
            <hr />

            <div className='container' style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <div className='container mt-20 pt-20' style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <div>
                  <button type='submit' className='ps-btn'>
                    Confirmar
                  </button>
                </div>
              </div>
            </div>
          </div>

        </form>
      </div>
    </div>
  )
}

export default reduxForm({
  form: 'entregar_pedido', // a unique identifier for this form
  destroyOnUnmount: false // <------ preserve form data

})(EntregaForm)
