import { handleActions } from 'redux-actions'
import { push } from 'react-router-redux'
import { notification } from 'antd'
import { NotificationManager } from 'react-notifications'
import { api } from 'api'
import { setMe } from './login'

export const actionTypes = {
  LOADER: 'LOGIN_LOADER',
  SET_NOTIFICATIONS: 'SET_NOTIFICATIONS',
  SEND_SOLICITUD: 'SEND_SOLICITUD',
  SET_ASOCIADOS: 'SET_ASOCIADOS',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD'

}

const modalSuccessAny = (type, message, description) => {
  notification[type]({
    message: message,
    description: description,
    duration: 3
  })
}

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
  type: actionTypes.LOADER,
  loader
})

export function setNotifications (notificaciones) {
  return {
    type: actionTypes.SET_NOTIFICATIONS,
    notificaciones
  }
}

export function setAsociados (asociados) {
  return {
    type: actionTypes.SET_ASOCIADOS,
    asociados
  }
}

function sendSolicitud (payload) {
  return {
    type: actionTypes.SEND_SOLICITUD,
    payload
  }
}

// ------------------------------------
// Actions
// ------------------------------------

export const invitarUsuario = (data) => (dispatch) => {
  api.post('usuarios/invitar_usuario', data).then((response) => {
    dispatch(getAsociados())
    modalSuccessAny('success', 'Completado', '')
    dispatch(push('/asociados'))
  }).catch((error) => {
    let mensaje = 'Hubo un error, intenta más tarde'
    if (error) {
      if (error.detail) { mensaje = error.detail }
    }
    modalSuccessAny('error', 'Error', mensaje)
  }).finally(() => {
  })
}

export const getAsociados = () => (dispatch, getStore) => {
  api.get('usuarios/asociados').then((response) => {
    dispatch(setAsociados(response))
  }).catch(() => {
    NotificationManager.error('Hubo un error intenta de nuevo', 'ERROR', 0)
  }).finally(() => {
  })
}

export const updateAsociado = (data) => (dispatch, getStore) => {
  api.post('usuarios/estado_asociado', data).then((response) => {
    dispatch(getAsociados())
    modalSuccessAny('success', 'Completado', '')
  }).catch(() => {
    NotificationManager.error('Hubo un error intenta de nuevo', 'ERROR', 0)
  }).finally(() => {
  })
}
export const deleteUser = (data) => (dispatch) => {
    api.post('usuarios/delete_user_proveedor', data).then((response) => {
      dispatch(getAsociados())
      modalSuccessAny('success', 'Completado', '')
    }).catch(() => {
      NotificationManager.error('Hubo un error intenta de nuevo', 'ERROR', 0)
    }).finally(() => {
    })
}

export const disableUser = (data) => (dispatch) => {
  api.post('usuarios/disable_user', data).then((response) => {
    dispatch(getAsociados())
    modalSuccessAny('success', 'Completado', '')
  }).catch(() => {
    NotificationManager.error('Hubo un error intenta de nuevo', 'ERROR', 0)
  }).finally(() => {
  })
}

export const sucessVerify = (is_valid) => (dispatch) => {
  if (is_valid === 'true') {
    dispatch(push('/cuenta-activacion/completado'))
  } else {
    dispatch(push('/cuenta-activacion/error'))
  }
}

export const submitChangePassword = (data) => (dispatch) => {
  api.post('usuarios/changepassword', data).then((response) => {
    dispatch({ type: actionTypes.CHANGE_PASSWORD })
    dispatch(push('/login'))
    modalSuccessAny('success', 'Completado', 'tu contraseña se cambio correctamente')
  }).catch((error) => {
    let mensaje = 'Hubo un error, intenta más tarde'
    if (error) {
      if (error.detail) { mensaje = error.detail }
    }
    modalSuccessAny('error', 'Error', mensaje)
  }).finally(() => {
  })
}

export const getNotificaciones = () => (dispatch) => {
  api.get('notificaciones/mis_notificaciones').then(response => {
    dispatch(setNotifications(response))
  })
    .catch(() => {
    }).finally(() => {})
}

export const actions = {
  getNotificaciones,
  getAsociados,
  invitarUsuario,
  updateAsociado,
  sucessVerify,
  submitChangePassword,
  disableUser,
  deleteUser,
}

export const reducers = {
  [actionTypes.SEND_SOLICITUD]: (state) => {
    return {
      ...state
    }
  },
  [actionTypes.LOADER]: (state, { loader }) => {
    return {
      ...state,
      loader
    }
  },
  [actionTypes.SET_NOTIFICATIONS]: (state, { notificaciones }) => {
    return {
      ...state,
      notificaciones
    }
  },
  [actionTypes.SET_ASOCIADOS]: (state, { asociados }) => {
    return {
      ...state,
      asociados
    }
  },
  [actionTypes.CHANGE_PASSWORD]: (state) => {
    return {
      ...state
    }
  }
}

export const initialState = {
  loader: false,
  notificaciones: {},
  asociados: {}
}

export default handleActions(reducers, initialState)
