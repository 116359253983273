import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import validate from "./validate";
import {
  SelectField,
  renderupdateField,
  renderImageUpload,
  renderFilePicker,
  renderupdateFieldNumber,
} from "../Utils/renderField/renderField";

const ProductUpdateForm = (props) => {
  const [error, imageError] = useState(null);
  const {
    handleSubmit,
    setImagen,
    producto,
    imagen,
    submit,
    pristine,
    valid,
    categorias,
    familias,
  } = props;

  const unidades_medidas = [
    { value: "500", label: "cc" },
    { value: "200", label: "grs" },
    { value: "100", label: "kg" },
    { value: "300", label: "lt" },
    { value: "800", label: "mg" },
    { value: "600", label: "mk" },
    { value: "400", label: "ml" },
    { value: "700", label: "und" },
  ];

  const opt_categorias = [];
  const opt_familia = [];

  {
    categorias.length > 0
      ? categorias.map((categoria) =>
          opt_categorias.push({
            label: categoria.nombre,
            value: categoria.id,
          })
        )
      : null;
  }

  {
    familias.length > 0
      ? familias.map((familia) =>
          opt_familia.push({
            label: familia.nombre,
            value: familia.id,
          })
        )
      : null;
  }

  return (
    <form className="form" onSubmit={handleSubmit}>
      <div style={{ display: "flex", justifyContent: "space-between" }} />
      <hr />
      <div className="ps-form__content">
        <figure className="ps-block--form-box">
          <figcaption>Agregar</figcaption>
          <div className="ps-block__content">
            <div className="form-group">
              <Field
                name="imagen"
                photo={imagen ? imagen : null}
                setFile={setImagen}
                component={renderFilePicker}
                required
              />
              <p style={{ color: "red" }}>{imageError ? error : null}</p>
            </div>
            <div className="form-group">
              <label>Envase: <small style={{color: 'red'}}>*</small></label>
              <Field
                name="envase"
                component={renderupdateFieldNumber}
                type="number"
                max={9999.99}
                placeholder="Envase"
              />
            </div>
            <div className="form-group">
              <label>Unidad de medida: <small style={{color: 'red'}}>*</small></label>
              <Field
                className="form-control"
                name="unidad_medida"
                options={unidades_medidas}
                component={SelectField}
                placeholder="Unidad de medida"
                required
              />
            </div>
            <div className="form-group">
              <label>SKU:</label>
              <Field
                name="sku"
                component={renderupdateField}
                type="text"
                placeholder="SKU"
              />
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <button
                className={
                  !valid
                    ? 'ps-btn'
                    : 'ps-btn'
                }
                style={{
                    backgroundColor: !valid ? '#ccc' : '',
                    cursor:!valid ? 'not-allowed' : '',
                    color: !valid ? '#000' : '' }}
                type='submit'
                disabled={!valid}
                >
                Guardar
              </button>
              <small style={{color: 'red', textAlign: 'center'}}>Campos obligatorios *</small>
            </div>
          </div>
        </figure>
      </div>
    </form>
  );
};

export default reduxForm({
  form: "producto_form", // a unique identifier for this form
  enableReinitialize: true,
  validate,
})(ProductUpdateForm);
