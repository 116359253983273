import React, { Component, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Table, Modal } from 'antd'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import { actions } from '../../../../redux/modules/cuenta/profile'
import AsociadoDisable from './AsociadoDisable'

const TableAsociados = (props) => {
  const [dataSource, setDataSource] = useState(props.profile.asociados)
  const [value, setValue] = useState('')
  const [isModalVisible, setModal] = useState(false)
  const [Ok, setOk] = useState(false)
  const [usuario, setInfo] = useState([])
  const [Cancel, setCancel] = useState(false)

  const showModal = () => {
    setModal(true)
  }

  const handleOk = () => {
    setInfo([])
    setOk(false)
    setModal(false)
  }

  const handleCancel = () => {
    setInfo([])
    setCancel(false)
    setModal(false)
  }

  const handleClose = () => {
    setOk(false)
    setCancel(false)
    setModal(false)
  }

  const handlePermanentDelete = (e, record) => {
    e.preventDefault()
    const data = { username: record.username, action: 'false' }
    handleClose()
    // props.disableUser(data)
    props.deleteUser(data)
  }

  const handleChange = (e, record) => {
    const data = { username: record.username, action: e.target.checked }
    props.updateAsociado(data)
    setDataSource(props.profile.asociados)
  }

  useEffect(() => {
    setDataSource(props.profile.asociados)
    is_valid = true
  }, [props.profile.asociados])

  const columns = [
    {
      title: 'Usuario',
      dataIndex: 'username',
      key: 'id',
      sorter: (a, b) => a.nombre.localeCompare(b.nombre),
      showSorterTooltip: false
    },
    {
      title: 'Nombre(s)',
      showSorterTooltip: false,
      render: (record) => (
                `${record.first_name} ${record.last_name}`
      )
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a.email.localeCompare(b.email),
      showSorterTooltip: false
    },
    {
      title: 'Tipo',
      dataIndex: 'tipo_usuario',
      key: 'tipo_usuario',
      sorter: (a, b) => a.tipo_usuario.localeCompare(b.tipo_usuario),
      showSorterTooltip: false
    },
    {
      title: 'Estado',
      key: 'id',
      render: (record) => {
        if (record.asociado_activo === true) {
          return (
            <FormControlLabel
              control={
                <Switch
                  checked={record.is_active}
                  onClick={e => {
                    handleChange(e, record)
                    props.getAsociados()
                  }}
                  name='checkedB'
                  color='primary'
                />
                        }
            />
          )
        } else {
          return (
            <p>(Sin activar)</p>
          )
        }
      }
    },

    {
      title: '-',
      key: 'id',
      render: (record) => (
        <React.Fragment>
          <button
              style={{
                  backgroundColor: record.is_active ? '#ccc' : '',
                  cursor: record.is_active ? 'not-allowed' : '',
                  color: record.is_active ? '#000' : ''}
              }
              disabled={record.is_active}
              className="ps-btn ps-btn--opt"
              onClick={(e) => {
                  setInfo(record),
                  showModal(true)
              }}
          >
              <i
                  className="icon-trash2"
                  style={{ fontWeight: "600" }}
              />
          </button>
        </React.Fragment>
      )
    }

  ]

  var is_valid = false
  { props.profile.asociados.length > 0
    ? is_valid = true
    : false
  }

  return (
    <div className=''>
      <section className='pl-0 pr-0'>
        <div className='container' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
           <div>
            <Link to='invitar-asociado' className='ps-btn'>
              Agregar +
            </Link>
          </div>

          {is_valid
            ?
              <div className='ps-form--search-simple'>
                <input
                  placeholder='Buscar...'
                  className='form-control'
                  value={value}
                  onChange={e => {
                    const currValue = e.target.value
                    setValue(currValue)
                    const filteredData = props.profile.asociados.filter(entry =>
                      entry.username.toLowerCase().includes(currValue.toLowerCase())
                    )
                    setDataSource(filteredData)
                  }}
                />
                <button><i className='icon icon-magnifier' /></button>
              </div>
            : null}


        </div>
      </section>
      <hr />
      {is_valid === true
        ? <div className='ps-container'>
          <Table dataSource={dataSource} columns={columns} className='table ps-table--market' />
        </div>
        : <div>
          <hr />
          <p>Aun no tienes usuarios invitados ...</p>
        </div>}
      <Modal
        width={800}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        <AsociadoDisable usuario={usuario} handleSend={handlePermanentDelete} closeModal={handleClose} />
      </Modal>
    </div>
  )
}

const ms2p = (state) => {
  return state
}

const md2p = { ...actions }

export default connect(ms2p, md2p)(TableAsociados)
