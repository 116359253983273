import React from 'react'

const Info_retiro = () => {
  return (
    <div className="alert alert-info" role="alert">
        <ul>
            <h3 className="mt-3">
                <i className="icon icon-bubble-alert" />
                Información importante
            </h3>
            Solo podras solicitar retiro de dinero de las cotizaciones que ya se han entregado
        </ul>
    </div>
  )
}

export default Info_retiro
