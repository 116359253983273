import React, { Component, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Number, Currency } from 'react-intl-number-format/dist/index.js'
import { Table, Modal } from 'antd'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Swal from "sweetalert2";
import CrearCuenta from '../Modal/CrearCuenta'
import EditarCuenta from '../Modal/EditarCuenta'
import { actions } from '../../../../redux/modules/bancos/bancos'

const TableCuentas = (props) => {
  const [dataSource, setDataSource] = useState(props.bancos.cuentas)
  const [filter, setFilter] = useState(props.categorias)
  const [value, setValue] = useState('')
  const [isModalVisible, setModal] = useState(false)
  const [info, setInfo] = useState([])
  const [isModalVisible2, setModal2] = useState(false)
  const [Ok, setOk] = useState(false)
  const [Cancel, setCancel] = useState(false)

  const showModal = () => {
    setModal(true)
  }

  const handleOk = () => {
    setModal(false)
  }

  const handleCancel = () => {
    setModal(false)
  }

  // Editar ============================================================

  const showModal2 = () => {
    setModal2(true)
  }

  const handleOk2 = () => {
    setModal2(false)
  }

  const handleCancel2 = () => {
    setModal2(false)
  }

  const bancos = [
    { id: '1', nombre: 'BANCO BBVA' },
    { id: '2', nombre: 'BANCO BICE' },
    { id: '3', nombre: 'BANCO BTG PACTUAL' },
    { id: '4', nombre: 'BANCO CONSORCIO' },
    { id: '5', nombre: 'BANCO DE CHILE-EDWARDS-CITI' },
    { id: '6', nombre: 'BANCO DE CRÉDITO E INVERSIONES' },
    { id: '7', nombre: 'BANCO ESTADO' },
    { id: '8', nombre: 'BANCO FALABELLA' },
    { id: '9', nombre: 'BANCO INTERNACIONAL' },
    { id: '10', nombre: 'BANCO PENTA' },
    { id: '11', nombre: 'BANCO RIPLEY' },
    { id: '12', nombre: 'BANCO SANTANDER-CHILE' },
    { id: '13', nombre: 'BANCO SECURITY' },
    { id: '14', nombre: 'HSBC BANK' },
    { id: '15', nombre: 'ITAÚ CORPBANCA' },
    { id: '16', nombre: 'RABOBANK CHILE' },
    { id: '17', nombre: 'SCOTIABANK CHILE' }
  ]

  const tipo_cuenta = [
    { id: '1', nombre: 'Cuenta Corriente' },
    { id: '2', nombre: 'Cuenta Vista' }
    ]

  const handleSetInfo = (record) => {
    const Info_set = record
    Info_set["no_cuenta"] = record.no_cuenta.toString()
    Info_set["banco"] = bancos.find(option => option.nombre == record.banco).id
    Info_set["tipo"] = record.tipo == 'CORRIENTE' ? '1' : '2'
    setInfo(Info_set)
  };

  const handleSend = (data) => {
    props.vincularCuenta(data)
    props.getBancos({ type: 'SEND_BANCO' })
    setDataSource(props.bancos.cuentas)
    handleOk()
  }

  const handleUpdate = (data) => {
    const dataC = {...data, id: info.id}
    props.actualizarEstadoCampos(dataC)
    handleOk2()
  }

  const handleChange = (e, record) => {
    const data = { id: record.id, action: e.target.checked }
    props.actualizarEstado(data)
    props.getBancos({ type: 'SET_BANCOS' })
    setDataSource(props.bancos.cuentas)
  }

  const sweet = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-lg ps-btn ps-btn--opt button--min--size",
      cancelButton: "btn btn-lg btn-danger px-4 button--min--size",
    },
    buttonsStyling: false,
  });

  const handleDeleteProduct = (e) => {
    sweet
      .fire({
        title: "¿Estás seguro de eleminar la cuenta?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        denyButtonText: "Cancelar",
      })
      .then((result) => {
        if (result.value) {
          props.deleteCuenta(e);
          setTimeout(() => {
            props.getBancos({ type: "SET_BANCOS" });
            setDataSource(props.bancos.cuentas)
          }, 1000);
        }
      });
  };

  useEffect(() => {
    setDataSource(props.bancos.cuentas)
    is_valid = true
  }, [props.bancos.cuentas])

  const columns = [
    {
      title: 'Banco',
      dataIndex: 'banco',
      key: 'id',
      sorter: (a, b) => a.banco.localeCompare(b.banco),
      showSorterTooltip: false
    },
    {
      title: 'Tipo',
      dataIndex: 'tipo',
      key: 'id'
    },
    {
      title: 'Cuenta',
      dataIndex: 'no_cuenta',
      key: 'no_cuenta'
    },
    {
      title: 'Estado',
      dataIndex: 'activo',
      key: 'id',
      render: (activo, record) => (
        <FormControlLabel
          control={
            <Switch
              checked={activo}
              onClick={e => {
                handleChange(e, record)
                props.getBancos({ type: 'SET_BANCOS' })
              }}
              name='checkedB'
              color='primary'
            />
            }
        />
      )
    },
    {
      title: '-',
      key: 'id',
      render: (activo, record) => (
        <React.Fragment>
            <div className='d-flex wrap justify-content-around'>
                <button className='ps-btn  ps-btn--opt' onClick={
                    (e) => {
                        showModal2(),
                        handleSetInfo(record)
                    }
                }>
                <i className="icon-pencil"></i>
                </button>
                <button
                  className="ps-btn ps-btn--opt"
                  onClick={(e) => {
                    handleDeleteProduct(record);
                  }}
                >
                  <i className="icon-trash"></i>
                </button>
            </div>
        </React.Fragment>
      )
    }
  ]

  var is_valid = false
  { props.bancos.cuentas.length > 0
    ? is_valid = true
    : false
  }

  return (
    <div>
      <div className='form-group '>
        <section className='pl-0 pr-0'>
          <div className='container' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div>
              <button className='ps-btn' onClick={showModal}>Agregar una cuenta</button>
            </div>
            {is_valid
              ?
                <div className='ps-form--search-simple'>
                  <input
                    placeholder='Buscar...'
                    className='form-control'
                    value={value}
                    onChange={e => {
                      const currValue = e.target.value
                      setValue(currValue)
                      const filteredData = props.bancos.cuentas.filter(entry =>
                        entry.banco.toLowerCase().includes(currValue.toLowerCase())
                      )
                      setDataSource(filteredData)
                    }}
                  />
                  <button><i className='icon icon-magnifier' /></button>
                </div>
              : null}


          </div>
        </section>
        <hr />
        {is_valid === true
          ? <Table dataSource={dataSource} columns={columns} className='table ps-table--market' />
          : <p>Aun no tienes cuentas agregadas...</p>}
      </div>
      <Modal
        title=''
        width={800}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[

        ]}
      >

        <CrearCuenta onSubmit={handleSend} me={props.login.me.tienda} />

      </Modal>

      <Modal
        title=''
        width={800}
        visible={isModalVisible2}
        onOk={handleOk2}
        onCancel={handleCancel2}
        footer={[
        ]}
      >
        <EditarCuenta
            info={info}
            onSubmit={handleUpdate}
            me={props.login.me.tienda}
            initialValues={info}
            />
      </Modal>
    </div>
  )
}

const ms2p = (state) => {
  return state
}

const md2p = { ...actions }

export default connect(ms2p, md2p)(TableCuentas)
