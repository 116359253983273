const validate = values => {
    const errors = {}

    if (!values.password) errors.password = 'Ingresa una contraseña';

    if (!values.password_confirmation) {
      errors.password_confirmation = 'Ingresa una contraseña';
    } else if (values.password_confirmation !== values.password) {
      errors.password_confirmation = 'Las contraseñas no coinciden';
    }

    return errors
  }


export const password = value =>
value && !/^(((?=.*[a-z])(?=.*[A-Z]))((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/.test(value)
 ? 'Tu contraseña debe contener 6 caracteres mínimo, almenos 1 mayúscula y almenos 1 número.'
 : undefined;

 export const passwordsMustMatch = (value, allValues) =>
 value !== allValues.password ?
   'Las contraseñas no coinciden' :
    undefined

 export default validate
