import React, { Component } from 'react'
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react'

export class MapContainer extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  render () {
    const style = {
      width: '70%',
      height: '100%',
      alignItems: 'center'

    }

    const { ubicacion } = this.props
    return (
      <Map
        google={this.props.google}
        style={style}
        initialCenter={{
          lat: ubicacion.latitud,
          lng: ubicacion.longitud
        }}
        zoom={17}
      >

        <Marker
          name={ubicacion.direccion}
          position={{ lat: parseFloat(ubicacion.latitud), lng: parseFloat(ubicacion.longitud) }}
        />
        <Marker />
      </Map>
    )
  }
}

export default GoogleApiWrapper({
  apiKey: ('AIzaSyCc7uFE7m8XJ17HcYQrXPF2SiTXysfDmss')
})(MapContainer)
