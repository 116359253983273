import React from "react";
import { Link } from "react-router-dom";

const SimpleBorderedCard = (props) => {
  const { url, img, title, description } = props;
  return (
    <div>
      <Link to={url}>
        <div className="ps-block--form-box2">
          <div className="ps-block__content">
            <div className="ps-block__left">
              <img src={img} height="75px" alt="Wallet" />
            </div>
            <h4 style={{ textAlign: "center" }}>{title}</h4>
            <hr />
            <p>{description}</p>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default SimpleBorderedCard;
