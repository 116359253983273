import React, { useState, useLayoutEffect, Component } from 'react'
import { connect } from 'react-redux'
import MapContainer from './MapContainer'

const DetalleUbicacion = (props) => {
  const { ubicacion, setUbicacion, regresar, editar } = props

  return (
    <div>
      <div className='container pl-5'>
        <button className='ps-btn' style={{backgroundColor: "#2e3d50", color: "white"}} onClick={regresar}>
          Regresar
        </button>
      </div>
      <hr />
      <div className='container' style={{ display: 'flex', justifyContent: 'space-evenly' }}>

        <table className='ps-table--market'>
          <thead>
            <tr>
              <th>Dirección</th>
              <th>Region</th>
              <th>Provincia</th>
              <th>Comuna</th>
              <th>-</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{props.ubicacion.direccion}</td>
              <td>{props.ubicacion.region_nombre}</td>
              <td>{props.ubicacion.provincia_nombre}</td>
              <td>{props.ubicacion.comuna_nombre}</td>
              <td>
                <button
                  className='ps-btn ps-btn--opt'
                  onClick={editar}
                >
                  <i className='icon-pencil'>...</i>
                </button>
              </td>

            </tr>
          </tbody>
        </table>
      </div>

      <hr />
      <div className='container' style={{ display: 'flex', justifyContent: 'space-around' }} />
      <MapContainer ubicacion={ubicacion} />
    </div>
  )
}

const md2p = {}

export default connect(null, md2p)(DetalleUbicacion)
