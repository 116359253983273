import React, { useEffect, useState } from 'react'
import { reduxForm } from 'redux-form'

const PickUpForm = (props) => {
  const { handleSubmit, venta, cuentas, change, title, estado } = props

  const [error, SetError] = useState(false)
  const [seleccionados, setSeleccionado] = useState([])
  const [total_ventas, setTotal] = useState(0)
  const [isModalVisible, setVisible] = useState()

  /*
        useLayoutEffect(() => {
            change("ventas", seleccionados)
          }, []);
*/

  const handleCancel = () => {
    setVisible(false)
  }

  useEffect(() => {
    change('cotizacion', venta[0].cotizacion)
    change('estado', estado)
  })

  return (
    <div>
      <div className='container'>
        <h4 style={{ textAlign: 'center' }}> {title}</h4>
        <form onSubmit={handleSubmit}>
          <table className='table table-striped'>
            <thead>
              <tr>
                <th>Producto</th>
                <th>Envase</th>
                <th>Cantidad</th>
              </tr>
            </thead>
            <tbody>
              {venta.map(venta => (
                <tr>
                  <td>
                    {venta.descripcion[0].nombre_comercial}
                  </td>
                  <td>
                    {venta.descripcion[0].envase}
                  </td>
                  <td>
                    {venta.cantidad}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div>
            <hr />

            <div className='container' style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <div className='container mt-20 pt-20' style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <div>
                  <button type='submit' className='ps-btn'>
                    Confirmar
                  </button>
                </div>
              </div>
            </div>
          </div>

        </form>
      </div>
    </div>
  )
}

export default reduxForm({
  form: 'confirmar_pedido', // a unique identifier for this form
  destroyOnUnmount: false // <------ preserve form data

})(PickUpForm)
