const db_regiones = [
  { id: '15', nombre: 'Arica y Parinacota' },
  { id: '01', nombre: 'Tarapacá' },
  { id: '02', nombre: 'Antofagasta' },
  { id: '03', nombre: 'Atacama' },
  { id: '04', nombre: 'Coquimbo' },
  { id: '05', nombre: 'Valparaíso' },
  { id: '06', nombre: "Libertador Gral. Bernardo O'Higgins" },
  { id: '07', nombre: 'Maule' },
  { id: '08', nombre: 'Biobío' },
  { id: '09', nombre: 'La Araucanía' },
  { id: '14', nombre: 'Los Ríos' },
  { id: '10', nombre: 'Los Lagos' },
  { id: '11', nombre: 'Aysén del Gral. Carlos Ibáñez del Campo' },
  { id: '12', nombre: 'Magallanes y de la Antártica Chilena' },
  { id: '13', nombre: 'Metropolitana de Santiago' }
]

export default db_regiones
