import React from 'react'
import PropTypes from 'prop-types'
import { MdCloudUpload } from 'react-icons/md'

const Placeholder = ({ getInputProps, getRootProps, error, touched }) => (
  <div
    {...getRootProps()}
    className={`placeholder-preview ${error && touched ? 'has-error' : ''}`}
  >
    <input {...getInputProps()} />
    <MdCloudUpload style={{ fontSize: 100, paddingTop: 85 }} />
    <p>Haz clic sobre este cuadro para cargar la imagen del producto, tamaño de archivo máximo 2MB</p>
  </div>
)

Placeholder.propTypes = {
  error: PropTypes.string,
  getInputProps: PropTypes.func.isRequired,
  getRootProps: PropTypes.func.isRequired,
  touched: PropTypes.bool
}

export default Placeholder
