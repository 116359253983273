const validate = (values) => {
    const errors = {};

    if (!values.nombre) {
        errors.nombre = 'Este campo es requerido';
    }
    if (!values.direccion) {
        errors.direccion = 'Este campo es requerido';
    }
    if (!values.banco) {
        errors.banco = 'Este campo es requerido';
    }
    if (!values.region) {
        errors.region = 'Este campo es requerido';
    }
    if (!values.provincia) {
        errors.provincia = 'Este campo es requerido';
    }
    if (!values.comuna) {
        errors.comuna = 'Este campo es requerido';
    }

    if (!values.telefono) {
        errors.telefono = 'Este campo es requerido';
    } else {
        if(values.telefono.includes('+')) {
            !/^\+56\d{9}$/.test(values.telefono.replace(/\((\w+)\)/g, "$1").replace('-', '')) ? errors.telefono = 'Telefon inválido' : null
        }else {
            !/^\d{9}$/.test(values.telefono.replace(/\((\w+)\)/g, "$1").replace('-', '')) ? errors.telefono = 'Telefon inválido' : null
        }
    }

    return errors;
};

export default validate;
