import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../Login/Login.scss";
import FooterWidgets from '../../shared/footer/FooterWidgets';
import FooterCopyright from '../../shared/footer/FooterCopyright';

class PasswordRecoveryConfirm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
  };

  render() {
    const { onSubmit, loader } = this.props;

    return (
      <React.Fragment>
        <div className="register-photo">
          <div className="form-container w-75 " style={{ height: "54vh" }}>
            {/* CAMBIAR ESTE LINK EN PRODUCION */}
            <a
              href="https://test127.ciancoders.com/"
              className="image-holder"
            ></a>
            <div
              className="ps-tab active h-100 align-items-center "
              style={{
                backgroundColor: "#fff",
              }}
            >
              <div className="ps-form__content p-5">
                <div className=" template-content px-5">
                  <h1 className="text-center">¡Información Enviada!</h1>
                  <p className="text-sec">
                    Hemos recibido tu solicitud de recuperación de la cuenta y
                    te hemos enviado un e-mail con las instrucciones. Recuerda
                    comprobar los "correos no deseados".
                  </p>
                  <Link to="/login">
                    <button
                      className="ps-btn ps-btn--fullwidth  p-4"
                    >
                      INICIAR SESIÓN
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className="ps-footer">
          <div className="ps-container">
            <FooterWidgets />
            <FooterCopyright />
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

export default PasswordRecoveryConfirm;
