import React, { Component, useLayoutEffect, useState } from 'react'
import { Field, reduxForm, reset} from 'redux-form'
import { onlyReadRenderField, renderField, SelectField } from '../../Utils/renderField/renderField'
import validate from './validate_cuenta'

const CuentaBancoForm = (props) => {
  const { handleSubmit, change, me, valid} = props

  const [error, SetError] = useState(false)

  const opt_bancos = []
  const opt_tipo = []

  const bancos = [
    { id: '1', nombre: 'BANCO BBVA' },
    { id: '2', nombre: 'BANCO BICE' },
    { id: '3', nombre: 'BANCO BTG PACTUAL' },
    { id: '4', nombre: 'BANCO CONSORCIO' },
    { id: '5', nombre: 'BANCO DE CHILE-EDWARDS-CITI' },
    { id: '6', nombre: 'BANCO DE CRÉDITO E INVERSIONES' },
    { id: '7', nombre: 'BANCO ESTADO' },
    { id: '8', nombre: 'BANCO FALABELLA' },
    { id: '9', nombre: 'BANCO INTERNACIONAL' },
    { id: '10', nombre: 'BANCO PENTA' },
    { id: '11', nombre: 'BANCO RIPLEY' },
    { id: '12', nombre: 'BANCO SANTANDER-CHILE' },
    { id: '13', nombre: 'BANCO SECURITY' },
    { id: '14', nombre: 'HSBC BANK' },
    { id: '15', nombre: 'ITAÚ CORPBANCA' },
    { id: '16', nombre: 'RABOBANK CHILE' },
    { id: '17', nombre: 'SCOTIABANK CHILE' }
  ]

  const tipo_cuenta = [
    { id: '1', nombre: 'Cuenta Corriente' },
    { id: '2', nombre: 'Cuenta Vista' }

  ]

  { bancos.length > 0
    ? bancos.map((option) => (
        opt_bancos.push({ value: option.id, label: option.nombre })
      )
      )
    : null
  }

  { tipo_cuenta.length > 0
    ? tipo_cuenta.map((option) => (
        opt_tipo.push({ value: option.id, label: option.nombre })
      )
      )
    : null
  }

  return (
    <div>
      <div className='container'>
        <h4 style={{ textAlign: 'center' }}>Agregar cuenta bancaria</h4>
        <form  name="agregar_cuenta" onSubmit={handleSubmit}>

          <div className='container' style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <div className='form-group col-8 pt-10'>
              <label htmlFor=''>RUT</label>
              <input className='form-control' value={me.rut} readOnly />
            </div>
            <div className='form-group col-8 pt-10'>
              <label htmlFor=''>Banco</label>
              <Field
                className='form-control'
                name='banco'
                options={opt_bancos}
                component={SelectField}
                placeholder='Banco'
                required
              />
            </div>
            <div className='form-group col-8 pt-10'>
              <label htmlFor=''>Tipo de cuenta</label>
              <Field
                className='form-control'
                name='tipo'
                options={opt_tipo}
                component={SelectField}
                placeholder='Tipo de cuenta'
                required
              />
            </div>
            <div className='form-group col-8 pt-10'>
              <label htmlFor=''>No. Cuenta</label>
              <Field
                className='form-control'
                name='no_cuenta'
                component={renderField}
                placeholder='No. Cuenta'
                required
              />
            </div>
          </div>
          <div className='container mt-20 pt-20' style={{ display: 'flex', justifyContent: 'center' }}>
            <button
              className={
                !valid
                  ? 'ps-btn'
                  : 'ps-btn'
              }
              style={{
                  backgroundColor: !valid ? '#ccc' : '',
                  cursor:!valid ? 'not-allowed' : '',
                  color: !valid ? '#000' : '' }}
              type='submit'
              disabled={!valid}
              >
              Guardar
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
const afterSubmit = (result, dispatch) =>
  dispatch(reset("agregar_cuenta"));

export default reduxForm({
  form: "agregar_cuenta", // a unique identifier for this form
  validate,
  onSubmitSuccess: afterSubmit,
})(CuentaBancoForm)
