import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import db_comunas from "../../../database/comunas";
import db_regiones from "../../../database/regiones";
import db_provincias from "../../../database/provincias";
import { Form } from "antd";

const FacturacionForm = (props) => {
  const { handleSend, tienda } = props;

  const [regiones, setRegiones] = useState(db_regiones);
  const [provincias, setProvincias] = useState(db_provincias);
  const [comunas, setComunas] = useState(db_comunas);
  const [filtered_provincias, setFilteredProvincias] = useState([]);
  const [filtered_comunas, setFilteredComunas] = useState([]);

  const [fact_nombre_comercial, setFactNombreComercial] = useState("");
  const [fact_razon_social, setFactRazonSocial] = useState("");
  const [fact_giro, setFactGiro] = useState("");
  const [fact_direccion, setFactDireccion] = useState("");
  const [fact_region, setFactRegion] = useState(0);
  const [fact_provincia, setFactProvincia] = useState(0);
  const [fact_comuna, setFactComuna] = useState(0);
  const [fact_rut, setFactRut] = useState("");
  const [rutValid, setRutValid] = useState(false);
  const [fact_telefono, setFactTelefono] = useState("");

  const handleTelefono = (e) => {
    setFactTelefono(e.target.value.replace('+(56)', ''));
    }
  const handleChangeRut = (e) => {

    const formato = (rut) => {
		if (!rut | typeof rut !== 'string') return false
		return /^\d{1,2}.\d{3}.\d{3}-[k|K|\d]{1}$/.test(rut)
	}
    // 8,287,562-K
	const digitoVerificador = (rut) => {
		let multiplos = [2,3,4,5,6,7]
        // remplazar las ',' por '.' en el rut
        rut = rut.replace(/\,/g, '.')
        // eliminar los caracteres que no sean numeros
		let digitos = rut.split('-')[0].replace(/\./g,'').split('').reverse()
		let digitoVerificador = rut.split('-')[1].toUpperCase()

		let digito = 11 - digitos.reduce((acc,elem,index)=>(acc+Number(elem)*multiplos[index%multiplos.length]),0)%11

		let digimap = [NaN,'1','2','3','4','5','6','7','8','9','K','0']

		return digimap[digito]===digitoVerificador
	}

    if (e.target) {
	setRutValid (formato(e.target.value) && digitoVerificador(e.target.value))
    setFactRut(e.target.value);
    }
    else{
        setRutValid (formato(e) && digitoVerificador(e))
        setFactRut(e);
    }
}


  useEffect(() => {
    setFactNombreComercial(tienda.fact_nombre_comercial);
    setFactRazonSocial(tienda.fact_razon_social);
    setFactGiro(tienda.fact_giro);
    setFactDireccion(tienda.fact_direccion);
    setFactRegion(tienda.fact_region);
    setFactProvincia(tienda.fact_provincia);
    setFactComuna(tienda.fact_comuna);
    handleChangeRut(tienda.fact_rut);
    setFactTelefono(tienda.fact_telefono.replace('+(56)', ''));
  }, [tienda]);

  const opt_regiones = [];
  const opt_provincias = [];
  let opt_comunas = [];

  {
    regiones.length > 0
      ? regiones.map((option) =>
          opt_regiones.push({ value: option.id, label: option.nombre })
        )
      : null;
  }

  {
    provincias.length > 0
      ? provincias.map((option) =>
          opt_provincias.push({ value: option.value, label: option.label })
        )
      : null;
  }

  {
    comunas.length > 0
      ? comunas.map((option) =>
          opt_comunas.push({ value: option.value, label: option.label })
        )
      : null;
  }

  const handleChangeRegion = (e) => {
    const target = e.target.value;
    const filteredData = provincias.filter((entry) =>
      entry.region.includes(target)
    );
    const region_texto = regiones.find((entry) => entry.id === target);
    setFilteredProvincias(filteredData);
    setFactRegion(region_texto.id);
    handleChangeProvincia({ target: { value: filteredData[0].value } });
  };

  const handleChangeProvincia = (e) => {
    const target = e.target.value;
    opt_comunas = [];
    const filteredData = comunas.filter((entry) =>
      entry.provincia.includes(target)
    );
    const provincia_texto = provincias.find((entry) => entry.value === target);
    setFilteredComunas(filteredData);
    setFactProvincia(provincia_texto.value);
    setFactComuna(filteredData[0].value);
  };

  const handleChangeComuna = (e) => {
    const target = e.target.value;
    const comuna_texto = comunas.find((entry) => entry.value === target);
    setFactComuna(comuna_texto.value);
  };

  const preSubmit = (data) => {
    data.fact_comuna = fact_comuna;
    data.fact_provincia = fact_provincia;
    const facturacion = {
      ...tienda,
      ...data
    }
    facturacion["fact_rut"] = fact_rut;
    facturacion["fact_telefono"] = "+(56)".concat(facturacion["fact_telefono"].replace('+(56)', ''))
    handleSend(facturacion);
  };

  return (
    <Form
      name="basic"
      layout="vertical"
      onFinish={preSubmit}
      initialValues={tienda}
      autoComplete="off"
      preserve={false}
      className="form container"
    >
      <div className="ps-form__content">
        <figure className="ps-block--form-box3">
          <figcaption>Datos de facturación</figcaption>
          <div className="ps-block__content">
            <div className="form-group">
              <Form.Item
                label="Nombre de la empresa:"
                name="fact_nombre_comercial"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido.",
                  },
                ]}
              >
                <input
                  type="text"
                  className="form-control"
                  name="fact_nombre_comercial"
                  id="fact_nombre_comercial"
                  value={fact_nombre_comercial}
                  onChange={setFactNombreComercial}
                  aria-describedby="helpId"
                  placeholder="Nombre comercial"
                />
              </Form.Item>
            </div>
            <div className="form-group">
              <Form.Item
                label="Razón Social:"
                name="fact_razon_social"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido.",
                  },
                ]}
              >
                <input
                  type="text"
                  className="form-control"
                  name="fact_razon_social"
                  id="fact_razon_social"
                  value={fact_razon_social}
                  onChange={setFactRazonSocial}
                  aria-describedby="helpId"
                  placeholder="Razón social"
                />
              </Form.Item>
            </div>
            <div className="form-group">
              <Form.Item
                label="Giro:"
                name="fact_giro"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido.",
                  },
                ]}
              >
                <input
                  type="text"
                  className="form-control"
                  name="fact_giro"
                  id="fact_giro"
                  value={fact_giro}
                  onChange={setFactGiro}
                  aria-describedby="helpId"
                  placeholder="Giro"
                />
              </Form.Item>
            </div>
            <div className="form-group">
              <Form.Item
                label="Dirección:"
                name="fact_direccion"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido.",
                  },
                ]}
              >
                <input
                  type="text"
                  className="form-control"
                  name="fact_direccion"
                  id="fact_direccion"
                  value={fact_direccion}
                  onChange={setFactDireccion}
                  aria-describedby="helpId"
                  placeholder="Dirección"
                />
              </Form.Item>
            </div>
            <div className="form-group">
              <Form.Item
                label="Región:"
                name="fact_region"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido.",
                  },
                ]}
              >
                <select
                  className="form-control"
                  aria-label="Default select example"
                  name="fact_region"
                  value={fact_region}
                  onChange={(e) => handleChangeRegion(e)}
                >
                  {opt_regiones.map((e, index) => (
                    <option key={index} value={e.value}>
                      {e.label}
                    </option>
                  ))}
                </select>
              </Form.Item>
            </div>
            <div className="form-group">
              <Form.Item
                label="Provincia:"
                name="fact_provincia"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido.",
                  },
                ]}
              >
                <select
                  className="form-control"
                  aria-label="Default select example"
                  name="fact_provincia"
                  value={fact_provincia}
                  onChange={handleChangeProvincia}
                >
                  {filtered_provincias.length > 0
                    ? filtered_provincias.map((e, index) => (
                        <option key={index} value={e.value}>
                          {e.label}
                        </option>
                      ))
                    : opt_provincias.map((e, index) => (
                        <option key={index} value={e.value}>
                          {e.label}
                        </option>
                      ))}
                </select>
              </Form.Item>
            </div>
            <div className="form-group">
              <Form.Item
                label="Comuna:"
                name="fact_comuna"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido.",
                  },
                ]}
              >
                <select
                  className="form-control"
                  aria-label="Default select example"
                  name="fact_comuna"
                  value={fact_comuna}
                  onChange={handleChangeComuna}
                >
                  {filtered_comunas.length > 0
                    ? filtered_comunas.map((e, index) => (
                        <option key={index} value={e.value}>
                          {e.label}
                        </option>
                      ))
                    : opt_comunas.map((e, index) => (
                        <option key={index} value={e.value}>
                          {e.label}
                        </option>
                      ))}
                </select>
              </Form.Item>
            </div>
            <div className="form-group">
              <Form.Item
                label="RUT:"
                name="fact_rut"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido.",
                  },
                ]}
              >
                <input
                  type="text"
                  className="form-control"
                  name="fact_rut"
                  id="fact_rut"
                  value={fact_rut}
                  onChange={handleChangeRut}
                  aria-describedby="helpId"
                  placeholder="RUT"
                />
                {!rutValid && (<div style={{color: '#ff4d4f'}}>Rut invalido</div>)}
              </Form.Item>
            </div>
            <div className="form-group">
              <Form.Item
                label="Telefono:"
                name="fact_telefono"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido.",
                  },
                ]}
              >
                <div className="input-group mb-3">
                    <span
                    className="input-group-text" id="basic-addon1"
                    style={{fontSize: '1.3rem'}}
                    >+(56)</span>
                    <input
                        placeholder="Telefono"
                        type='text'
                        name="fact_telefono"
                        id="fact_telefono"
                        minLength='9'
                        maxLength='9'
                        className="form-control"
                        value={fact_telefono.replace('+(56)', '')}
                        required title="Minimo 9 digitos numericos"
                        onChange={handleTelefono}
                        aria-describedby="basic-addon1"
                        pattern="[0-9]+"
                    />
                </div>
                {/* <input
                  type="text"
                  className="form-control"
                  name="fact_telefono"
                  id="fact_telefono"
                  value={fact_telefono}
                  onChange={setFactTelefono}
                  aria-describedby="helpId"
                  placeholder="Telefono"
                /> */}
              </Form.Item>
            </div>
            <div
              className="container"
              style={{ display: "flex", justifyContent: "space-around" }}
            >
              <Link to="/perfil" className="ps-btn ps-btn--gray">
                Cancelar
              </Link>
              <button
                className={
                  !rutValid
                    ? 'ps-btn'
                    : 'ps-btn'
                }
                style={{
                    backgroundColor: !rutValid ? '#ccc' : '',
                    cursor: !rutValid ? 'not-allowed' : '',
                    color: !rutValid ? '#000' : '' }}
                type='submit'
                disabled={!rutValid}
                >
                Guardar Cambios
              </button>
            </div>
          </div>
        </figure>
      </div>
    </Form>
  );
};

export default FacturacionForm;
