import React, { Component } from "react";
import { Currency } from "react-intl-number-format/dist/index.js";
import CurrencyInput from "react-currency-input-field";
import { actions } from "../../../redux/modules/cotizaciones/cotizacion";
import { connect } from "react-redux";
import { Modal } from "antd";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";
import { api, basepath } from "../../../utility/api";
import DefaultHeader from "../layout/Header/DefaultHeader";
import moment from "moment";
import LoadMask from "../Utils/LoadMask/LoadMask";
import ContizacionInvalidModal from "./ContizacionInvalidModal";
class CotizacionDetalle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.match.params.id,
      cotizacion: [],
      iva: 0.19,
      encabezado: "",
      status: false,
      editable: false,
      _total: 0,
      scrollPosition: "",
      cotizacion_invalid: {},
      invalid: false,
      cotizacion_loading: false,
    };
  }

  openModal = () => {
    window.scrollTo(0, 0);
    this.setState({ status: true});
    const data = {}
    for (let i = 0; i < this.state.cotizacion.length; i++) {
      data[this.state.cotizacion[i].id] = {
        cantidad: this.state.cotizacion[i].cantidad,
        precio: this.state.cotizacion[i].precio,
        unidad: this.state.cotizacion[i].unidad,
        total: this.state.cotizacion[i].total,
      };
    }

    api.post(`/categorias-feeds-commissions/veri_cot_com/?id=${this.state.id}`, data)
        .then((res) => {
            this.setState({invalid: !res.is_valid_total})
            this.setState({cotizacion_invalid: res})
        })
        .catch((err) => {
            console.log(err);
        })
        .finally(() => {
            this.setState({ cotizacion_loading: false });
        });
  };

  closeModal = () => {
    this.setState({ status: false });
  };

  handleClick = (e) => {
    history.push("/");
  };

  handleUpdateCotizacion = () => {
    const { cotizacion } = this.state;
    this.props.onSubmitCotizacion(cotizacion);
    this.closeModal();
  };

  calculateSubtotal = (producto, value, index) => {
    let total_c = 0;
    const { cotizacion } = this.state;
    let precio;
    {
      value ? (precio = value) : (precio = 0);
    }
    total_c = producto.cantidad * precio;
    total_c = total_c + total_c * this.state.iva;
    const newCounters = Object.assign(cotizacion, {
      ...cotizacion,
      [index]: {
        ...cotizacion[index],
        total: total_c,
        precio: value,
        unidad: value,
      },
    });
    this.setState({ cotizacion: newCounters });
    this.calculateTotal();
  };

  calculateTotal = () => {
    let total = 0;
    this.state.cotizacion.map((monto) => (total += parseFloat(monto.total?monto.total:0)));
    this.setState({ _total: total });
  };

  setTotal = (cotizacion) => {
    let total = 0;
    this.state.cotizacion.map((monto) => (total += parseFloat(monto.total?monto.total:0)));
    this.setState({ _total: total });
    return total;
  };

  componentDidMount() {
    var id = this.state.id;
    this.getCotizacion(id);
    this.calculateTotal();
    this.setTotal(this.state.cotizacion);
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.cotizacion !== this.state.cotizacion) {
        this.calculateTotal();
        this.setTotal(this.state.cotizacion);
    }
    }


  getIva = async () => {
    await api.get('config/get_iva').then((response) => {
      this.setState({iva: response.valor_numerico / 100});
    })
  }

  getCotizacion = async () => {
    const SPProducts = await api
      .get(`cotizaciones/request_to_me/?identifier=${this.state.id}`)
      .then((response) => {
        //setProductItems(response.results);
        if (response) {
          this.setState({ cotizacion: response });
          this.setState({ editable: response[0].editable });
          this.setState({ encabezado: response[0].encabezado[0] });
        } else {
          //setEncabezado({})
        }
        setTimeout(function () {}, 500);
        return SPProducts;
      });
  };

  render() {
    const { cotizacion, encabezado, _total, editable, cotizacion_loading, cotizacion_invalid, invalid} = this.state;
    return (
      <div>
        <DefaultHeader title="Cotizacion" search_bar={false} />
        {editable ? (
          <div>
            <div
              className="ps-section__cart-actions"
              style={{ display: "flex" }}
            >
              <Link to="/cotizaciones" style={{backgroundColor: "#2e3d50", color: "white"}} className="ps-btn ps-btn--gray mr-5">
                Regresar
              </Link>
            </div>
            <hr />
            <h4>Detalle de cotización</h4>
            <form className="ps-form--account">
              <table className="table ps-table--market">
                <thead>
                  <tr>
                    <th>Producto</th>
                    <th>Descripcion</th>
                    <th>Envase</th>
                    <th>Cantidad</th>
                    <th>Precio Unitario (CLP+IVA)</th>
                    <th>VALOR TOTAL (CLP)</th>
                  </tr>
                </thead>
                <tbody>
                  {cotizacion.map((c, index) => (
                    <tr key={c.id}>
                      {c.descripcion.map((producto) => (
                        <React.Fragment>
                          <td>
                            <LazyLoad>
                              <img
                                src={`${basepath}/media/${producto.imagen}`}
                                alt={`${basepath}/media/${producto.imagen}`}
                              />
                            </LazyLoad>
                          </td>

                          <td>{producto.nombre_comercial}</td>
                          <td>{producto.envase}</td>
                          <td>{c.cantidad}</td>
                          <td>
                            <CurrencyInput
                              className="form-control"
                              maxLength="10"
                              id="input-example"
                              name="precio"
                              allowNegativeValue = {false}
                              allowDecimals={true}
                              placeholder="(Precio)"
                              groupSeparator= ','
                              decimalSeparator= '.'
                              prefix= '$'
                              disableGroupSeparators={false}
                              decimalsLimit={2}
                              onValueChange={(value, name) =>
                                this.calculateSubtotal(c, value, index)
                              }
                              value={Number(c.precio?c.precio:0).toFixed(2)}
                            />
                          </td>
                        </React.Fragment>
                      ))}

                      <td>
                        {c.total ?
                          <Currency groupSeparator= ',' decimalSeparator= '.' allowDecimals={true} decimalsLimit={2}>
                            {c.total}
                          </Currency>
                        :
                        <Currency groupSeparator= ',' decimalSeparator= '.' allowDecimals={true} decimalsLimit={2}>
                            0.00
                          </Currency>
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <hr />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                }}
              >
                <div
                  className="ps-section__cart-actions"
                  style={{ display: "flex" }}
                >
                  <Link to="/cotizaciones" style={{backgroundColor: "#2e3d50", color: "white"}} className="ps-btn ps-btn--gray mr-5">
                    Regresar
                  </Link>
                  <Link
                    to="#"
                    className="ps-btn  mr-5"
                    onClick={()=>{
                        this.setState({cotizacion_loading: true})
                        this.openModal();
                    }}
                  >
                    Enviar cotización
                  </Link>
                </div>
                <div
                  className="ps-container"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h3>Precio total:</h3>
                  <h3 className="pl-2" style={{ color: "#FCB800" }}>
                    {/*encabezado.total> 0?
                    <Currency locale="es-CL" currency="CLP">{encabezado.total}</Currency>
                        :
                    <Currency locale="es-CL" currency="CLP">{_total}</Currency>
                        */}
                    <Currency groupSeparator= ',' decimalSeparator= '.' allowDecimals={true} decimalsLimit={2}>
                      {_total}
                    </Currency>
                  </h3>
                </div>
              </div>
            </form>
          </div>
        ) : (
          <div>
            <div
              className="ps-section__cart-actions"
              style={{ display: "flex" }}
            >
              <Link to="/cotizaciones" style={{backgroundColor: "#2e3d50", color: "white"}} className="ps-btn ps-btn--gray mr-5">
                Regresar
              </Link>
            </div>
            <hr />
            <h4>Detalle de cotización</h4>
            <div className="alert alert-warning" role="alert">
              Ya haz enviado una respuesta al comprador, debes esperar su
              respuesta.
            </div>
            <form className="ps-form--account">
              <table className="table ps-table--market">
                <thead>
                  <tr>
                    <th>Producto</th>
                    <th>Descripcion</th>
                    <th>Envase</th>
                    <th>Cantidad</th>
                    <th>Precio Unitario (CLP+IVA)</th>
                    <th>VALOR TOTAL (CLP)</th>
                  </tr>
                </thead>
                <tbody>
                  {cotizacion.map((c, index) => (
                    <tr key={c.id}>
                      {c.descripcion.map((producto) => (
                        <React.Fragment>
                          <td>
                            <LazyLoad>
                              <img
                                src={`${basepath}/media/${producto.imagen}`}
                                //src={c.imagen_url}
                                //src={`${DummyURL}${defaultimage}`}
                                alt={`${basepath}/media/${producto.imagen}`}
                              />
                            </LazyLoad>
                          </td>

                          <td>{producto.nombre_comercial}</td>
                          <td>{producto.envase}</td>
                          <td>{c.cantidad}</td>
                          <td>
                          <Currency groupSeparator= ',' decimalSeparator= '.' allowDecimals={true} decimalsLimit={2}>
                              {c.precio}
                            </Currency>
                          </td>
                        </React.Fragment>
                      ))}

                      <td>
                      <Currency
                          groupSeparator= ','
                          decimalSeparator= '.'
                          allowDecimals={true}
                          decimalsLimit={2}>
                          {c.total}
                        </Currency>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <hr />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                }}
              >
                <div
                  className="ps-section__cart-actions"
                  style={{ display: "flex" }}
                >
                  <Link to="/cotizaciones" style={{backgroundColor: "#2e3d50", color: "white"}} className="ps-btn ps-btn--gray mr-5">
                    Regresar
                  </Link>
                </div>
                <div
                  className="ps-container"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h3>Precio total: </h3>
                  <h3 className="pl-2" style={{ color: "#FCB800" }}>
                  <Currency groupSeparator= ',' decimalSeparator= '.' allowDecimals={true} decimalsLimit={2}>
                      {_total}
                    </Currency>
                  </h3>
                </div>
              </div>
            </form>
          </div>
        )}

        {encabezado.editable ? (
          <Modal
            width={1200}
            title={[]}
            visible={this.state.status}
            onOk={this.closeModal}
            onCancel={this.closeModal}
            footer={[
              <button
                type="reset"
                className="ps-btn ps-btn"
                onClick={this.closeModal}
              >
                Cerrar
              </button>,
            ]}
          >
            <LoadMask loading={cotizacion_loading} blur={true}>
                <ContizacionInvalidModal
                    cotizacion_invalid={cotizacion_invalid}
                    invalid={invalid}
                    cotizacion={cotizacion}
                    encabezado={encabezado}
                    handleUpdateCotizacion={this.handleUpdateCotizacion}
                    closeModal={this.closeModal}
                    _total={_total}
                    />
            </LoadMask>
          </Modal>
        ) : null}
      </div>
    );
  }
}

const ms2p = (state) => {
  return state.cotizacion;
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(CotizacionDetalle);
