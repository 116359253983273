import React, { Component } from 'react';
import DefaultHeader from '../layout/Header/DefaultHeader'
import { connect } from "react-redux";
import LoadMask from "../Utils/LoadMask/LoadMask";
import CardItem from './CardItem';
import UserImage from  '../../../../assets/img/usuario.png'
import BancoImage from  '../../../../assets/img/banco.png'
import FacturaImage from  '../../../../assets/img/cuenta.png'
import TiendaImage from  '../../../../assets/img/tienda.png'
import MapImage from  '../../../../assets/img/map.png'
import PasswordImage from  '../../../../assets/img/password.png'
import MultiUserImage from '../../../../assets/img/usuarios.png'








class Perfil extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalVisible: false,
            loader: true
        }; 
    }

    timer = null;

    showModal = () => {
        this.setState({isModalVisible:true})
      };

    handleOk = () => {
        this.setState({isModalVisible:false})
      };
    
    handleCancel = () => {
    this.setState({isModalVisible:false})
    };
    
    componentDidMount(){
        this.handleLoader()


    }

    handleLoader = () => {
        setTimeout(()=> this.setState({loader:false}),1000);
    }
    



render() {
        const {me} = this.props.login
    return (
        <div>
            <DefaultHeader title="Perfil" visible={true}/>
        {this.state.loader?
        <LoadMask loading={this.state.loader} dark>
        
        </LoadMask>
        :
            <div>   
            <hr/>
            <section>
            <div className="container" style={{display:'flex' , flexWrap:"wrap" , justifyContent:"space-around"}}>
                {me.tipo_usuario === 20?
                <React.Fragment>
                <div className="ps-block--form-box2">
                    <CardItem 
                        image={TiendaImage}
                        title="Empresa"
                        description="Información de la tienda y datos relacionados"
                        text_button="Ver"
                        url='/tienda'
                    />
                </div>

                <div className="ps-block--form-box2">
                    <CardItem
                        image={MultiUserImage}
                        title="Usuarios"
                        description="Usuarios que pueden administrar la tienda"
                        text_button="Ver" 
                        url="/asociados"
                    />
                </div>
                <div className="ps-block--form-box2">
                    <CardItem 
                        image={BancoImage}
                        title="Cuentas Bancarias"
                        description=" Cuentas de banco vinculadas para los pagos"
                        text_button="Ver"
                        url='/cuentas-bancarias'
                    />
                </div>

                <div className="ps-block--form-box2">
                    <CardItem 
                        image={FacturaImage}
                        title="Datos de facturación"
                        description="tus datos de facturación"
                        text_button="Ver"
                        url='datos-facturacion'
                    />
                </div>
                </React.Fragment>
                :null}

                <div className="ps-block--form-box2">
                    <CardItem 
                        image={MapImage}
                        title="Sucursales"
                        description="Información sobre las direcciones de las tiendas para entrega"
                        text_button="Ver"
                        url='/ubicaciones'
                    />
                </div>

                <div className="ps-block--form-box2">
                    <CardItem 
                        image={PasswordImage}
                        title="Credenciales de acceso"
                        description="cambiar datos de acceso"
                        text_button="Ver"
                        url='datos-acceso'
                    />
                </div>
                
            </div>
            </section>
        </div>
        }
        </div>
            );
        }
}



const ms2p = state => ({...state})

const md2p = {};
  
export default connect(ms2p, md2p)(Perfil);
