import React, { Component } from 'react';
import LoadMask from "../../Utils/LoadMask/LoadMask";
import { actions } from '../../../../redux/modules/cuenta/login';
import { Form, Input } from 'antd';
import { connect } from 'react-redux';
import FooterWidgets from '../../shared/footer/FooterWidgets';
import FooterCopyright from '../../shared/footer/FooterCopyright';


class RecoveryForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
        };
    }

    componentDidMount(){
        this.handleLoader()

    }


    handleLoader = () => {
        setTimeout(()=> this.setState({loader:false}),1000);
    }


    handleLoginSubmit = e => {
        this.props.password_recovery(e);
    };



    render() {
        return (
        <React.Fragment>
            <div className="register-photo">
                            {this.loader === true ?
                            <div className="ps-tab-list">
                            <LoadMask loading={this.state.loader} dark>
                            
                            </LoadMask>
                            </div>
                            :
                            null
                            }
                <div className="form-container">
                    {/* CAMBIAR ESTE LINK EN PRODUCION */}
                    <a href='https://test127.ciancoders.com/' className="image-holder" id="register-login"></a>
                    <Form
                            onFinish={this.handleLoginSubmit.bind(this)}>
                            <div className="ps-form--account template-content" id="password-recovery">
                                <div>
                                    <h1>Recupera tu cuenta</h1>
                                    <p className='text-sec'>Para poder recuperar tu cuenta necesitamos que proporciones los siguientes datos:</p>
                                    <div className="form-group mt-3">
                                        <Form.Item
                                            name="usuario"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Por favor ingresa este campo!',
                                                },
                                            ]}>
                                            <Input
                                                className="form-control"
                                                type="text"
                                                placeholder="Ingrea tu usuario o correo electronico"
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="form-group submit">
                                        <button
                                            type="submit"
                                            className="ps-btn ps-btn--fullwidth p-4">
                                            Recuperar
                                        </button>
                                    </div>
                                    <div className="text-center">
                                        <a style={{ color: '#0066c0' }} className='secondary' href="#">Aviso de privacidad</a>
                                    </div>
                                </div>
                                <div className="ps-form__footer">
                                </div>
                            </div>
                        </Form>
                </div>
            </div>
            <footer className="ps-footer">
                <div className="ps-container">
                    <FooterWidgets />
                    <FooterCopyright />
                </div>
            </footer>
        </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return state.login;
};

const md2p = {...actions};


export default connect(mapStateToProps, md2p)(RecoveryForm);
