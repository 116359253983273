import React from 'react';
import { Link } from 'react-router-dom';
import webPayIMG from '../../../../../assets/img/web_pay.jpg';


const FooterCopyright = () => (
        <div className="ps-footer__copyright ps-block-mg">
        <p>
            &copy; 2021 agromall con todos los derechos reservados
            
        </p>
        <p> 
            <span>Pago seguro por medio de:</span>
            <Link to="#">
                <a>
                    <img src={webPayIMG} style={{width:"100px"}}/>
                </a>
            </Link>
        </p>
        </div>
);

export default FooterCopyright;
