import React, { Component } from "react";
import { connect } from "react-redux";
import { getCalifications } from "../../../redux/modules/rating/rating";
import Rating from "@material-ui/lab/Rating";
import ProgressBar from "@ramonak/react-progress-bar";

class ListRating extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valor: "",
      params: "/?page=1&limit=5",
    };
  }

  getRange = (inicio, final) => {
    let rango = [];
    for (let i = inicio; i < final + 1; i++) {
      rango.push(i);
    }
    return rango;
  };

  searchRating = async (e) => {
    this.setState({ valor: e.target.value }, () => {
      this.paginator(1, 0);
    });
  };

  paginator = (page, pagina_actual) => {
    if (page != pagina_actual) {
      let params = "/?";
      if (this.state.valor) {
        params = params + "search=" + this.state.valor + "&";
      }
      params = params + `page=${page}&limit=5`;
      this.setState({ params }, () => {
        this.props.getCalifications(this.state.params);
      });
    }
  };

  async componentDidMount() {
    await this.props.getCalifications(this.state.params);
  }

  render() {
    const response = this.props.rating.calificaciones;
    const estadistica = response.estadistica;
    const calificaciones = response.datos;
    const total = response.meta ? response.meta.page.total : 0;
    const anterior = response.links ? response.links.previos : 0;
    const primera = response.links ? response.links.first : 0;
    const ultima = response.links ? response.links.last : 0;
    const pagina_actual = response.meta ? response.meta.page.current_page : 0;
    const rango = this.getRange(primera, ultima);
    return (
      <div>
        <div className="container">
          <div className="row mb-5">
            <div className="col-12">
              <h3 className="fw-b fs-22">Calificaciones</h3>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-6">
              {estadistica ? (
                <div align="center">
                  <h2 style={{ fontWeight: "bold" }}>{estadistica.rate}</h2>
                  <Rating
                    precision={0.5}
                    name="rating"
                    size="large"
                    value={parseFloat(estadistica.rate)}
                    readOnly
                  />
                  <h5>Calificaciones: {estadistica.rated_count}</h5>
                </div>
              ) : null}
            </div>
            <div className="col-6">
              <div className="card" style={{ width: "30rem" }}>
                <ul className="list-group list-group-flush">
                  {estadistica && estadistica.datos.length
                    ? estadistica.datos.map((e, index) => (
                        <li key={index} className="list-group-item">
                          <div className="row">
                            <div className="col-3">
                              <span>
                                {e.rate} <i className="fa fa-star"></i>
                              </span>
                            </div>
                            <div className="col-7">
                              <ProgressBar
                                labelColor="#010911"
                                labelAlignment="outside"
                                bgColor="#1890ff"
                                completed={
                                  (
                                    (e.count * 100) /
                                    estadistica.total
                                  ).toFixed(2) + "%"
                                }
                              />
                            </div>
                            <div className="col-2">{e.count}</div>
                          </div>
                        </li>
                      ))
                    : null}
                </ul>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="col-12">
              <nav className="navbar navbar-light bg-light justify-content-between">
                <a className="navbar-brand">Calificacion(es) {total}</a>
                <div className="form-inline">
                  <input
                    className="form-control mr-sm-2"
                    type="text"
                    placeholder="Buscar..."
                    aria-label="Search"
                    value={this.state.valor}
                    onChange={(e) => this.searchRating(e, pagina_actual)}
                  />
                </div>
              </nav>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="list-group">
                {calificaciones && calificaciones.length ? (
                  calificaciones.map((calificacion, index) => (
                    <a
                      key={index}
                      className="list-group-item list-group-item-action"
                      aria-current="true"
                    >
                      <div className="d-flex w-100 justify-content-between">
                        <h5>
                          <strong>Usuario:</strong>{" "}
                          {calificacion.usuario.first_name}{" "}
                          {calificacion.usuario.last_name}
                        </h5>
                        <p>
                          <Rating
                            precision={0.5}
                            name="rating"
                            value={parseFloat(calificacion.rate)}
                            readOnly
                            size="large"
                          />
                          {calificacion.rate}
                        </p>
                      </div>
                      {calificacion.descripcion ? (
                        <p className="mb-1">
                          <strong>Comentario:</strong>{" "}
                          {calificacion.descripcion}
                        </p>
                      ) : null}
                      <h5 style={{ marginTop: "15px" }}>Preguntas:</h5>
                      <ul>
                        {calificacion.tienda_pregunta.map((element, index) => (
                          <li key={index}>
                            <p>
                              {element.pregunta}{" "}
                              <Rating
                                precision={0.5}
                                name="rating"
                                value={parseFloat(element.rate)}
                                readOnly
                              />{" "}
                              {calificacion.rate}
                            </p>
                          </li>
                        ))}
                      </ul>
                    </a>
                  ))
                ) : (
                  <div>No hay datos.</div>
                )}
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12 d-flex justify-content-center">
              {calificaciones && calificaciones.length > 0 ? (
                <nav aria-label="Page navigation example">
                  <ul className="pagination">
                    <li
                      className={`page-item ${anterior == 0 ? "disabled" : ""}`}
                    >
                      <a
                        className="page-link"
                        onClick={() => this.paginator(anterior, pagina_actual)}
                      >
                        Anterior
                      </a>
                    </li>
                    {rango.map((e, index) => (
                      <li
                        key={index}
                        className={`page-item ${
                          pagina_actual == e ? "active" : ""
                        }`}
                      >
                        <a
                          className="page-link"
                          onClick={() => this.paginator(e, pagina_actual)}
                        >
                          {e}
                        </a>
                      </li>
                    ))}
                    <li
                      className={`page-item ${
                        pagina_actual == ultima ? "disabled" : ""
                      }`}
                    >
                      <a
                        className="page-link"
                        onClick={() => this.paginator(ultima, pagina_actual)}
                      >
                        Siguiente
                      </a>
                    </li>
                  </ul>
                </nav>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const ms2p = (state) => {
  return state;
};

const md2p = { getCalifications };

export default connect(ms2p, md2p)(ListRating);
