import React, { Component } from "react";
import { Drawer } from "antd";
import { connect } from "react-redux";
import "../../../../../style/scss/custom/HeaderMobile.module.scss";
import { Link } from "react-router-dom";
import { menu } from "../Sidebar/Menu";

class HeaderMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuDrawer: false,
      seleccionado: "/",
      estado: "1",
    };
  }

  componentDidMount() {
    this.handleInit();
  }

  handleInit = () => {
    const get_ruta = location.href.split("/");
    let route = get_ruta[get_ruta.length - 1];
    this.setState({ seleccionado: `${route}` });
  };

  handleSetActive = (ruta) => {
    this.setState({ seleccionado: `${ruta.url}`, menuDrawer: false });
  };

  handleShowMenuDrawer = () => {
    this.setState({
      menuDrawer: !this.state.menuDrawer,
    });
  };

  handleDrawerClose = () => {
    this.setState({
      menuDrawer: false,
    });
  };

  render() {
    return (
      <header className="header--mobile noprint" media="print">
        <Drawer
          className="ps-panel--mobile noprint header__top"
          placement="right"
          closable={false}
          onClose={this.handleDrawerClose}
          visible={this.state.menuDrawer}
        >
          <div className="ps-panel--wrapper ">
            <div className="ps-panel__header p-20 m-20 row">
              <div className="col-8 d-flex justify-content-end">
                <h3>Menú</h3>
              </div>
              <div className="col-4 d-flex justify-content-end">
                <button
                  className="btn btn-default"
                  style={{ fontSize: "15px" }}
                  onClick={this.handleDrawerClose}
                >
                  <i className="icon-cross"></i>
                </button>
              </div>
            </div>
            <div className="ps-panel__content">
              <ul className="menu">
                {menu.map((item, index) => (
                  <li
                    key={index}
                    className={
                      this.state.seleccionado === item.url ? "active" : ""
                    }
                  >
                    <Link
                      to={item.url}
                      onClick={this.handleSetActive.bind(this, item)}
                    >
                      <i className={item.icon}></i>
                      {item.text}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Drawer>
        <div className="header__left noprint" media="print">
          <button
            className="ps-drawer-toggle"
            onClick={this.handleShowMenuDrawer}
          >
            <i>
              <img
                src={require("../../../../../assets/icons/list-solid.svg")}
                className="icon-w30"
                alt=""
              />
            </i>
          </button>
        </div>
        <div className="header__center"></div>
        <div className="header__right noprint" media="print">
          <a className="header__site-link" href="#">
            <i>
              <img
                src={require("../../../../../assets/icons/door-open-solid.svg")}
                className="icon-w30"
                alt=""
              />
            </i>
          </a>
        </div>
      </header>
    );
  }
}

const mapStateToProps = (state) => {
  return { setting: state.setting };
};
export default connect(mapStateToProps)(HeaderMobile);
