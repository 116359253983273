import React, { useState, useEffect } from "react";
import { Table, Modal } from "antd";
import { connect } from "react-redux";
import { actions } from "../../../../redux/modules/productos/productos";
import ProductForm from "../Form";
import UpdateForm from "../UpdateForm";

const TableProductosLocal = (props) => {
  const { categorias, familias } = props;

  const [value, setValue] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [producto_img, setImagen] = useState(null);
  const [isModalVisible, setModal] = useState(false);
  const [modalNew, setModalNew] = useState(false);
  const [producto, setInfo] = useState([]);

  const [Ok, setOk] = useState(false);
  const [Cancel, setCancel] = useState(false);

  const setFile = (imagen) => {
    setImagen(imagen);
  };

  const handleSend = (data) => {
    props.createProducto(data);
    CloseModal();
  };

  const showModal = () => {
    setModal(true);
  };

  const handleNuevoProducto = () => {
    setModalNew(true);
  };

  const handleOk = () => {
    setOk(false);
    CloseModal();
  };

  const CloseModal = () => {
    setCancel(false);
    setModal(false);
    setModalNew(false);
    //setImagen(null);
    setInfo([]);
  };

  const handleSelect = (product) => {
    setInfo(product);
    showModal();
  };

  const opt_categorias = [];
  const opt_familias = [];

  {
    categorias.length > 0
      ? categorias.map((option, index) =>
          opt_categorias.push({
            value: option.categoria,
            text: option.categoria,
          })
        )
      : null;
  }

  {
    familias.length > 0
      ? familias.map((option, index) =>
          opt_familias.push({ value: option.nombre, text: option.nombre })
        )
      : null;
  }

  const columns = [
    {
      title: "Nombre del producto",
      key: "id",
      render: (record) => <p>{record.nombre_comercial}</p>,
    },
    {
      title: "Concentración",
      render: (record) => <p>{record.concentracion}</p>,
    },
    {
      title: "Envase / Unidad medida",
      render: (record) => (
        <p>
          {record.envase} - {record.unidad_medida_text}
        </p>
      ),
    },
    {
      title: "Tipo de formulación",
      render: (record) => <p>{record.tipo_formulacion}</p>,
    },
    {
      title: () => <b>Familia</b>,
      filters: opt_familias,
      onFilter: (value, record) => record.familia === value,
      render: (record) => <p>{record.familia_texto}</p>,
    },
    {
      title: () => <b>Categoría</b>,
      filters: opt_categorias,
      onFilter: (value, record) => record.categoria === value,
      render: (record) => <p>{record.categoria_texto}</p>,
    },
    {
      title: "-",
      render: (record) => (
        <React.Fragment>
          <button className="ps-btn" onClick={() => handleSelect(record)}>
            +
          </button>
        </React.Fragment>
      ),
    },
  ];

  useEffect(() => {
    setDataSource(props.info);
  },[]);

  return (
    <div>
      <div className="form-group">
        {props.styled ? (
          <React.Fragment>
            <section className="ps-dashboard" style={{ alignItems: "center" }}>
              <div className="d-flex justify-content-between flex-row-reverse">
                <div className="col-8 pl-0 d-flex justify-content-end">
                  <div className="ps-form--search-simple">
                    <input
                      placeholder="Buscar (Nombre del producto)"
                      className="form-control"
                      value={value}
                      onChange={(e) => {
                        const currValue = e.target.value;
                        setValue(currValue);
                        const filteredData = props.info.filter((entry) =>
                          entry.nombre_comercial
                            .toLowerCase()
                            .includes(currValue.toLowerCase())
                        );
                        setDataSource(filteredData);
                      }}
                    />
                    <button>
                      <i className="icon icon-magnifier" />
                    </button>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <button className="ps-btn" onClick={handleNuevoProducto}>
                      Crear nuevo +
                    </button>
                  </div>
                  <div>{/* button here */}</div>
                </div>
              </div>
            </section>
            <hr />

            <Table
              dataSource={dataSource}
              columns={columns}
              className="table ps-table--market"
            />
          </React.Fragment>
        ) : (
          <Table dataSource={dataSource} columns={columns} />
        )}
      </div>
      <Modal
        title=""
        width={800}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={CloseModal}
        footer={[]}
      >
        <UpdateForm
          initialValues={producto}
          onSubmit={handleSend}
          imagen = {producto_img}
          setImagen={setFile}
          producto={producto}
          categorias={categorias}
          familias={familias}
        />
      </Modal>
      <Modal
        title=""
        width={800}
        visible={modalNew}
        onOk={handleOk}
        onCancel={CloseModal}
        footer={[]}
      >
        <ProductForm
          onSubmit={handleSend}
          setFile={setFile}
          categorias={categorias}
          familias={familias}
        />
      </Modal>
    </div>
  );
};

const md2p = { ...actions };

export default connect(null, md2p)(TableProductosLocal);
