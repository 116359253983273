export const menu = [
    {
        "text": "Dashboard",
        "url": "/",
        "icon": "icon-home",
        "id": "1"
    },
    {
        "text": "Billetera",
        "url": "/billetera",
        "icon": "icon-wallet",
        "id": "2"
    },
    {
        "text": "Cotizaciones",
        "url": "/cotizaciones",
        "icon": "icon-ticket",
        "id": "3"
    },
    {
        "text": "Actividad",
        "url": "/notificaciones",
        "icon": "icon-notification",
        "id": "4"
    },
    {
        "text": "Catálogo",
        "url": "/catalogo",
        "icon": "icon-database",
        "id": "5"

    },
    {
        "text": "Pedidos en proceso",
        "url": "/en-proceso",
        "icon": "icon-check-square",
        "id": "6"

    },
    {
        "text": "Devoluciones",
        "url": "/devoluciones",
        "icon": "icon-return",
        "id": "9"
    },
    {
        "text": "Calificaciones",
        "url": "/rating/list",
        "icon": "icon-check",
        "id": "8"
    },
    {
        "text": "Perfil",
        "url": "/perfil",
        "icon": "icon-cog",
        "id": "7"
    }
];