import React, { Component } from "react";
import DefaultHeader from "../../layout/Header/DefaultHeader";
import { connect } from "react-redux";
import { actions } from "../../../../redux/modules/productos/productos";
import LoadMask from "../../Utils/LoadMask/LoadMask";
import TableProductosLocal from "./TableProductosLocal";

class ProductoLocal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
      isModalRequested: false,
      isModalPaid: false,
      loader: true,
    };
  }

  componentDidMount() {
    this.handleLoader();
    this.props.getProductosLocales();
    this.props.getCategorias();
    this.props.getFamilias();
  }

  showModal = () => {
    this.setState({ isModalVisible: true });
  };

  showModalRequested = () => {
    this.setState({ isModalRequested: true });
  };

  showModalPaid = () => {
    this.setState({ isModalPaid: true });
  };

  handleOk = () => {
    this.setState({ isModalVisible: false });
    this.setState({ isModalRequested: false });
    this.setState({ isModalPaid: false });
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false });
    this.setState({ isModalRequested: false });
    this.setState({ isModalPaid: false });
  };

  handleSend = (data) => {
    this.props.onSubmitSolicitud(data);
  };

  handleLoader = () => {
    setTimeout(() => this.setState({ loader: false }), 1000);
  };

  render() {
    return (
      <div>
        <DefaultHeader title="Agregar un producto" visible={true} />
        {this.state.loader ? (
          <LoadMask loading={this.state.loader} dark></LoadMask>
        ) : (
          <div>
            {this.props.productos.productos ? (
              <div className="container">
                <ul>
                  <li>
                    Busca dentro del catalogo del marketplace el producto que
                    deseas vender.
                  </li>
                  <li>
                    Si el producto que quieres vender no aparece en la lista{" "}
                    <a>crea uno nuevo</a>.
                  </li>
                  <li>
                    Todos los productos nuevos deben ser aprobados por los
                    administradores del sitio asi que puede que tome un tiempo
                    para publicarse.
                  </li>
                </ul>
                <hr />
                <div className="ps-card">
                  {this.props.productos.productos.length > 0 ? (
                    <div>
                      <TableProductosLocal
                        info={this.props.productos.productos}
                        categorias={this.props.productos.categorias}
                        familias={this.props.productos.familias}
                        styled={true}
                      />
                    </div>
                  ) : (
                    <p>En este momento no hay productos</p>
                  )}
                </div>
              </div>
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

const ms2p = (state) => {
  return state;
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ProductoLocal);
