import React, { Component } from "react";
import DefaultHeader from "../layout/Header/DefaultHeader";
import { Modal } from "antd";
import { Currency } from "react-intl-number-format/dist/index.js";
import { connect } from "react-redux";
import { actions } from "../../../redux/modules/solicitudes/solicitudes";
import { getBancos } from "../../../redux/modules/bancos/bancos";
import RetiroForm2 from "./RetiroForm2";
import LoadMask from "../Utils/LoadMask/LoadMask";
import TableSolicitud from "./TableSolicitud";
import TableActividad from "./TableActividad";
import { api } from '../../../utility/api'

class Billetera extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
      isModalRequested: false,
      isModalPaid: false,
      loader: true,
      error: false,
      ventas: [],
      loading: true,
      comisionData: [],
    };
  }
  calculateComisiones = () => {
    let data = {id_cotizacion: []}
    this.state.ventas.map((venta) =>{
        data.id_cotizacion.push(venta.cotizacion)
    })
    api.post(`/categorias-feeds-commissions/calcule_comisions`, data)
        .then((res) => {
            this.setState({comisionData: res})
        })
        .catch((err) => {
            console.log(err);
        })
        .finally(() => {
            this.setState({loading: false})
        });
  };
  componentDidMount() {
    this.handleLoader();
    this.props.getBalance();
    this.props.getSolicitudes();
    this.props.getVentasPorCobrar();
    this.props.getBancos();
    this.calculateComisiones();
  }

  showModal = () => {
    this.setState({ isModalVisible: true });
  };

  showModalRequested = () => {
    this.setState({ isModalRequested: true });
  };

  showModalPaid = () => {
    this.setState({ isModalPaid: true });
  };

  handleOk = () => {
    this.setState({ isModalVisible: false });
    this.setState({ isModalRequested: false });
    this.setState({ isModalPaid: false });
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false });
    this.setState({ isModalRequested: false });
    this.setState({ isModalPaid: false });
  };

  handleSend = (data) => {
    this.props.onSubmitSolicitud(data);
  };

  handleLoader = () => {
    setTimeout(() => {
      this.setState({ loader: false });
      this.setState({ ventas: this.props.solicitudes.ventas.ventas });
    }, 1500);
  };

  render() {
    let cuentas;
    {
      this.props.bancos.cuentas.length > 0
        ? (cuentas = this.props.bancos.cuentas)
        : (cuentas = []);
    }

    return (
      <div>
        <DefaultHeader title="Tu dinero" visible={true} />
        {this.state.loader ? (
          <LoadMask loading={this.state.loader} dark></LoadMask>
        ) : (
          <div>
            {this.props.solicitudes.data ? (
              <section className="container">
                <div className="row w-100 d-flex justify-content-md-between">
                  <div className="col-12 col-md-3">
                    {/* WALLET CARD*/}
                    <div>
                      <div className="ps-block--form-box2">
                        <div className="ps-block__content">
                          <div className="ps-block__left">
                            <img
                              src={require("../../../../assets/icons/wallet-solid.svg")}
                              height="75px"
                              alt="Wallet"
                            />
                          </div>
                          <h4 style={{ textAlign: "center" }}>
                            En tu billetera
                          </h4>
                          <h3 style={{ textAlign: "center", color: "green" }}>
                            <Currency locale="es-CL" currency="CLP">
                              {this.props.solicitudes.balance.balance}
                            </Currency>
                          </h3>
                          <hr />
                          {this.props.solicitudes.balance.balance <= 0 ? (
                            <button
                              className="btn ps-btn--gray"
                              style={{ fontWeight: "bold" }}
                              disabled
                            >
                              Solicitar un retiro
                            </button>
                          ) : (
                            <button
                              onClick={this.showModal}
                              className="ps-btn"
                              style={{ fontWeight: "bold" }}
                            >
                              Solicitar un retiro
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-3">
                    {/* WALLET CARD*/}
                    <div>
                      <div className="ps-block--form-box2">
                        <div className="ps-block__content">
                          <div className="ps-block__left">
                            <img
                              src={require("../../../../assets/icons/depositar.svg")}
                              height="75"
                              alt="Wallet"
                            />
                          </div>
                          <h4 style={{ textAlign: "center" }}>Por liberar</h4>
                          <h3 style={{ textAlign: "center", color: "blue" }}>
                            <Currency locale="es-CL" currency="CLP">
                              {this.props.solicitudes.data.pendiente
                                ? this.props.solicitudes.data.pendiente
                                    .pendiente
                                : 0}
                            </Currency>
                          </h3>
                          <hr />

                          <button
                            onClick={this.showModalRequested}
                            className="ps-btn"
                            style={{ fontWeight: "bold" }}
                          >
                            Ver solicitudes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-3">
                    {/* WALLET CARD*/}
                    <div>
                      <div className="ps-block--form-box2">
                        <div className="ps-block__content">
                          <div className="ps-block__left">
                            <img
                              src={require("../../../../assets/icons/withdrawal.svg")}
                              height="75"
                              alt="Wallet"
                            />
                          </div>
                          <h4 style={{ textAlign: "center" }}>Retirado</h4>
                          <h3 style={{ textAlign: "center", color: "red" }}>
                            <Currency locale="es-CL" currency="CLP">
                              {this.props.solicitudes.data.retirado
                                ? this.props.solicitudes.data.retirado.retirado
                                : 0}
                            </Currency>
                          </h3>
                          <hr />
                          <button
                            onClick={this.showModalPaid}
                            className="ps-btn"
                            style={{ fontWeight: "bold" }}
                          >
                            Ver historial
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            ) : null}

            <div className="container">
              <hr />
              <h3>Movimientos</h3>
              <div className="ps-card">
                <TableActividad info={this.props.solicitudes.data.todas} />
              </div>
            </div>
          </div>
        )}

        <Modal
          width={800}
          title=""
          visible={this.state.isModalVisible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <button
              type="reset"
              className="ps-btn ps-btn"
              onClick={this.handleCancel}
            >
              Cerrar
            </button>,
          ]}
        >
          <RetiroForm2
            comisionDataProps={this.state.comisionData}
            loadingProps={this.state.loading}
            onSubmit={this.handleSend}
            ventas={this.state.ventas}
            iva={this.props.solicitudes.ventas.iva}
            cuentas={cuentas}
          />
        </Modal>
        <Modal
          width={800}
          title="Ultimas 10 solicitudes"
          visible={this.state.isModalRequested}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[]}
        >
          <TableSolicitud
            info={this.props.solicitudes.data.por_liberar}
            styled={false}
          />
        </Modal>
        <Modal
          width={800}
          title="Ultimos 10 retiros"
          visible={this.state.isModalPaid}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[]}
        >
          <TableSolicitud
            info={this.props.solicitudes.data.pagadas}
            styled={false}
          />
        </Modal>
      </div>
    );
  }
}

const ms2p = (state) => {
  return state;
};

const md2p = { ...actions, getBancos };

export default connect(ms2p, md2p)(Billetera);
