import React, { Component } from "react";
import { connect } from "react-redux";
import { getDevoluciones, updateDevoluciones } from "../../../redux/modules/devolucion/devolucion";
import LoadMask from "../Utils/LoadMask/LoadMask";

class ListDevoluciones extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valor: "",
      params: "/?page=1&limit=10",
      loader: true,
    };
  }

  componentDidMount() {
    this.handleLoader();
  }

  handleLoader = () => {
    setTimeout(() => {
      this.setState({ loader: false });
      this.props.getDevoluciones(this.state.params);
    }, 1000);
  };

  getRange = (inicio, final) => {
    let rango = [];
    for (let i = inicio; i < final + 1; i++) {
      rango.push(i);
    }
    return rango;
  };

  searchDevolucion = async (e) => {
    this.setState({ valor: e.target.value }, () => {
      this.paginator(1, 0);
    });
  };

  paginator = (page, pagina_actual) => {
    if (page != pagina_actual) {
      let params = "/?";
      if (this.state.valor) {
        params = params + "search=" + this.state.valor + "&";
      }
      params = params + `page=${page}&limit=10`;
      this.setState({ params }, () => {
        this.props.getDevoluciones(this.state.params);
      });
    }
  };

  markAsReturn(devolucion) {
    const data = {
        cantidad: devolucion.cantidad,
        cotizacion_producto: devolucion.id,
        devuelto: !devolucion.devuelto,
        id: devolucion.id,
        precio: parseFloat(devolucion.precio)
    }
    this.props.updateDevoluciones(data);
    this.setState({loader: true}, () => {
        this.handleLoader();
    });
  }

  render() {
    const response = this.props.devolucion.devoluciones;
    const devoluciones = response.data;
    const total = response.meta ? response.meta.page.total : 0;
    const anterior = response.links ? response.links.previos : 0;
    const primera = response.links ? response.links.first : 0;
    const ultima = response.links ? response.links.last : 0;
    const pagina_actual = response.meta ? response.meta.page.current_page : 0;
    const rango = this.getRange(primera, ultima);
    return (
      <div>
        <div className="container">
          <div className="row mb-5">
            <div className="col-12">
              <h3 className="fw-b fs-22">Devoluciones</h3>
            </div>
          </div>
          {this.state.loader ? (
            <LoadMask loading={this.state.loader} dark></LoadMask>
          ) : (
            <React.Fragment>
              <div className="row d-flex justify-content-center">
                <div className="col-12">
                  <nav
                    className="navbar justify-content-between"
                    style={{
                      backgroundColor: "#f8f9fa",
                      marginTop: "5px",
                    }}
                  >
                    <a className="navbar-brand">Devolucion(es) {total}</a>
                    <div className="ps-form--search-simple">
                      <input
                        placeholder="Buscar..."
                        className="form-control"
                        value={this.state.valor}
                        onChange={(e) =>
                          this.searchDevolucion(e, pagina_actual)
                        }
                      ></input>
                      <button>
                        <i className="icon icon-magnifier" />
                      </button>
                    </div>
                  </nav>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <table className="table custom--table">
                    <thead>
                      <tr
                        className="table-active"
                        style={{ fontSize: "20px", fontWeight: "bold" }}
                      >
                        <th scope="col">Cotización</th>
                        <th scope="col">Producto</th>
                        <th scope="col">Cantidad</th>
                        <th scope="col">Precio</th>
                        <th scope="col">Total</th>
                        <th scope="col">Devuelto</th>
                      </tr>
                    </thead>
                    <tbody>
                      {devoluciones ? (
                        devoluciones.length > 0 ? (
                          devoluciones.map((element, index) => (
                            <tr key={index}>
                              <td scope="row">
                                {element.cotizacion_producto.cotizacion.id}
                              </td>
                              <td>
                                {
                                  element.cotizacion_producto.producto
                                    .nombre_comercial
                                }
                              </td>
                              <td>{element.cantidad}</td>
                              <td>{element.precio} CLP</td>
                              <td>{element.total} CLP</td>
                              {/* <td>{element.devuelto ? "Si" : "No"}</td> */}
                              <td>
                                <div className="form-check form-switch">
                                    <input
                                      checked={element.devuelto}
                                      onChange={() => this.markAsReturn(element)}
                                      className="form-check-input"
                                      type="checkbox"
                                    />
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <th
                              colSpan="4"
                              style={{
                                textAlign: "center",
                              }}
                            >
                              No hay datos.
                            </th>
                          </tr>
                        )
                      ) : (
                        <tr>
                          <th
                            colSpan="4"
                            style={{
                              textAlign: "center",
                            }}
                          >
                            No hay datos.
                          </th>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12 d-flex justify-content-center">
                  {devoluciones && devoluciones.length > 0 ? (
                    <nav aria-label="Page navigation example">
                      <ul className="pagination">
                        <li
                          className={`page-item ${
                            anterior == 0 ? "disabled" : ""
                          }`}
                        >
                          <a
                            className="page-link"
                            onClick={() =>
                              this.paginator(anterior, pagina_actual)
                            }
                          >
                            &laquo;
                          </a>
                        </li>
                        {rango.map((e, index) => (
                          <li
                            key={index}
                            style={{
                              margin: "0px 25px",
                            }}
                            className={`page-item ${
                              pagina_actual == e ? "active" : ""
                            }`}
                          >
                            <a
                              className="page-link"
                              onClick={() => this.paginator(e, pagina_actual)}
                            >
                              {e}
                            </a>
                          </li>
                        ))}
                        <li
                          className={`page-item ${
                            pagina_actual == ultima ? "disabled" : ""
                          }`}
                        >
                          <a
                            className="page-link"
                            onClick={() =>
                              this.paginator(ultima, pagina_actual)
                            }
                          >
                            &raquo;
                          </a>
                        </li>
                      </ul>
                    </nav>
                  ) : null}
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

const ms2p = (state) => {
  return state;
};

const md2p = { getDevoluciones, updateDevoluciones };

export default connect(ms2p, md2p)(ListDevoluciones);
