import { handleActions } from 'redux-actions'
import Swal from 'sweetalert2'
import { push } from 'react-router-redux'
import { initialize as initializeForm } from 'redux-form'
import { NotificationManager } from 'react-notifications'
import { api } from 'api'
import { notification } from 'antd'
import { getProductos } from '../productos/productos'

const actionTypes = {
  SEND_SOLICITUD: 'SEND_SOLICITUD',
  SET_SOLICITUDES: ' SET_SOLICITUDES',
  SET_BALANCE: 'SET_BALANCE',
  SET_VENTAS: 'SET_VENTAS'

}

// ------------------------------------
// Pure Actions
// ------------------------------------

const modalSuccessAny = (type, message, description) => {
  notification[type]({
    message: message,
    description: description,
    duration: 2
  })
}

const SwalAlert = (title, text, type) => {
  Swal.fire({
    width: '400px',
    heightAuto: true,
    title: title,
    text: text,
    type: type

  })
}

export const setLoader = loader => ({
  type: actionTypes.LOADER,
  loader
})

export function sendSolicitud (payload) {
  return {
    type: actionTypes.SEND_SOLICITUD,
    payload
  }
}

export function setSolicitudes (data) {
  return {
    type: actionTypes.SET_SOLICITUDES,
    data
  }
};

export const getSolicitudes = () => (dispatch) => {
  api.get('solicitud/requested').then(data => {
    dispatch(setSolicitudes(data))
  })
    .catch(() => {
    }).finally(() => {})
}

export function setBalance (balance) {
  return {
    type: actionTypes.SET_BALANCE,
    balance
  }
}

export function setVentasPorCobrar (ventas) {
  return {
    type: actionTypes.SET_VENTAS,
    ventas
  }
}

// ------------------------------------
// Actions
// ------------------------------------

export const onSubmitSolicitud = (data) => (dispatch) => {
  api.post('solicitud', data).then((response) => {
    dispatch(sendSolicitud())
    dispatch(push('/billetera'))
    const title = 'Enviado'
    const text = 'Estamos procesando tu solicitud. Tus fondos pueden tardar hasta  2 días hábiles en liberarse.'
    const type = 'success'
    SwalAlert(title, text, type)
  }).catch((error) => {
    const title = 'Error'
    const text = 'Parece que hubo un error al enviar la solicitud, intenta más tarde'
    const type = 'error'
    SwalAlert(title, text, type)
  }).finally(() => {
    window.location.reload()
  })
}

export const getBalance = () => (dispatch) => {
  api.get('/usuarios/balance').then(balance => {
    dispatch(setBalance(balance))
  })
    .catch(() => {
    }).finally(() => {})
}

export const example = () => (dispatch) => {
  const title = 'Enviado'
  const text = 'Estamos procesando tu solicitud. Tus fondos pueden tardar hasta  2 días hábiles en liberarse.'
  const type = 'error'
  SwalAlert(title, text, type)
}

export const getVentasPorCobrar = () => (dispatch) => {
  api.get('solicitud/ventas_pagadas').then(ventas => {
    dispatch(setVentasPorCobrar(ventas))
  })
    .catch(() => {
    }).finally(() => {})
}

export const updateProducto = (data) => (dispatch) => {
  api.post('solicitud/activacion_producto', data).then((response) => {
    dispatch(sendSolicitud())
    modalSuccessAny('success', 'Completado', '')
    dispatch(getProductos())
  }).catch((error) => {
    let mensaje
    if (error) {
      mensaje = error.detail
    } else {
      mensaje = 'Hubo un error'
    }
    modalSuccessAny('error', 'Error', mensaje)
  }).finally(() => {
  })
}

export const actions = {
  onSubmitSolicitud,
  getSolicitudes,
  getBalance,
  getVentasPorCobrar,
  example,
  updateProducto
}

export const reducers = {
  [actionTypes.SET_SOLICITUDES]: (state, { data }) => {
    return {
      ...state,
      data
    }
  },
  [actionTypes.SEND_SOLICITUD]: (state) => {
    return {
      ...state
    }
  },
  [actionTypes.SET_BALANCE]: (state, { balance }) => {
    return {
      ...state,
      balance
    }
  },
  [actionTypes.SET_VENTAS]: (state, { ventas }) => {
    return {
      ...state,
      ventas
    }
  }

}

export const initialState = {
  loader: false,
  data: {},
  balance: ''

}

export default handleActions(reducers, initialState)
