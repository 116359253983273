import React from 'react'
import PropTypes from 'prop-types'
import DropZone from 'react-dropzone'
import ImagePreview from './ImagePreview.js'
import Placeholder from './placeholder'
import ShowError from './showerrors'

const DropZoneField = ({
  handleOnDrop,
  input: { onChange },
  imagefile,
  meta: { error, touched }

}) => (
  <div className='ps-tab active' id='register'>

    <div className='ps-form__content'>
      <div className='preview-container'>
        <DropZone
          accept='image/jpeg, image/png, image/gif, image/bmp'
          className='upload-container'
          multiple={false}
          maxFiles={1}
          maxSize={2048000}
          onDrop={file => handleOnDrop(file, onChange)}
        >
          {({ getRootProps, getInputProps }) =>
            imagefile && imagefile.length > 0 ? (
              <ImagePreview imagefile={imagefile} />
            ) : (
              <Placeholder
                error={error}
                touched={touched}
                getInputProps={getInputProps}
                getRootProps={getRootProps}
              />
            )}
        </DropZone>
        <ShowError error={error} touched={touched} />
      </div>
    </div>
  </div>

)

DropZoneField.propTypes = {
  error: PropTypes.string,
  handleOnDrop: PropTypes.func.isRequired,
  imagefile: PropTypes.arrayOf(
    PropTypes.shape({
      file: PropTypes.file,
      name: PropTypes.string,
      preview: PropTypes.string,
      size: PropTypes.number
    })
  ),
  label: PropTypes.string,
  onChange: PropTypes.func,
  touched: PropTypes.bool
}

export default DropZoneField
