import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'

import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles({
  root: {
    maxWidth: 345
  }
})

const CardItem = (props) => {
  return (
    <Card style={{ minHeight: '350px',minWidth:"300px", display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"space-between" }}>
      <CardContent>
        <CardMedia
          style={{ objectFit: 'contain' }}
          className='image-material-card'
          component='img'
          alt='Tienda'
          image={props.image}
          title='Tienda'
        />
        <div style={{ textAlign: 'center' }}>
          <h4>{props.title}</h4>
          <p>{props.description}</p>
        </div>
      </CardContent>
      <CardActions style={{ justifyContent: 'center' }}>
        <div className='pt-0'>
          <Link to={props.url} className='ps-btn'>
            Ver
          </Link>
        </div>
      </CardActions>
    </Card>
  )
}

export default CardItem
