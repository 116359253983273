import React from 'react'

const AsociadoDisable = (props) => {
  const { usuario, handleSend, closeModal } = props

  return (
    <div className='container'>
      <div className='pb-50 mb-50'>
        <h4 style={{ textAlign: 'center' }}>¿Estás seguro que desea eliminar este usuario?</h4>
        <p style={{ textAlign: 'center' }}> <b>Nota:</b> Esta acción no se puede deshacer.</p>
      </div>
      <div className='d-flex justify-content-around'>
        <button className='ps-btn ps-btn--gray' onClick={closeModal}>Cancelar</button>
        <button className='ps-btn' onClick={e => { handleSend(e, usuario) }}>Si, Guardar</button>

      </div>
    </div>
  )
}

export default AsociadoDisable
