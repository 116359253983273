import React, { Component } from "react";
import DefaultHeader from "../../layout/Header/DefaultHeader";
import { connect } from "react-redux";
import LoadMask from "../../Utils/LoadMask/LoadMask";
import FacturacionForm from "./FacturacionForm";
import { updateTienda } from "../../../../redux/modules/cuenta/login";
import { Link } from "react-router-dom";

import { NotificationManager } from 'react-notifications'

import Geocode from 'react-geocode';

Geocode.setApiKey('AIzaSyCc7uFE7m8XJ17HcYQrXPF2SiTXysfDmss')
Geocode.setLanguage('en')
Geocode.setRegion('es')
Geocode.setLocationType('ROOFTOP')

class Facturacion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
      loader: true,
    };
  }

  timer = null;

  showModal = () => {
    this.setState({ isModalVisible: true });
  };

  handleOk = () => {
    this.setState({ isModalVisible: false });
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };

  componentDidMount() {
    this.handleLoader();
  }

  handleLoader = () => {
    setTimeout(() => this.setState({ loader: false }), 1000);
  };

  handleSend = (data) => {
    Geocode.fromAddress(data.fact_direccion).then((response) => {
      const { lat, lng } = response.results[0].geometry.location;
      data.longitud = lng;
      data.latitud = lat;
      this.props.updateTienda(data);
    },
    (error) => {
      NotificationManager.error("Dirección no válida, por favor verifiquela.", 'ERROR')
    }
    );

    
  };

  render() {
    return (
      <div>
        <DefaultHeader title="Datos de facturación" visible={true} />
        {this.state.loader ? (
          <LoadMask loading={this.state.loader} dark></LoadMask>
        ) : (
          <div>
            <Link
              to={`/perfil`}
              style={{ backgroundColor: "#2e3d50", color: "white" }}
              className="ps-btn"
            >
              Regresar
            </Link>
            <hr />
            <section style={{display: 'flex', justifyContent: 'center'}}>
              <div
                className=""
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-around",
                }}
              >
                <FacturacionForm
                  handleSend={this.handleSend}
                  tienda={this.props.login.me.tienda}
                />
              </div>
            </section>
          </div>
        )}
      </div>
    );
  }
}

const ms2p = (state) => ({ ...state });

const md2p = { updateTienda };

export default connect(ms2p, md2p)(Facturacion);
