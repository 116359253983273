import React from "react";

export const InformacionProducto = (props) => {
  const { producto } = props;
  if (producto.descripcion) {
    return (
      <div className="card text-center" style={{ width: "100%" }}>
        <div className="pt-20 pb-20">
          <img src={producto.imagen_url}/>
        </div>
        <table className="table">
          <tr style={{ backgroundColor: "#6799c5" }}>
            <td colSpan="2" style={{ fontWeight: "bold" }}>
              Informacion del producto
            </td>
          </tr>
          <tr>
            <td style={{ fontWeight: "bold" }}>Familia:</td>
            <td>{producto.descripcion.familia}</td>
          </tr>          
          <tr>
            <td style={{ fontWeight: "bold" }}>Categoría:</td>
            <td>{producto.descripcion.categoria}</td>
          </tr>
          <tr>
            <td style={{ fontWeight: "bold" }}>Nombre comercial:</td>
            <td>{producto.nombre_comercial}</td>
          </tr>
          <tr>
            <td style={{ fontWeight: "bold" }}>Marca:</td>
            <td>{producto.marca}</td>
          </tr>
          <tr>
            <td style={{ fontWeight: "bold" }}>Envase:</td>
            <td>
              {producto.envase} {producto.unidad_medida_texto}
            </td>
          </tr>
          <tr>
            <td style={{ fontWeight: "bold" }}>Ingrediente activo:</td>
            <td>{producto.descripcion.sustancia_activa}</td>
          </tr>
          <tr>
            <td style={{ fontWeight: "bold" }}>Concentración:</td>
            <td>{producto.descripcion.concentracion_sustancia_activa}</td>
          </tr>
          <tr>
            <td style={{ fontWeight: "bold" }}>Tipo de formulación:</td>
            <td>{producto.descripcion.tipo_formulacion}</td>
          </tr>
        </table>
      </div>
    );
  }else{
      return(
          <p>Cargando...</p>
      )
  }
};
