import React, { Component } from 'react';
import DefaultHeader from '../../layout/Header/DefaultHeader'
import { connect } from "react-redux";
import LoadMask from "../../Utils/LoadMask/LoadMask";
import { Link } from 'react-router-dom';
import TableAsociados from './TableAsociados';
import {actions} from '../../../../redux/modules/cuenta/profile'


class Asociados extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalVisible: false,
            loader: true
        }; 
    }

    timer = null;

    showModal = () => {
        this.setState({isModalVisible:true})
      };

    handleOk = () => {
        this.setState({isModalVisible:false})
      };
    
    handleCancel = () => {
    this.setState({isModalVisible:false})
    };
    
    componentDidMount(){
        this.handleLoader()
        this.props.getAsociados()
    }

    handleLoader = () => {
        setTimeout(()=> this.setState({loader:false}),1000);
    }
    



render() {
    return (
        <div className="container">
            <DefaultHeader title="Usuarios asociados" visible={true}/>
            {this.state.loader?
                <LoadMask loading={this.state.loader} dark/>
                :
                <div className="container">
                    <TableAsociados />
                </div>
            }
        </div>
            );
        }
}



const ms2p = state => ({...state})

const md2p = {...actions};
  
export default connect(ms2p, md2p)(Asociados);
