import { handleActions } from "redux-actions";
import { push } from "react-router-redux";
import { initialize as initializeForm } from "redux-form";
import { NotificationManager } from "react-notifications";
import Swal from "sweetalert2";
import { api } from "api";
import { notification } from "antd";

const actionTypes = {
  BEST_SELLER: "BEST_SELLER",
  SET_PRODUCTOS: "SET_PRODUCTOS",
  SET_PRODUCTOS_LOCALES: "SET_PRODUCTOS_LOCALES",
  SET_CATEGORIAS_AP: "SET_CATEGORIAS_AP",
  SET_CATEGORIA: "SET_CATEGORIA",
  SET_FAMILIA_AP: "SET_FAMILIA_AP",
  SET_FAMILIAS: "SET_FAMILIAS",
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = (loader) => ({
  type: actionTypes.LOADER,
  loader,
});

const modalSuccessAny = (type, message, description) => {
  notification[type]({
    message: message,
    description: description,
    duration: 1,
  });
};

const SwalAlert = (title, text, type) => {
  Swal.fire({
    width: "400px",
    heightAuto: true,
    title: title,
    text: text,
    type: type,
  });
};

export function serBestSeller(best_seller) {
  return {
    type: actionTypes.BEST_SELLER,
    best_seller,
  };
}

export function setMisProductos(mis_productos) {
  return {
    type: actionTypes.SET_PRODUCTOS,
    mis_productos,
  };
}

export function setProductosLocales(productos) {
  return {
    type: actionTypes.SET_PRODUCTOS_LOCALES,
    productos,
  };
}

export function setCategoriasAP(categorias_ap) {
  return {
    type: actionTypes.SET_CATEGORIAS_AP,
    categorias_ap,
  };
}

export function setFamiliasAP(familias_ap) {
  return {
    type: actionTypes.SET_FAMILIA_AP,
    familias_ap,
  };
}

export function setFamilias(familias) {
  return {
    type: actionTypes.SET_FAMILIAS,
    familias,
  };
}

export function setCategoria(categorias) {
  return {
    type: actionTypes.SET_CATEGORIA,
    categorias,
  };
}

const cleanForm = () => (dispatch) => {
  dispatch(initializeForm("producto_form", {}));
};

// ------------------------------------
// Actions
// ------------------------------------

export const getBestSeller = (data) => (dispatch) => {
  api
    .post("/cotizaciones/productos/cotizacion/best_seller", data)
    .then((best_seller) => {
      dispatch(serBestSeller(best_seller));
    })
    .catch(() => {})
    .finally(() => {});
};

export const getProductos = () => (dispatch) => {
  api
    .get("/productos/productos/mis_productos/")
    .then((mis_productos) => {
      dispatch(setMisProductos(mis_productos));
    })
    .catch(() => {})
    .finally(() => {});
};

export const getProductosLocales = () => (dispatch) => {
  api
    .get("/productos/productos/productos_locales")
    .then((productos) => {
      dispatch(setProductosLocales(productos));
    })
    .catch(() => {})
    .finally(() => {});
};

export const getCategoriasAP = () => (dispatch) => {
  api
    .get("productos/productos/categorias_ap")
    .then((categorias_ap) => {
      dispatch(setCategoriasAP(categorias_ap));
    })
    .catch(() => {})
    .finally(() => {});
};

export const getFamiliasAP = () => (dispatch) => {
  api
    .get("productos/productos/familias_ap")
    .then((familias_ap) => {
      dispatch(setFamiliasAP(familias_ap));
    })
    .catch(() => {
      modalSuccessAny("error", "Hubo un error al obtener las familias");
    })
    .finally(() => {});
};

export const getFamilias = () => (dispatch) => {
  api
    .get("productos/familias/familias")
    .then((familias) => {
      dispatch(setFamilias(familias));
    })
    .catch(() => {
      modalSuccessAny("error", "Hubo un error al obtener las familias");
    })
    .finally(() => {});
};

export const getCategorias = () => (dispatch) => {
  api
    .get("productos/categorias/categorias")
    .then((categorias) => {
      dispatch(setCategoria(categorias));
    })
    .catch(() => {
      modalSuccessAny("error", "Hubo un error al obtener las familias");
    })
    .finally(() => {});
};

export const createProducto = (data) => (dispatch) => {
  api
    .post("/productos/productos", data)
    .then((response) => {
      const title = "Enviado";
      const text =
        "Tu producto se ha enviado para la aprobación. Te notificaremos cuando este listo.";
      const type = "success";
      dispatch(cleanForm());
      dispatch(push("/catalogo"));
      SwalAlert(title, text, type);
    })
    .catch((error) => {
      console.log(error, 'aqui');
      let mensaje = "";
      if (error.detail) {
        mensaje = `${error.detail}. *Campo error: ${error.field}`;
        modalSuccessAny("error", "Error", mensaje);
      }
    })
    .finally(() => {});
};

export const importProducts = (data) => (dispatch) => {
  api
    .post("productos/productos/sync_productos", data)
    .then((response) => {
      dispatch(push("#/catalogo"));
    })
    .catch(() => {})
    .finally(() => {});
};

export const updateProduct = (data) => (dispatch) => {
  api
    .put("productos/productos/update_product", data)
    .then((response) => {
      modalSuccessAny(
        "success",
        "Completado",
        "El producto se actualizo correctamente."
      );
      dispatch(getProductos());
      dispatch(push("#/catalogo"));
    })
    .catch((error) => {
      modalSuccessAny("error", "Hubo un error", "");
    })
    .finally(() => {});
};

export const deleteProduct = (data) => (dispatch) => {
  api
    .post("productos/productos/soft_delete", data)
    .then(() => {
      modalSuccessAny(
        "success",
        "Completado",
        "El producto fue eliminado correctamente."
      );
      dispatch(getProductos());
    })
    .catch((err) => {console.log(err); modalSuccessAny("error", "Hubo un error", "")});
};

export const actions = {
  getBestSeller,
  createProducto,
  getProductos,
  getProductosLocales,
  getFamiliasAP,
  getCategoriasAP,
  getFamilias,
  getCategorias,
  importProducts,
  deleteProduct,
};

export const reducers = {
  [actionTypes.BEST_SELLER]: (state, { best_seller }) => {
    return {
      ...state,
      best_seller,
    };
  },
  [actionTypes.SET_PRODUCTOS]: (state, { mis_productos }) => {
    return {
      ...state,
      mis_productos,
    };
  },
  [actionTypes.SET_PRODUCTOS_LOCALES]: (state, { productos }) => {
    return {
      ...state,
      productos,
    };
  },
  [actionTypes.SET_CATEGORIAS_AP]: (state, { categorias_ap }) => {
    return {
      ...state,
      categorias_ap,
    };
  },
  [actionTypes.SET_FAMILIA_AP]: (state, { familias_ap }) => {
    return {
      ...state,
      familias_ap,
    };
  },
  [actionTypes.SET_FAMILIAS]: (state, { familias }) => {
    return {
      ...state,
      familias,
    };
  },
  [actionTypes.SET_CATEGORIA]: (state, { categorias }) => {
    return {
      ...state,
      categorias,
    };
  },
};

export const initialState = {
  loader: false,
  best_seller: [],
  mis_productos: [],
  productos: [],
  familias: [],
  categorias_ap: [],
  categorias: [],
  familias_ap: [],
};

export default handleActions(reducers, initialState);
