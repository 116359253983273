import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { actions } from '../../../../redux/modules/cuenta/login'
import profile from '../../../../redux/modules/cuenta/profile'
import LazyLoad from 'react-lazyload'
import { api, basepath } from '../../../../utility/api'

const WidgetUserWelcome = (props) => {
  const handleLogout = (e) => {
    props.logOut()
  }

  const { me } = props

  if (props.login) {
    return (
      <div className='ps-block--user-wellcome'>
        <div className='ps-block__left'>
          <img src={require('../../../../../assets/img/admin.jpg')} alt='' />
        </div>
        <div className='ps-block__right'>
          <p>
            Hola!,<a href='#'>{me.empresa_nombre}</a>
          </p>
        </div>
        {/*
        <div className='ps-block__action icon-w10'>
          <Link to='#' onClick={handleLogout}>
            <a>
              <i className='icon-exit' />
            </a>
          </Link>
          {// <span className="fs-12">Salir</span> }
        </div>
        */}
      </div>
    )
  } else {
    return (
      <div>
        <div className='container'>
          <div className='ps-block--avatar'>
            {me.tienda.logo
              ? <LazyLoad>
                <img
                  src={`${me.tienda.logo}`}
                                // src={c.imagen_url}
                                // src={`${DummyURL}${defaultimage}`}
                  alt={`${me.tienda.logo}`}
                />
              </LazyLoad>
              : <img src={require('../../../../../assets/img/admin.jpg')} alt='' />}
          </div>
          <br />
        </div>
        <div>
          <div className='ps-block__right'>
            <h4 style={{ textAlign: 'center' }}>
              {me.first_name} {me.last_name}
            </h4>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return state.login
}

const md2p = { ...actions }

export default connect(mapStateToProps, md2p)(WidgetUserWelcome)
