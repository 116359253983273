import { handleActions } from 'redux-actions'
import Swal from 'sweetalert2'
import { push } from 'react-router-redux'
import { initialize as initializeForm } from 'redux-form'
import { NotificationManager } from 'react-notifications'
import { useHistory } from 'react-router-dom'
import { api } from 'api'
import { notification } from 'antd'
import { getProductos } from '../productos/productos'

const actionTypes = {
  SEND_UBICACION: 'SEND_UBICACION',
  SET_UBICACION: ' SET_UBICACION'
}

// ------------------------------------
// Pure Actions
// ------------------------------------

const modalSuccessAny = (type, message, description) => {
  notification[type]({
    message: message,
    description: description,
    duration: 1
  })
}

const SwalAlert = (title, text, type) => {
  Swal.fire({
    width: '400px',
    heightAuto: true,
    title: title,
    text: text,
    type: type

  }) 
}

const cleanUbicacionForm = () => (dispatch) => {
  dispatch(initializeForm('ubicaciones_form', {}))
}

export function sendSolicitud (payload) {
  return {
    type: actionTypes.SEND_UBICACION,
    payload
  }
}

export function setUbicaciones (ubicaciones) {
  return {
    type: actionTypes.SET_UBICACION,
    ubicaciones
  }
};

export const getUbicaciones = () => (dispatch) => {
  api.get('ubicaciones/mis_ubicaciones').then(ubicaciones => {
    dispatch(setUbicaciones(ubicaciones))
  })
    .catch(() => {
    }).finally(() => {})
}

// ------------------------------------
// Actions
// ------------------------------------

export const agregarUbicacion = (data) => (dispatch) => {
  api.post('ubicaciones', data).then((response) => {
    dispatch(sendSolicitud())
    dispatch(push('/ubicaciones'))
    dispatch(getUbicaciones())
    dispatch(cleanUbicacionForm())
    const title = 'Completado'
    const text = 'Se agrego la direccion para las entregas de productos'
    const type = 'success'
    SwalAlert(title, text, type)
    // window.location.reload();
  }).catch((error) => {
    const title = 'Ha ocurrido un error'
    const text = 'Parece que hubo un error al enviar la solicitud, intenta más tarde'
    const type = 'error'
    SwalAlert(title, text, type)
  }).finally(() => {
  })
}

export const actualizar = (data) => (dispatch) => {
  api.put(`/ubicaciones/${data.id}`, data).then((response) => {
    dispatch(sendSolicitud())
    dispatch(push('/ubicaciones'))
    dispatch(getUbicaciones())
    const title = 'Completado'
    const text = 'Se actualizó la dirección'
    const type = 'success'
    SwalAlert(title, text, type)
    // window.location.reload();
  }).catch((error) => {
    const title = 'Error'
    const text = 'Parece que hubo un error al enviar la solicitud, intenta más tarde'
    const type = 'error'
    SwalAlert(title, text, type)
  }).finally(() => {
  })
}

export const estadoUbicacion = (data) => (dispatch) => {
  api.post('ubicaciones/actualizar_estado', data).then((response) => {
    dispatch(sendSolicitud())
    dispatch(getUbicaciones())
    modalSuccessAny("success" , "Completado" , "Se actualizó el estado correctamente")
  }).catch((error) => {
    const title = 'Error'
    const text = 'Parece que hubo un error al enviar la solicitud, intenta más tarde'
    const type = 'error'
    SwalAlert(title, text, type)
  }).finally(() => {
  })
}

export const actions = {
  getUbicaciones,
  agregarUbicacion,
  estadoUbicacion,
  actualizar
}

export const reducers = {
  [actionTypes.SET_UBICACION]: (state, { ubicaciones }) => {
    return {
      ...state,
      ubicaciones
    }
  },
  [actionTypes.SEND_UBICACION]: (state) => {
    return {
      ...state
    }
  }

}

export const initialState = {
  loader: false,
  ubicaciones: {}

}

export default handleActions(reducers, initialState)
