import React, { useState } from 'react'
import { Field, reduxForm } from 'redux-form'
import { renderCurrencyCLP } from '../Utils/renderField/index'

const RetiroForm = (props) => {
  const { handleSubmit, balance } = props

  const [error, SetError] = useState(false)

  const handleRead = (e) => {
    const current = e.target.value
    const parse = Number(current.replace(/[^0-9-]+/g, ''))
    if (parse > balance) {
      SetError(true)
    } else {
      SetError(false)
    }
  }

  return (
    <div>
      <div className='container'>

        <h3 style={{ textAlign: 'center' }}>Realiza una solicitud para retirar el dinero de tu billetera digital.</h3>
        <hr />
        <p>Las solicitudes de retiro tienen un periodo de tiempo entre 24 a 48 horas en ser liberadas.</p>

      </div>
      <form onSubmit={handleSubmit}>
        <div className='form-group'>
          <label>Confirma el monto que deseas retirar</label>
          <hr />
          <label>Monto del retiro (CLP)<sup>*</sup></label>

          <Field
            name='monto_solicitado'
            component={renderCurrencyCLP}
            placeholder='Cantidad (CLP)'
            onChange={handleRead}
          />
          {error === true
            ? <div>
              <p style={{ color: 'red' }}>El monto es mayor al que tiene actualmente. Intenta de nuevo</p>
              <div className='form-group'>
                <button type='submit' className='ps-btn ps-btn--gray' disabled={error === true}>Enviar solicitud</button>
              </div>
            </div>
            : <div className='form-group'>
              <br />
              <button type='submit' className='ps-btn' disabled={error === true}>Enviar solicitud</button>
            </div>}
        </div>
      </form>
    </div>
  )
}

export default reduxForm({
  form: 'solicitud_retiro', // a unique identifier for this form
  destroyOnUnmount: false // <------ preserve form data

})(RetiroForm)
