import { combineReducers } from 'redux'
import { routerReducer as routing } from 'react-router-redux'
import { reducer as formReducer } from 'redux-form'
import login from './modules/cuenta/login'
import register from './modules/cuenta/register'
import profile from './modules/cuenta/profile'
import usuarios from './modules/usuarios/usuarios'
import bancos from './modules/bancos/bancos'
import cotizacion from './modules/cotizaciones/cotizacion'
import notificaciones from './modules/notificaciones/notificaciones'
import productos from './modules/productos/productos'
import solicitudes from './modules/solicitudes/solicitudes'
import ubicaciones from './modules/ubicaciones/ubicaciones'
import rating from './modules/rating/rating';
import devolucion from './modules/devolucion/devolucion'

export default combineReducers({
  form: formReducer,
  login,
  register,
  bancos,
  profile,
  usuarios,
  routing,
  notificaciones,
  cotizacion,
  productos,
  solicitudes,
  ubicaciones,
  rating,
  devolucion
})
