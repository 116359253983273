import React, { Component } from 'react'
import { Link } from "react-router-dom";
import {api} from '../../../utility/api'
import { Currency } from "react-intl-number-format/dist/index.js";
import DefaultHeader from '../layout/Header/DefaultHeader'
import {Table , Tag , Input} from 'antd';
import LoadMask from "../Utils/LoadMask/LoadMask";
import { CSVLink } from "react-csv";



import moment from 'moment'


class  Ventas extends Component{
    constructor(props) {
        super(props);
        this.state = {
            cotizaciones : [],
            dataSource: [],
            seleccionado: '/en-proceso',
            entregadas: false,
            estado:'9',
            value: '',
            hasta:moment().format(),
            desde: moment().clone().startOf('month').format('YYYY-MM-DD'),
            loader: true,

        };
    }



    handleSetFilter = () => {
        var inicio = new Date(this.state.desde).getTime()
        var final = new Date(this.state.hasta).getTime()

        var resultData = this.state.cotizaciones.filter( obj =>{
            var parse = obj.fecha_limite.slice(0,10).split('-');
            parse = parse[0] + '-' + parse[2] + '-' + parse[1]
            var time = new Date(parse).getTime();
            return (inicio < time && time <= final);
        });
        this.setState({dataSource:resultData});
    }


    componentDidMount(){
        this.handleLoader();
        this.getCotizaciones(this.state.estado);
    }

    getCotizaciones = async(estado) =>{
        const reponse = api.get(`/cotizaciones/cotizaciones_tagged/?estado=${estado}`).then((reponse) =>{
            this.setState({cotizaciones:reponse})
            if(estado===12){
                this.setState({entregadas:true})
            }
            this.setState({dataSource:reponse})

        })
    .catch((error) => {
        {error: JSON.stringify(error)};
        this.setState({cotizaciones:[]})
        });
    return reponse;
    };




    handleSetActive = (ruta) => {
    this.setState({cotizaciones:[]})
    this.setState({dataSource:[]})
    this.setState({seleccionado:`${ruta.url}`})
    let estado = ruta.id
    this.getCotizaciones(estado)
    }
    handleLoader = () => {
        setTimeout(()=> this.setState({loader:false}),1000);
    }

    render(){
    let coti = this.state.cotizaciones
    let entregadas = this.state.entregadas
    const get_ruta = location.href.split("/");
    let route = "/" + get_ruta[get_ruta.length - 1];
    let keyword = route.split("#")

    const enbacezado_csv = [
        {label:"Venta", key:"id"},
        {label:"Cliente", key:"cliente"},
        {label:"Fecha de entrega", key:"f_entrega"},
        {label:"Retiro billetera", key:"cobrada"},
        {label:"Monto", key:"monto_pagado.pagado"},
        {label:"Fecha del retiro", key:"fecha_retiro"},

    ]

    const menuItems = [
        {
        text: `Por despachar`,
        url: '/en-proceso',
        icon: 'icon-home',
        id:'9'

        },
        {
        text: 'Para entregar',
        url: '/en-proceso#listas',
        icon: 'icon-database',
        id:'11'
        },
        {
        text: 'Entregadas',
        url: '/en-proceso#entregadas',
        icon: 'icon-database',
        id:'12'
        },
        ];

        const estados = [
            {"id":0 , "nombre":"-", "color":"default"},
            {"id":1 , "nombre":"RECIBIDA", "color":"orange"},
            {"id":2 , "nombre":"ENVIADA", "color":"processing"},
            {"id":3 , "nombre":"EXPIRADA", "color":"error"},
            {"id":4 , "nombre":"FINALIZADA", "color":"success"},
            {"id":5 , "nombre":"APROBADA-PARCIAL", "color":"yellow"},
            {"id":6 , "nombre":"APROBADA-TOTAL", "color":"processing"},
            {"id":7 , "nombre":"PAGO PENDIENTE", "color":"orange"},
            {"id":8 , "nombre":"FINALIZADA-NO PAGO", "color":"error"},
            {"id":9 , "nombre":"PAGADA", "color":"blue"},
            {"id":10 , "nombre":"PAGADA", "color":"blue"},
            {"id":11 , "nombre":"LISTA PARA ENTREGAR", "color":"green"},
            {"id":12 , "nombre":"ENTREGADA", "color":"error"},

        ]



        const columns = [
            {
                title: 'ACCIONES',
                key: 'id',
                dataIndex: 'id',
                render: (id) =>(
                <React.Fragment>
                    <Link to={`venta/${id}`} className="ps-btn">
                        Ver
                </Link>
                </React.Fragment>
                )
              },

            {
                title: 'Cotizacion',
                dataIndex: 'id',
                key: 'id',
                width: '20%',
                render: (id) => (
                    `No. ${id}`
                ),
                sorter: (a, b) => a.id - b.id,
                showSorterTooltip: false,
              },
            {
                title: 'Cliente',
                dataIndex: 'cliente',
                key: '',
              },
              {
                title: "Fecha Limite",
                dataIndex: "fecha_limite",
                key: "fecha_limite",
                sorter: (a, b) =>
                  moment(a.fecha_limite).unix() - moment(b.fecha_limite).unix(),
                showSorterTooltip: false,
              },
              {
                title: 'Sucursal de entrega',
                key: 'id',
                width: '20%',
                render: (record) => (
                    `${record.direccion_entrega.direccion_entrega__nombre} (${record.direccion_entrega.direccion_entrega__direccion})`
                ),
              },
              {
                title: 'Cobrada',
                dataIndex: 'cobrada',
                key: 'cobrada',
                width: '10%',
                sorter: (a, b) => a.cobrada.localeCompare(b.cobrada),
                showSorterTooltip: false,
              },
              {
                title: 'Monto venta',
                key: 'monto_pagado',
                width: '20%',
                render: (record) => (
                    <Currency locale="es-CL" currency="CLP">{record.monto_pagado.pagado}</Currency>
                ),
              },
              {
                title: 'Estado',
                dataIndex: 'estado',
                key: 'id',
                render: estado=>(
                    <span>
                    <Tag color={estados[estado].color}>
                        {estados[estado].nombre.toUpperCase()}
                    </Tag>
                </span>
                )
              },
          ];



    return(
        <div>
            <DefaultHeader title="En proceso" visible={true}/>
            {this.state.loader?
            <LoadMask loading={this.state.loader} dark/>
                  :
            <div>
                <hr></hr>
                <div className="container">
                <ul className="menu-horizontal" >
                    {menuItems.map((item, index) => (
                        <li key={index} className={this.state.seleccionado === item.url ? 'active' : ''}>
                    <Link to={item.url} onClick={this.handleSetActive.bind(
                                                    this,
                                                    item
                                                )}>
                            {item.text}
                            {this.state.seleccionado === item.url ?
                            `(${this.state.cotizaciones.length})`
                             :
                             null}

                    </Link>
                        </li>
                    ))}
                </ul>
                <div style={{display:"flex", justifyContent:"space-between" , alignItems:"center"}}>



                <div>
                    <button className="ps-btn xs" onClick={this.handleSetFilter}>Filtrar</button>
                </div>

                <div>
                <CSVLink
                    data={this.state.dataSource}
                    headers={enbacezado_csv}
                    filename={`entregado_${moment().format('L')}.csv`}
                    className="btn btn-success ml-3">
                        <i className="icon-file-empty"></i>
                </CSVLink>
                </div>
                <div className="col-3">
                    <label htmlFor="estados">Desde:</label>
                <input
                type="date"
                className="form-control"
                name="fecha_i"
                value={this.state.desde}
                onChange={e => {
                    const currValue = e.target.value;
                    this.setState({desde:currValue});
                }}
                />

                <hr/>
                </div>
                <div className="col-3">
                    <label htmlFor="estados">Hasta:</label>
                <input
                type="date"
                className="form-control"
                name="fecha_f"
                value={this.state.hasta}
                onChange={e => {
                    const currValue = e.target.value;
                    this.setState({hasta:currValue});
                }}

                />
                <hr/>
                </div>
                <div className="ps-form--search-simple">
                    <input
                    placeholder="Buscar..."
                    className="form-control"
                        value={this.state.value}
                        onChange={e => {
                            const currValue = e.target.value;
                            this.setState({value:currValue})
                            const filteredData = this.state.cotizaciones.filter(entry =>
                            entry.cliente.toLowerCase().includes(currValue.toLowerCase())
                            );
                            this.setState({dataSource:filteredData});
                        }}
                    >
                    </input>
                    <button><i className="icon icon-magnifier"></i></button>
                </div>
                </div>
                <Table
                    className="ps-table--market"
                    columns={columns}
                    dataSource={this.state.dataSource}
                />


                </div>
            </div>
        }
        </div>
    );
}

};

export default Ventas;
