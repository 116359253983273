import React from 'react';
import { Link } from 'react-router-dom';

import LogoAgromall from '../../../../../assets/img/logo.png'


const FooterThank = () => (
        <div className="ps-footer__copyright ps-block-mg justify-content-center flex-column">
        <p> 
            <span > Si necesitas ayuda, escríbenos a <b>contacto@agromall.cl</b></span>
            
        </p>
        <Link to="/">
                <a>
                    <img src={LogoAgromall} style={{width:"100px"}}/>
                </a>
        </Link>
        </div>
);

export default FooterThank;
