import React, { Component } from 'react';
import {api} from '../../../utility/api'
import DefaultHeader from '../layout/Header/DefaultHeader'
import LoadMask from "../Utils/LoadMask/LoadMask";
import TableProductos from './TableProductos'
import { connect } from "react-redux";
import {getProductos, getFamilias} from '../../../redux/modules/productos/productos'

class Productos extends Component{
    constructor(props) {
        super(props);
        this.state = {

            categorias: [],
            familias: [],
            loader: true,
          };
    }



      componentDidMount() {
        this.handleLoader();
        this.props.getProductos();
        this.getFamilias();
        this.getCategorias();

    }

    handleLoader = () => {
        setTimeout(()=> this.setState({loader:false}),1000);
    }


    getCategorias = async() => {
        const reponse = api.get(`/productos/categorias/all`).then((response) =>{
            this.setState({categorias: response})
        })
        .catch((error) => ({ error: JSON.stringify(error) }));
        return reponse;
        };  

    getFamilias = async() => {
        const reponse = api.get(`/productos/familias/all`).then((response) =>{
            this.setState({familias: response})
        })
        .catch((error) => ({ error: JSON.stringify(error) }));
        return reponse;
        };  




    render(){
        let familias = this.state.familias
        let productos = this.props.productos.mis_productos
        let categorias = this.state.categorias
        const {me} = this.props.login
        return (            
            <div className="container">
            <DefaultHeader title="Catálogo" visible={true}/>

                {this.state.loader?
                    <LoadMask loading={this.state.loader} dark />
                    :
                    <div>
                        <div className="header__center">
                            <div className="row">
                                
                            </div>
                        </div>
                        <div className="container">
                        <TableProductos productos={productos}  
                            categorias={categorias} 
                            familias = {familias}
                            me={me}
                            />    
                        </div>              
                    </div>
                }
            </div>
        );
}
}

const ms2p = (state) => {
    return state;
  };

const md2p = {getProductos , getFamilias}

  
export default connect(ms2p ,md2p)(Productos);
