import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { api } from "../../../utility/api";
import DefaultHeader from "../layout/Header/DefaultHeader";
import { InformacionProducto } from "./InformacionProducto";

const DetalleProducto = (props) => {
    const { id } = props.match.params;
    const [producto, setProducto] = useState({});
    //const [showEdit, setshowEdit] = useState(false);

    const getProduct = async () => {
        const response = await api
            .get(
                `productos/productos/detalle/?producto=${id}`
            )
            .then((producto) => {
                setProducto(producto);
                console.log(producto)
            });
    };

    useEffect(() => {
        getProduct();
    }, []);

    //const showUpdateProduct = () => setshowEdit(!showEdit)

    return (
        <div>
            <DefaultHeader
                title={`Detalle de: ${producto.nombre_comercial}`}
                visible={true}
            />
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between"
                }}
            >
                <div>
                    <Link to="/catalogo" className="ps-btn ps-btn">
                        Regresar
                    </Link>
                </div>
                {/*
                    !showEdit? <div className="ps-btn ps-btn" onClick={showUpdateProduct}>Editar  <i className="icon icon-pencil smaller" /></div>:
                    <div className="ps-btn ps-btn" onClick={showUpdateProduct}>Ocultar Edicion  <i className="icon icon-arrow-down smaller" /></div>
                */}
            </div>
            <hr />
            <InformacionProducto producto={producto} />
        </div>
    );
};

export default DetalleProducto;
