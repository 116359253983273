import React, { Component, useState } from 'react'
import { Table } from 'antd'

const TableBestSeller = (info) => {
  const [dataSource, getDataSource] = useState(info.info)

  const columns = [
    {
      title: 'Producto',
      key: 'producto__nombre_comercial',
      dataIndex: 'producto__nombre_comercial'
    },
    {
      title: 'Cantidad Vendida',
      dataIndex: 'vendidos',
      key: 'vendidos'

    }
  ]

  return (
    <div>
      <div className='form-group'>
        <Table dataSource={dataSource} columns={columns} className='table ps-table--money' />

      </div>
    </div>
  )
}

export default TableBestSeller
