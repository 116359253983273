const provincias = [
  { value: '151', label: 'Arica', region: '15' },
  { value: '152', label: 'Parinacota', region: '15' },
  { value: '011', label: 'Iquique', region: '01' },
  { value: '014', label: 'Tamarugal', region: '01' },
  { value: '021', label: 'Antofagasta', region: '02' },
  { value: '022', label: 'El Loa', region: '02' },
  { value: '023', label: 'Tocopilla', region: '02' },
  { value: '031', label: 'Copiapó', region: '03' },
  { value: '032', label: 'Chañaral', region: '03' },
  { value: '033', label: 'Huasco', region: '03' },
  { value: '041', label: 'Elqui', region: '04' },
  { value: '042', label: 'Choapa', region: '04' },
  { value: '043', label: 'Limarí', region: '04' },
  { value: '051', label: 'Valparaíso', region: '05' },
  { value: '052', label: 'Isla de Pascua', region: '05' },
  { value: '053', label: 'Los Andes', region: '05' },
  { value: '054', label: 'Petorca', region: '05' },
  { value: '055', label: 'Quillota', region: '05' },
  { value: '056', label: 'San Antonio', region: '05' },
  { value: '057', label: 'San Felipe de Aconcagua', region: '05' },
  { value: '058', label: 'Marga Marga', region: '05' },
  { value: '061', label: 'Cachapoal', region: '06' },
  { value: '062', label: 'Cardenal Caro', region: '06' },
  { value: '063', label: 'Colchagua', region: '06' },
  { value: '071', label: 'Talca', region: '07' },
  { value: '072', label: 'Cauquenes', region: '07' },
  { value: '073', label: 'Curicó', region: '07' },
  { value: '074', label: 'Linares', region: '07' },
  { value: '081', label: 'Concepción', region: '08' },
  { value: '082', label: 'Arauco', region: '08' },
  { value: '083', label: 'Biobío', region: '08' },
  { value: '084', label: 'Ñuble', region: '08' },
  { value: '091', label: 'Cautín', region: '09' },
  { value: '092', label: 'Malleco', region: '09' },
  { value: '141', label: 'Valdivia', region: '14' },
  { value: '142', label: 'Ranco', region: '14' },
  { value: '101', label: 'Llanquihue', region: '10' },
  { value: '102', label: 'Chiloé', region: '10' },
  { value: '103', label: 'Osorno', region: '10' },
  { value: '104', label: 'Palena', region: '10' },
  { value: '111', label: 'Coyhaique', region: '11' },
  { value: '112', label: 'Aysén', region: '11' },
  { value: '113', label: 'Capitán Prat', region: '11' },
  { value: '114', label: 'General Carrera', region: '11' },
  { value: '121', label: 'Magallanes', region: '12' },
  { value: '122', label: 'Antártica Chilena', region: '12' },
  { value: '123', label: 'Tierra del Fuego', region: '12' },
  { value: '124', label: 'Última Esperanza', region: '12' },
  { value: '131', label: 'Santiago', region: '13' },
  { value: '132', label: 'Cordillera', region: '13' },
  { value: '133', label: 'Chacabuco', region: '13' },
  { value: '134', label: 'Maipo', region: '13' },
  { value: '135', label: 'Melipilla', region: '13' },
  { value: '136', label: 'Talagante', region: '13' }
]

export default provincias
