import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Field, reduxForm, change } from 'redux-form'
import { connect } from 'react-redux'

// import { validate, validators } from 'validate-redux-form'
import { Link } from 'react-router-dom'
import { renderField, renderRUT, renderupdateField, renderImageUpload, renderTextArea, SelectField, renderTelefonoFormat} from '../../Utils/renderField/renderField'
import DropZoneField from '../../../components/Utils/ImageField/DropField'
import login from '../../../../redux/modules/cuenta/login'
import validate from './validate_tienda_form'
import { basepath } from '../../../../utility/api'

import db_comunas from "../../../database/comunas";
import db_regiones from "../../../database/regiones";
import db_provincias from "../../../database/provincias";

const TiendaForm = (props) => {
  const { handleSubmit, pristine,valid, reset, submitting, change, setAvatar, tienda } = props
  const [imageFile, setImageFile] = useState([])
  const [logo, setLogo] = useState([])
  const [regiones, setRegiones] = useState(db_regiones)
  const [provincias, setProvincias] = useState(db_provincias)
  const [comunas, setComunas] = useState(db_comunas)
  const [filtered_provincias, setFilteredProvincias] = useState([])
  const [filtered_comunas, setFilteredComunas] = useState([])

  const resetForm = () => {
    setImageFile([]),
    change('tienda_logo', '')
  }

  console.log(tienda)
  console.log(tienda.region)

  const [rutValid, setRutValid] = useState(false);
  const handleChangeRut = (rut) => {

    const formato = (rut) => {
		if (!rut | typeof rut !== 'string') return false
		return /^\d{1,2}.\d{3}.\d{3}-[k|K|\d]{1}$/.test(rut)
	}
    // 8,287,562-K
	const digitoVerificador = (rut) => {
		let multiplos = [2,3,4,5,6,7]
        // remplazar las ',' por '.' en el rut
        rut = rut.replace(/\,/g, '.')
        // eliminar los caracteres que no sean numeros
		let digitos = rut.split('-')[0].replace(/\./g,'').split('').reverse()
		let digitoVerificador = rut.split('-')[1].toUpperCase()

		let digito = 11 - digitos.reduce((acc,elem,index)=>(acc+Number(elem)*multiplos[index%multiplos.length]),0)%11

		let digimap = [NaN,'1','2','3','4','5','6','7','8','9','K','0']

		return digimap[digito]===digitoVerificador
	}

	setRutValid (formato(rut) && digitoVerificador(rut))
    }

    const opt_regiones = []
    const opt_provincias = []
    let opt_comunas = []

    { regiones.length > 0
      ? regiones.map((option) => (
          opt_regiones.push({ value: option.id, label: option.nombre })
        )
        )
      : null
    }

    { provincias.length > 0
      ? provincias.map((option) => (
          opt_provincias.push({ value: option.value, label: option.label })
        ))
      : null
    }

    { comunas.length > 0
      ? comunas.map((option) => (
          opt_comunas.push({ value: option.value, label: option.label })
        ))
      : null
    }

    const handleChangeRegion = (e) => {
      const target = `${e[0]}${e[1]}`
      const filteredData = provincias.filter(entry =>
        entry.region.includes(target)
      )
      const region_texto = regiones.find(entry =>
        entry.id === target
      )
      setFilteredProvincias(filteredData)
      change('region_texto', region_texto.nombre)
    }

    const handleChangeProvincia = (e) => {
      const target = `${e[0]}${e[1]}${e[2]}`
      opt_comunas = []
      const filteredData = comunas.filter(entry =>
        entry.provincia.includes(target)
      )
      const provincia_texto = provincias.find(entry =>
        entry.value === target
      )
      setFilteredComunas(filteredData)
      change('provincia_texto', provincia_texto.label)
    }

    const handleChangeComuna = (e) => {
      const target = `${e[0]}${e[1]}${e[2]}${e[3]}${e[4]}`
      const comuna_texto = comunas.find(entry =>
        entry.value === target
      )
      change('comuna_texto', comuna_texto.label)
    }
    console.log(!valid)
  return (
    <form className='form' onSubmit={handleSubmit}>
      <div className='ps-form__content'>
        <figure className='ps-block--form-box3'>
          <figcaption>Actualizar la información</figcaption>
          <div className='ps-block__content'>

            <div className='form-group'>
              <label htmlFor='nombres'>Imagen de perfil:</label>
              <Field
                name='tienda_logo'
                aspect={1}
                imagen={tienda.logo ? `${tienda.logo}` : null}
                setFile={setAvatar}
                component={renderImageUpload}
              />
            </div>

            <div className='form-group'>
              <label>Nombre de la tienda:</label>
              <Field name='nombre' component={renderupdateField} type='text' placeholder='Nombre de la tienda' />
            </div>
            <div className='form-group'>
              <label>Direccion:</label>
              <Field name='direccion' component={renderupdateField} type='text' placeholder='Dirección' />
            </div>
            <div className='form-group'>
              <label htmlFor='region'>Región</label>
              <Field
                name='region'
                options={opt_regiones}
                component={SelectField}
                placeholder='Region'
                onChange={handleChangeRegion}
              />
            </div>
            <div className='form-group'>
              <label htmlFor='provincia'>Provincia</label>
              <Field
                name='provincia'
                options={filtered_provincias.length > 0 ? filtered_provincias : opt_provincias}
                component={SelectField}
                onChange={handleChangeProvincia}
                placeholder='Provincia'
              />
            </div>
            <div className='form-group'>
              <label htmlFor='comuna'>Comuna</label>
              <Field
                name='comuna'
                options={filtered_comunas.length > 0 ? filtered_comunas : opt_comunas}
                component={SelectField}
                placeholder='Comuna'
                onChange={handleChangeComuna}
              />
            </div>
            <div>
              <label>RUT:</label>
              <Field
                name='rut'
                type='text'
                listen={(e) => handleChangeRut(e)}
                component={renderRUT}
                placeholder='RUT *'
                defaultValue={tienda.rut ? tienda.rut : null}
              />
            </div>
            <div className='form-group'>
              <label>Telefono:</label>
              <Field name='telefono' component={renderTelefonoFormat} type='text' placeholder='Telefono empresa' />
            </div>

            <div className='form-group'>
              <label>Razón social:</label>
              <Field name='razon' component={renderupdateField} type='text' placeholder='Razón social' />
            </div>

            <div className='form-group'>
              <label>Giro:</label>
              <Field name='giro' component={renderupdateField} type='text' placeholder='Giro' />
            </div>

            <div className='form-group'>
              <label>Descripcion:</label>
              <Field
                name='descripcion'
                component={renderTextArea}
                rows='4'
                placeholder='Cuentanos sobre tu tienda...'
              />
            </div>
            <div className='container' style={{ display: 'flex', justifyContent: 'space-around' }}>

              <Link to='/perfil' className='ps-btn ps-btn--gray'>
                Cancelar
              </Link>
              <button
                className={
                  !rutValid || !valid
                    ? 'ps-btn'
                    : 'ps-btn'
                }
                style={{
                    backgroundColor: !rutValid || !valid ? '#ccc' : '',
                    cursor: !rutValid || !valid ? 'not-allowed' : '',
                    color: !rutValid || !valid ? '#000' : '' }}
                type='submit'
                disabled={!rutValid || !valid}
                >
                Guardar Cambios
              </button>
            </div>
          </div>
        </figure>
      </div>
    </form>
  )
}

export default reduxForm({
  form: 'tienda_info',
  enableReinitialize: true,
  validate,
})(TiendaForm)
