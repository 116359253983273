import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { actions } from "../../../../redux/modules/cuenta/profile";
import { menu } from "./Menu";
import {actions as actionslog} from "../../../../redux/modules/cuenta/login"
class MenuSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seleccionado: "/",
      estado: "1",
    };
  }

  handleLogout = (e) => {
    this.props.logOut()
  }

  componentDidMount() {
    this.handleInit();
  }

  handleInit = () => {
    const get_ruta = location.href.split("/");
    let route = get_ruta[get_ruta.length - 1];
    this.setState({ seleccionado: `${route}` });
  };

  handleSetActive = (ruta) => {
    this.setState({ seleccionado: `${ruta.url}` });
  };

  render() {
    return (
      <React.Fragment>
        <ul className="menu">
          {menu.map((item, index) => (
            <li
              key={index}
              className={this.state.seleccionado === item.url ? "active" : ""}
            >
              <Link
                to={item.url}
                onClick={this.handleSetActive.bind(this, item)}
              >
                <i className={item.icon}></i>
                {item.text}
              </Link>
            </li>
          ))}
          <li key='8'>
              <Link 
                to="#" 
                onClick={this.handleLogout}
                >
                <i className='icon-exit'></i>
                Salir    
              </Link>
          </li>
        </ul>
      </React.Fragment>
    );
  }
}

const ms2p = (state) => {
  return state;
};

const md2p = { ...actions,...actionslog };

export default connect(ms2p, md2p)(MenuSidebar);
