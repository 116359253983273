import React, { Component } from 'react';
import DefaultHeader from '../layout/Header/DefaultHeader'
import { connect } from "react-redux";
import {getBestSeller} from '../../../redux/modules/productos/productos'
import {getBalance , getSolicitudes , onSubmitSolicitud , getVentasPorCobrar} from '../../../redux/modules/solicitudes/solicitudes'
import {getTotales, getSinEnviar, getEnviadas} from '../../../redux/modules/cotizaciones/cotizacion'
import {getBancos} from '../../../redux/modules/bancos/bancos'
import {mediapath, api} from '../../../utility/api'
import { Currency } from "react-intl-number-format/dist/index.js";
import LoadMask from "../Utils/LoadMask/LoadMask";
import TableBestSeller from "./TableBestSeller"
import RetiroForm2 from '../Billetera/RetiroForm2';
import RetiroForm from '../Billetera/RetiroForm';
import {Modal} from 'antd'
import moment from 'moment'


class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalVisible: false,
            now: moment().format('YYYY-MM-DD'),
            init_month: moment().format("YYYY-MM-01"),
            sin_enviar: "",
            ganadas: "",
            todas: "",
            loader: true,
            me : this.props.login.me
        }; 
    }

    timer = null;

    handleOk = () => {
        this.setState({isModalVisible:false})
      };
    
    handleCancel = () => {
    this.setState({isModalVisible:false})
    };

    handleSend = (data) =>{
        this.handleOk()
        this.props.onSubmitSolicitud(data);
    }
    
    handlegetBestSeller = () => {
        let filter = {"filter": this.state.now}
        this.props.getBestSeller(filter)
    }
    
    componentDidMount(){
        this.handleLoader()
        this.handlegetBestSeller()
        this.getTodas(20)
        this.getSinEnviar(1)
        this.getEnviadas(2)
        if(this.state.me.tipo_usuario===20){
            this.props.getBalance()
            this.props.getSolicitudes()
            this.props.getVentasPorCobrar()
            this.props.getBancos()
        }

    }

    showModal = () => {
        this.setState({isModalVisible:true})
      };

    handleFilter = (e) =>{
        let inicio=e.target.value
        let filter = {"filter": inicio}
        this.props.getBestSeller(filter)
    }

    getSinEnviar = (data) =>{
        this.props.getSinEnviar(data);
    }

    getEnviadas = (data) =>{
        this.props.getEnviadas(data);
    }

    getTodas = (data) =>{
        this.props.getTotales(data);
    };

    handleLoader = () => {
        setTimeout(()=> this.setState({loader:false}),1000);
    }
    



    render() {
        let cuentas
        const {best_seller} = this.props.productos
        {this.props.bancos.cuentas.length>0? 
        cuentas = this.props.bancos.cuentas
        :
        cuentas = []
        }
        return (
            <div>
             <DefaultHeader title="Dashboard" visible={true}/>
            {this.state.loader?
            <LoadMask loading={this.state.loader} dark>
            
            </LoadMask>
            :
             <div>
            
            <section className="ps-dashboard">
            <div className="ps-section__left">
            {this.state.me.tipo_usuario === 20?
                <div className="container">
                <h4>Tu dinero</h4>
                    <table className="table ps-table--market" >
                        <thead>
                            <tr>
                            <th>DINERO DISPONIBLE (CLP)</th>
                            <th>DINERO RETIRADO (CLP)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                <h3 style={{color:"green"}}>
                                <Currency locale="es-CL" currency="CLP">{this.props.solicitudes.balance.balance}</Currency>
                                </h3>
                                </td>
                                <td>
        
                                {/*
                                <div className="form-group">
                                <select>
                                    <option value="3">Ultimos 3 meses</option>
                                </select>
                                </div>
                                */}
                                <h3 style={{color:"red"}}>
                                <Currency locale="es-CL" currency="CLP">
                                    {this.props.solicitudes.data.retirado.retirado>0
                                    ?this.props.solicitudes.data.retirado.retirado
                                    :
                                    0}
                                
                                </Currency>
                                </h3>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                {this.props.solicitudes.balance.balance <= 0? 
                                <button 
                                onClick={this.showModal}
                                className="btn ps-btn--gray" 
                                style={{fontWeight:"bold"}}
                                disabled
                                >Solicitar un retiro
                                </button>
                                :
                                <button 
                                onClick={this.showModal}
                                className="btn ps-btn" 
                                style={{fontWeight:"bold"}}
                                >Solicitar un retiro
                                </button>
                                }
                                </td>
                                <td>
                                <section className="ps-card ps-card--statics">
                                <div className="ps-card--statics ps-card__sortby" style={{flexDirection:"column"}}>
                                <div className="form-group--select mt-5">
                                    <select className="form-control">
                                        <option value="3">Todo</option>
                                        <option value="3">Ultimos 7 dias</option>
                                        <option value="3">Ultimo 1 mes</option> 
                                        <option value="3">Ultimos 3 meses</option>
                                    </select>
                                    <i className="icon-chevron-down"></i>
                                </div>
                                </div>
                                </section>
            
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            :null}
            <hr/>
                <div className="container">
                {best_seller?
                <div className="ps-container">
                {this.props.productos.best_seller.length>0 ? 
                <React.Fragment>
                    <h4>Productos más vendido del mes</h4>
                <TableBestSeller info={this.props.productos.best_seller}/>
                </React.Fragment>
                :
                null
                }
                    
            </div>
            :
            null
                }
            </div>



            
            </div>
            <div className="ps-section__right pt-30">
            <section className="ps-card ps-card--statics">
            <div className="ps-block--stat pink">
                   <div className="ps-block__left">
                       <span><i className="icon-bubble-alert"></i></span>
                    </div>
                <div className="ps-block__content">
                    <b>Cotizaciones sin enviar</b>
                    <h4 className="numbers-yellow">
                        {this.props.cotizacion.sin_enviar}
                    </h4>
                </div>
            </div>  

            <div className="ps-block--stat green">
                   <div className="ps-block__left">
                       <span><i className="icon-check"></i></span>
                    </div>
                <div className="ps-block__content">
                    <b>Cotizaciones enviadas</b>
                    <h4 className="numbers-yellow">
                        {this.props.cotizacion.enviadas}
                    </h4>
                </div>
            </div>  

            <div className="ps-block--stat yellow">
                   <div className="ps-block__left">
                       <span><i className="icon-page-break2"></i></span>
                    </div>
                <div className="ps-block__content">
                    <b>Cotizaciones totales</b>
                    <h4 className="numbers-yellow">
                        {this.props.cotizacion.todas}
                    </h4>
                </div>
            </div>  

            </section>
            </div>     
            </section>
            {this.state.me.tipo_usuario === 20? 
                <Modal 
                width={800}
                title="" 
                visible={this.state.isModalVisible} 
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                footer={[
                    <button type="reset" className="ps-btn ps-btn" onClick={this.handleCancel}>Cancelar</button>
                ]}
                >

                <RetiroForm2 
                onSubmit={this.handleSend} 
                ventas={this.props.solicitudes.ventas.ventas} 
                iva={this.props.solicitudes.ventas.iva}
                cuentas={cuentas}/>
                        
                </Modal>
            :null}
            </div>
        
    }
    </div>
        );
    }
}



const ms2p = state => ({...state})

const md2p = {
    getBestSeller, getTotales, getSinEnviar ,
    getEnviadas , getBalance , getSolicitudes ,
    onSubmitSolicitud, getVentasPorCobrar, getBancos
};
  
export default connect(ms2p, md2p)(Home);
