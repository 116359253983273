import React from 'react';
import { Link } from 'react-router-dom';
import logoStore from '../../../../../assets/img/logo_light.png';
import './_footer.scss'

const vender = [
    {"titulo": "Políticas de privacidad",
    "url": "#",
    },
    {"titulo": "Políticas de devolución",
    "url": "#",
    },
    {"titulo": "Políticas de entrega ",
    "url": "#",
    },
    {"titulo": "Terminos y condiciones de uso ",
    "url": "#",
    },
]

const acerca_de = [
    {"titulo": "Contacto",
    "url": "#",
    },
    {"titulo": "Quiénes somos",
    "url": "#",
    },
    {"titulo": "Visión",
    "url": "#",
    },
]

const compras = [
    {"titulo": "Registrate",
    "url": "/account/registro",
    },
    {"titulo": "Política de devolución",
    "url": "#",
    },
    {"titulo": "Visión",
    "url": "#",
    },
]

const otros = [
    {"titulo": "Preguntas frecuentes",
    "url": "#",
    },
    {"titulo": "",
    "url": "#",
    },
]

const redes_sociales = [
    {
    "text": "Facebook",
    "url":"#",
    "icon": "fa fa-facebook-square"
    },
    {
    "text": "Twitter",
    "url":"#",
    "icon": " fa fa-twitter-square"
    },
    {
    "text": "Instagram",
    "url":"#",
    "icon": "fa fa-instagram"
    },

]



const FooterWidgets = () => (
    <div className="ps-footer__widgets ">
        <aside className="widget widget_footer">
            {/*vender*/}
            <h4 className="widget-title">Vender</h4>
            <ul className="ps-list--link">
            {vender.map((item, index) =>(
                <li key={index}>
                    <a href={item.url}>{item.titulo}</a>
                </li>
            ))}
            </ul>
        </aside>

        <aside className="widget widget_footer">
            {/*Politicas*/}
            <h4 className="widget-title">Comprar</h4>
            <ul className="ps-list--link">
            {compras.map((item, index) =>(
                <li key={index}>
                    <a href={item.url}>{item.titulo}</a>
                </li>
            ))}
            </ul>
        </aside>

        <aside className="widget widget_footer">
            {/*Politicas*/}
            <h4 className="widget-title">Acerca de</h4>
            <ul className="ps-list--link">
            {acerca_de.map((item, index) =>(
                <li key={index}>
                    <a href={item.url}>{item.titulo}</a>
                </li>
            ))}
            </ul>
        </aside>

        <aside className="widget widget_footer">
            {/*Redes sociales*/}
            <h4 className="widget-title">Redes sociales</h4>
            <ul className="ps-list--link">
                <li>
                {redes_sociales.map((item, index) =>(
                        <a className="pl-3" href={item.url}>
                            <i className={`${item.icon}`} style={{fontSize:"xx-large"}}></i>
                        </a>
                    ))}
                </li>
            </ul>
        </aside>

        <aside className="widget widget_footer">
            {/*Logo*/}
            <ul className="widget-title">
                <li>
                <img src={logoStore} alt="" />
                </li>
            </ul>
        </aside>
    </div>
);

export default FooterWidgets;
