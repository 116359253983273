const validate = (values) => {
    const errors = {};

    if (!values.email) {
        errors.email = 'Este campo es requerido';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Correo invalido'
      }

    if (!values.cliente_direccion) {
        errors.cliente_direccion = 'Este campo es requerido';
    }
    if (!values.first_name) {
        errors.first_name = 'Este campo es requerido';
    }
    if (!values.last_name) {
        errors.last_name = 'Este campo es requerido';
    }

    if (!values.cliente_telefono) {
        errors.cliente_telefono = 'Este campo es requerido';
    }else if (!/[0-9]+/.test(values.cliente_telefono.replace(/\((\w+)\)/g, "$1").replace('-', ''))) {
        errors.cliente_telefono = 'Telefono invalido'
      }

    if (!values.cliente_rut) {
        errors.cliente_rut = 'Este campo es requerido';
    }
    if (!values.cliente_region) {
        errors.cliente_region = 'Este campo es requerido';
    }
    if (!values.cliente_provincia) {
        errors.cliente_provincia = 'Este campo es requerido';
    }
    if (!values.cliente_comuna) {
        errors.cliente_comuna = 'Este campo es requerido';
    }

    return errors;
};

export default validate;
