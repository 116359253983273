const validate = (values) => {
    const errors = {};

    if (!values.banco) {
        errors.banco = 'Este campo es requerido';
    }
    if (!values.tipo) {
        errors.tipo = 'Este campo es requerido';
    }
    if (!values.no_cuenta) {
        errors.no_cuenta = 'Este campo es requerido';
    } else if (!/^[0-9]{1,20}$/.test(values.no_cuenta)) {
        errors.no_cuenta = 'Este campo debe contener solo números';
    }
    return errors;
};

export default validate;
