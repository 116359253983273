import React, { Component } from 'react';
import {api} from '../../../../utility/api'
import { connect} from "react-redux";
import {actions} from '../../../../redux/modules/cuenta/login'
import LoadMask from "../../Utils/LoadMask/LoadMask";
import {sucessVerify} from '../../../../redux/modules/cuenta/profile'


class Activacion extends Component {

    constructor(props) {
      super(props);
  
      this.state = {
          token : this.props.match.params.id,
          valid: null,
          loader: true,
      }
    }

    componentDidMount(){
        this.verify_token(this.state.token);
        setTimeout(()=> this.setState({loader:false}),3000);
        this.handleLoader()

    }


    
    verify_token = async(token) =>{
        const reponse = api.get(`/usuarios/activate_asociados/?token=${token}`).then((reponse) =>{
            this.setState({valid:reponse.detail})
        })
    .catch((error) => {
        if(error){
            this.setState({valid:error.body.detail})
        }
        });
    }

    successfull = ()=>{
        let is_valid = this.state.valid
        this.props.sucessVerify(is_valid)
    }


    handleLoader = () => {
        setTimeout(()=> this.successfull(),2000);
    }

render() {
    return (            
        <div className="container pt-50 mt-50">
            {this.state.loader?
                <LoadMask loading={this.state.loader} dark>
                    
                </LoadMask>
                :
                null
            }
        </div>
    );
}
}

const ms2p = state => {
    return state;
};


const md2p = {...actions , sucessVerify}

  
export default connect(ms2p ,md2p)(Activacion);